import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { colors, table } from '../theme';
import { TableCell } from './Table';

type Props<T> = {
    sortKey: string,
    items: T[],
    sortData: (item: T) => string | number | Date,
    sortBy: string
    sortAction: (items: T[], sortBy: string, ascending: boolean) => void,
    title: string
    width?: string,
    currentAscending: boolean
}

export default function Sorter<T>({ sortKey, items, currentAscending, sortAction, title, sortBy, width = table.tableCellWidth, sortData }: Props<T>): JSX.Element {

    const [ascending, setAscending] = React.useState<boolean>(true)
    const [color, setColor] = React.useState<string>(colors.neutral06)
    const [smaller, setSmaller] = React.useState<number>(-1)
    const [bigger, setBigger] = React.useState<number>(1)

    useEffect(() => {
        if (sortKey === sortBy) {
            setColor(colors.success06)
        } else {
            setColor(colors.neutral06)
        }
    }, [sortKey, sortBy])

    useEffect(() => {
        if (sortKey === sortBy && currentAscending === ascending) {
            setAscending(!ascending)
        } else if (sortKey !== sortBy) {
            setAscending(true)
        }
    }, [items])

    useEffect(() => {
        if (ascending) {
            setSmaller(-1)
            setBigger(1)
        } else {
            setSmaller(1)
            setBigger(-1)
        }
    }, [ascending])


    const getIcon = () => {
        if (sortKey === sortBy) {
            return <FontAwesomeIcon size='lg' color={color} icon={(ascending ? faAngleDown : faAngleUp)} style={{ marginLeft: '10px', position: 'relative', bottom: '-3px' }} />
        }
        return <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', position: 'relative', bottom: '3px' }}>
            <FontAwesomeIcon size='lg' color={color} icon={(faAngleUp)} />
            <FontAwesomeIcon size='lg' color={color} icon={(faAngleDown)} style={{ marginTop: '-7px' }} />
        </div>
    }

    const compare = (a: string | number | Date, b: string | number | Date) => {
        if (a < b) { return smaller; }
        if (a > b) { return bigger; }
        return 0
    }

    const sort = () => {
        const itemsWithSortData = items.map(item => ({ item, sortData: sortData(item) }))
        itemsWithSortData.sort(function (a, b) {
            if (typeof a.sortData === 'string' && typeof b.sortData === 'string') {
                return compare(a.sortData.toLowerCase(), b.sortData.toLowerCase())
            } else {
                return compare(a.sortData, b.sortData)
            }
        })
        const itemsWithoutSortData = itemsWithSortData.map(item => item.item)
        sortAction(itemsWithoutSortData, sortKey, ascending)
    }


    return <TableCell width={width} style={{ cursor: 'pointer' }} onClick={() => sort()} >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {title}
            {getIcon()}
        </div>
    </TableCell>
}




import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { elementWithHeader } from "../../components/common/header/Header";
import { ViewContainer } from "../../components/ViewContainer";
import { FetchCompaniesAction } from "../../redux/companies/companies-actions";
import { FetchUsersAction, FilterUsersAction, UsersPaginationItemsPerPageChangeAction, UsersPaginationPageChangeAction } from "../../redux/users/users-actions";
import UsersTable from "./UsersTable";
import CreateUserModal from "./CreateUserModal";
import CreateUserButton from "./CreateUserButton";
import { alignments } from "../../theme";
import Pagination from "../../components/common/pagination";
import { RootState } from "../../interfaces/RootState";
import CommonSearchBar from "../../components/common/search-bar/CommonSearchBar";
import { User } from "../../redux/users/users-types";


function Users(): React.ReactElement {
    const dispatch = useDispatch()
    const users = useSelector((state: RootState) => state.hydrolink.users.users)
    const usersCount = useSelector((root: RootState) => root.hydrolink.users.filteredAndSortedUsers).length
    const currentPage = useSelector((root: RootState) => root.hydrolink.users.pagination.currentPage)

    const pageChange = (pageNumber: number) => {
        dispatch(UsersPaginationPageChangeAction(pageNumber))
    }

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(UsersPaginationItemsPerPageChangeAction(itemsPerPage))
    }

    const searchData = (user: User) =>
        [user.firstName, user.lastName, user.username]

    return <>
        <CreateUserModal />
        <ViewContainer style={alignments.evenHorizontal}>
            <CommonSearchBar
                items={users}
                searchData={searchData}
                filterAction={FilterUsersAction}
                pageChange={pageChange}

            />
            <CreateUserButton />
        </ViewContainer>
        <ViewContainer>
            <UsersTable />
        </ViewContainer>
        <ViewContainer>
            <Pagination
                current={currentPage}
                itemsCount={usersCount}
                currentPageCallback={pageChange}
                itemsPerPageCallback={itemsPerPageChange}
            />
        </ViewContainer>
    </>
}


export default function UsersView() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(FetchUsersAction())
        dispatch(FetchCompaniesAction())
    }, [dispatch])

    return elementWithHeader(<Users />)
}
import { createAction } from '@reduxjs/toolkit'
import {
  AcknowledgeMessagePayload,
  MessageTypes
} from './messages-types'

export const AddMessageAction = createAction('ADD_MESSAGE', (message: string, type: MessageTypes) => {
  return {
    payload: {
      message: {
        message,
        type,
        id: Date.now().toString()
      }
    }
  }
})
export const AcknowledgeMessageAction = createAction<AcknowledgeMessagePayload>('ACKNOWLEDGE_MESSAGE')

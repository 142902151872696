import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';
import { FetchCompaniesAction, SortCompaniesAction } from '../../redux/companies/companies-actions';
import { Company } from '../../redux/companies/companies-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Sorter from '../../components/Sorter';
import { table } from '../../theme';
import CompanyTableRow from './CompanyTableRow';

const CompaniesTable = ({ companyPrincipalManagerEmail }: { companyPrincipalManagerEmail: (company: Company) => string | undefined }) => {
    const dispatch = useDispatch()
    const t = useTranslation().t
    const allCompanies = useSelector((state: RootState) => state.hydrolink.companies.filteredAndSortedCompanies)
    const currentSorting = useSelector((state: RootState) => state.hydrolink.companies.sortBy)
    const currentAscending = useSelector((state: RootState) => state.hydrolink.companies.ascending)
    const pagination = useSelector((state: RootState) => state.hydrolink.companies.pagination)
    let startIndex = (pagination.currentPage - 1) * pagination.itemsPerPage
    let endIndex = startIndex + pagination.itemsPerPage
    let companies = allCompanies.slice(startIndex, endIndex)

    useEffect(() => {
        dispatch(FetchCompaniesAction())
    }, [dispatch])

    const sortCompaniesFunction = (sortCompanies: Company[], sortBy: string, ascending: boolean) => {
        dispatch(SortCompaniesAction({ companies: sortCompanies, sortBy: sortBy, ascending: ascending }))
    }

    const companyTableHead = (): React.ReactNode => <TableHead>
        <TableRow>
            <TableCell width={table.tableCellWidthVeryShort} ></TableCell>
            <Sorter
                sortKey={"name"}
                items={allCompanies}
                sortAction={sortCompaniesFunction}
                sortBy={currentSorting}
                title={t('companies.name')}
                width={table.tableCellWidthLonger}
                sortData={(company: Company) => company.name}
                currentAscending={currentAscending}
            />
            <TableCell width={table.tableCellWidthVeryShort} >
                {t('companies.alarms')}
            </TableCell>
            <Sorter
                sortKey={"streetAddress"}
                items={allCompanies}
                sortAction={sortCompaniesFunction}
                sortBy={currentSorting}
                title={t('companies.address')}
                sortData={(company: Company) => company.streetAddress}
                currentAscending={currentAscending}
            />
            <Sorter
                sortKey={"town"}
                items={allCompanies}
                sortAction={sortCompaniesFunction}
                sortBy={currentSorting}
                title={t('companies.city')}
                width={table.tableCellWidthShorter}
                sortData={(company: Company) => company.town}
                currentAscending={currentAscending}
            />
            <Sorter
                sortKey={"principal"}
                items={allCompanies}
                sortAction={sortCompaniesFunction}
                sortBy={currentSorting}
                title={t('companies.principal')}
                sortData={(company: Company) => companyPrincipalManagerEmail(company) ?? ''}
                currentAscending={currentAscending}
            />
            <TableCell width={table.tableCellWidthVeryShort}></TableCell>
        </TableRow>
    </TableHead>

    return <>
        <TableContainer component={Paper}>
            <Table>
                {companyTableHead()}
                <TableBody>
                    {[...companies].map((c) => {
                        return <CompanyTableRow key={c.id} company={c} />
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}


export default CompaniesTable
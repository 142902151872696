interface BlockProps {
    buttons: JSX.Element[]
}

const FloatButtonsRight = (props: BlockProps) => {

    const button = (b: JSX.Element, i: number) => <div key={i} style={{ display: 'flex', justifyContent: 'space-around' }}>
        {b}
        <div style={{ marginLeft: '20px' }} />
    </div>


    return (
        <div style={{ display: 'flex', float: 'right', marginTop: '20px' }}>
            {props.buttons.map((b, i) => button(b, i))}
        </div>
    )
}

export default FloatButtonsRight
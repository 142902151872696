import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { alignments } from '../../theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';

export enum Tabs {
    APARTMENTS,
    REPORTS,
    INITIALIZATIONREPORT,
    CENTERS,
    RESIDENTS,
    NOTES
}

type TabObject = {
    tab: Tabs,
    title: string,
    link: string,
    adminOnly: boolean
}

const tabs: TabObject[] = [
    { tab: Tabs.APARTMENTS, title: 'companies.tabs.apartments', link: './../apartments', adminOnly: false },
    { tab: Tabs.REPORTS, title: 'companies.tabs.reports', link: './../reports', adminOnly: false },
    { tab: Tabs.INITIALIZATIONREPORT, title: 'companies.tabs.initializationReport', link: './../initialization-report', adminOnly: true },
    { tab: Tabs.CENTERS, title: 'companies.tabs.centers', link: './../centers', adminOnly: true },
    { tab: Tabs.RESIDENTS, title: 'companies.tabs.residents', link: './../residents', adminOnly: false },
    { tab: Tabs.NOTES, title: 'companies.tabs.notes', link: './../notes', adminOnly: true }
]

export default function CompanyTabs({ tab: currentTab }: { tab: Tabs }) {
    const t = useTranslation().t
    const translatedTitle = t(tabs[currentTab].title)
    const isAdmin: boolean = useSelector((state: RootState) => state.hydrolink.auth.isAdmin)

    return <div style={alignments.evenHorizontal}>
        <h2>{translatedTitle}</h2>
        <div style={alignments.rightSide}>
            {tabs.filter((tab) => isAdmin || !tab.adminOnly).map((tabObject) =>
                <Link
                    draggable="false"
                    to={tabObject.link}
                    key={tabObject.title}
                >
                    <Button
                        variant='text'
                        style={{
                            border: 'hidden',
                            textTransform: 'none',
                            fontWeight: tabObject.tab == currentTab ? 'bold' : 'normal'
                        }}
                    >
                        {t(tabObject.title)}
                    </Button>
                </Link>
            )}
        </div>
    </div>
}

import { all, call } from 'redux-saga/effects'
import { authSagas } from './auth/auth-sagas'
import { usersSaga } from './users/users-sagas'
import { companiesSaga } from './companies/companies-sagas'
import { apartmentsSaga } from './apartments/apartments-sagas'
import { residentsSaga } from './residents/residents-saga'
import { centersSaga } from './centers/centers-sagas'
import { notesSaga } from './notes/notes-sagas'
import { reportsSaga } from './reports/reports-saga'

export default function* rootSaga() {
  yield all([
    call(authSagas),
    call(usersSaga),
    call(notesSaga),
    call(centersSaga),
    call(companiesSaga),
    call(apartmentsSaga),
    call(residentsSaga),
    call(reportsSaga)
  ])
}

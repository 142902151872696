import React, { useEffect } from "react";
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/Button';
import { Block } from '../../components/common/block';
import { ModalInput } from '../../components/common/modal/ModalInput';
import SeparatorLine from '../../components/SeparatorLine';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { DeleteCompanyUserAction } from '../../redux/companies/companies-actions';
import { Company } from "../../redux/companies/companies-types";
import { CreateNewUserPasswordAction, DeleteUserAction, UpdateUserAction, UpdateUserEmailAction } from '../../redux/users/users-actions';
import { getCompanyManagerByUser } from '../../redux/users/users-reducer';
import { User } from "../../redux/users/users-types";
import { alignments } from '../../theme';
import AdminRights from './AdminRightsButton';
import ButtonsDisplayer from "../../components/FloatButtonsRight";
import Checkbox from "../../components/Checkbox";
import { ReportErrorAction } from "../../redux/error/error-actions";

type Props = {
    user: User,
    companies: Company[]
}

export default function UsersInfo({ user, companies }: Props): JSX.Element {
    const dispatch = useDispatch()
    const t = useTranslation().t

    const [originalUserDetails, setOriginalUserDetails] = React.useState<User>(user)
    const [userDetails, setUserDetails] = React.useState<User>(originalUserDetails)
    const [createNewPassword, setCreateNewPassword] = React.useState<boolean>(false)

    useEffect(() => {
        setOriginalUserDetails(user)
    }, [user])

    const changeValues = (e: React.ChangeEvent<HTMLInputElement>, field: keyof User) => {
        setUserDetails({
            ...userDetails,
            [field]: e.target.value
        })
    }

    const anythingChanged = (): boolean => basicDetailsChanged() || emailChanged() || createNewPassword

    const emailChanged = (): boolean => originalUserDetails.email !== userDetails.email

    const basicDetailsChanged = (): boolean =>
        !(originalUserDetails.firstName === userDetails.firstName
            && originalUserDetails.lastName === userDetails.lastName
            && originalUserDetails.username === userDetails.username
            && originalUserDetails.phone === userDetails.phone)

    const deleteUser = () => {
        if (window.confirm(`${t("users.deleteUserConfirmation")}?`)) {
            for (let c of companies) {
                dispatch(DeleteCompanyUserAction({ companyId: c.id, userId: user.id }))
            }
            dispatch(DeleteUserAction(user.id))
        }
    }

    const saveUser = () => {
        // Save stuff only if necessary
        if (basicDetailsChanged()) {
            if (userDetails.firstName == "") {
                dispatch(ReportErrorAction(t("errors.firstNameMissing")))
                return
            }
            if (userDetails.lastName == "") {
                dispatch(ReportErrorAction(t("errors.lastNameMissing")))
                return
            }

            dispatch(UpdateUserAction(userDetails))
        }
        if (emailChanged()) {
            dispatch(UpdateUserEmailAction({ userId: user.id, newEmail: userDetails.email }))
        }
        if (createNewPassword) {
            setCreateNewPassword(false)
            dispatch(CreateNewUserPasswordAction(user.id))
        }

        // `originalUserDetails` is used to keep track if there are any changes made (to disable/enable save button)
        setOriginalUserDetails(userDetails)
    }

    function createUserDetailInput(field: keyof User): React.ReactNode {
        return <ModalInput
            key={field}
            title={t(`users.${field}`)}
            value={userDetails[field] as string}
            handleFormValue={e => changeValues(e, field)}
        />
    }

    const usersCompaniesTableHead = (): React.ReactNode => <TableHead>
        <TableRow key={"companyTable_head"}>
            <TableCell>{t('users.company')}</TableCell>
            <TableCell>{t('users.role')}</TableCell>
        </TableRow>
    </TableHead>

    const usersCompanyRow = (c: Company): React.ReactNode => <TableRow key={c.id}>
        <TableCell>{c.name}</TableCell>
        <TableCell>{t(`roles.${getCompanyManagerByUser(c, user)?.role}`)}</TableCell>
    </TableRow>

    const userCompanies = (): React.ReactNode => <div style={{ padding: '10px 0' }}>
        <h3>{t('users.roles')}</h3>
        <TableContainer component={Paper}>
            <Table>
                {usersCompaniesTableHead()}
                <TableBody>
                    {companies.map((c: Company) => usersCompanyRow(c))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>

    const basicUserInfo = (): React.ReactNode => <>
        <h3>{t('users.basicInfo')}</h3>
        <div style={alignments.twoElementGrid}>
            {createUserDetailInput("firstName")}
            {createUserDetailInput("lastName")}
            {createUserDetailInput("phone")}
            {createUserDetailInput("username")}
        </div>
    </>

    const userCredentials = (): React.ReactNode => <>
        <div style={alignments.twoElementGrid}>
            {createUserDetailInput("email")}
            <div style={{ paddingTop: '48px' }}>
                <Checkbox
                    label={t('users.generateNewPassword')}
                    checked={createNewPassword}
                    onChange={() => setCreateNewPassword(!createNewPassword)}
                />
            </div>
        </div>
    </>

    return <Block noMargin noBottomBorder variant={'white'} style={{ overflow: 'hidden' }}>
        {basicUserInfo()}
        <SeparatorLine />
        {userCredentials()}
        <SeparatorLine />
        {userCompanies()}
        <ButtonsDisplayer
            buttons={[
                <Button onClick={deleteUser}>
                    {t('users.delete')}
                </Button>,
                <AdminRights user={user} />,
                <Button
                    variant="contained"
                    onClick={saveUser}
                    disabled={!anythingChanged()}
                >
                    {t('generic.save')}
                </Button>
            ]}
        />
    </Block>
}
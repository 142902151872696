import { RootState } from "../interfaces/RootState";
import { useSelector } from "react-redux";
import { Role } from "../redux/companies/companies-types";

export function useButtonRights(
    companyId: number,
    requirement: "admin" | "manager",
    disabled?: boolean): boolean | undefined {
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const myRole = useSelector((state: RootState): Role => state.hydrolink.auth.roleByCompanyId.find(c => c.companyId === companyId)?.role ?? "viewer")

    if (!isAdmin) {
        if (requirement === "admin" || (requirement === "manager" && myRole === "viewer")) {
            return true
        }
    }
    return disabled ?? false
};
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface Props {
  onClick: () => void
  style?: React.CSSProperties
  disabled?: boolean
  icon: IconDefinition
}

const FontAwesomeIconButton = (props: Props) => {

  const defaultStyle = { padding: 0, fontSize: '1em', float: 'right', color: '#6A80A2' }

  return <IconButton
    sx={{ ...defaultStyle, ...props.style ?? {} }}
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <FontAwesomeIcon
      icon={props.icon}
      size='lg'
    />
  </IconButton>

}

export default FontAwesomeIconButton

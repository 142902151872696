import { Checkbox as MuiCheckbox, FormControlLabel, Typography } from "@mui/material"
import { typography } from "../theme"

type Props = {
    label?: string,
    checked: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    disabled?: boolean
}

export default function Checkbox({ label, checked, onChange, disabled }: Props): JSX.Element {
    return <FormControlLabel
        label={
            <Typography
                fontSize={typography.fontSize.default}
                fontWeight={"bold"}
                marginTop={'2px'}
            >
                {label}
            </Typography>
        }
        control={
            <MuiCheckbox
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
        }
    />
}
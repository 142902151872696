import { useTranslation } from "react-i18next";
import { Button } from '../../components/Button';
import { TableContainer, Table, TableRow, TableBody, TableCell } from "../../components/Table";
import { useEffect, useState } from 'react';
import DropDown from '../../components/DropDown';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { ReportSettings } from "../../redux/apartments/apartments-types";
import { FetchReportFormatsAction, SaveReportSettingsAction } from "../../redux/apartments/apartments-actions";
import deepEqual from 'deep-equal';
import Checkbox from "../../components/Checkbox";
import { InputLabel, TextField } from "@mui/material";
import { DatePickerComponent } from "../../components/DatePicker";
import React from "react";
import { useButtonRights } from "../../utils/useButtonRights";


type Props = {
    companyId: number
}

export default function Reporting({ companyId }: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const existingReportSettings = useSelector((state: RootState) => state.hydrolink.apartments.reportSettings)
    const reportFormats = useSelector((state: RootState) => state.hydrolink.apartments.reportFormats)
    const [reportSettings, setReportSettings] = useState<ReportSettings>(existingReportSettings)
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const [reportStart, setReportStart] = React.useState<Date | null>(null)

    const changed = (): boolean => !deepEqual(existingReportSettings, reportSettings) || reportStart !== null

    const saveReportSettingsButtonRights = useButtonRights(companyId, "manager", !changed())

    const timeSpans = [
        { value: '1', displayText: t('apartments.report.reportInterval.1month') },
        { value: '2', displayText: t('apartments.report.reportInterval.2month') },
        { value: '3', displayText: t('apartments.report.reportInterval.3month') },
        { value: '6', displayText: t('apartments.report.reportInterval.6month') },
        { value: '12', displayText: t('apartments.report.reportInterval.12month') }
    ]

    const languages = [
        { value: 'fi', displayText: t('country.finland') },
        { value: 'ee', displayText: t('country.estonia') },
        /*     { value: 'se', displayText: t('country.sweden') } */
    ]

    useEffect(() => {
        dispatch(FetchReportFormatsAction())
        setReportSettings(existingReportSettings)
    }, [existingReportSettings])

    // Updates report setting in local state
    const updateLocalSetting = (field: keyof ReportSettings, value: string | boolean | number) => {
        setReportSettings({
            ...reportSettings,
            [field]: value
        })
    }

    const saveReportSettings = () => {
        let newReportStart = undefined
        if (reportStart && isAdmin) {
            newReportStart = reportStart
        }
        dispatch(SaveReportSettingsAction({
            reportSettings: {
                ...reportSettings,
                reportStart: newReportStart
            },
            companyId: companyId
        }))
    }

    const tableRow = (label: JSX.Element, input: JSX.Element) => <TableRow >
        <TableCell>
            {label}
        </TableCell>
        <TableCell align="left">
            {input}
        </TableCell>
    </TableRow>

    return (<>
        <div style={{ width: "60%" }}>
            <TableContainer component='div' >
                <Table aria-label="simple table">
                    <TableBody >
                        {tableRow(
                            <InputLabel htmlFor="companyIdInReports">{t('apartments.report.companyId')} </InputLabel>,
                            <TextField
                                id="companyIdInReports"
                                size="small"
                                value={reportSettings.reportingId}
                                onChange={(e: React.ChangeEvent) => updateLocalSetting("reportingId", (e.target as HTMLInputElement).value)}
                            />
                        )}
                        {tableRow(
                            <InputLabel htmlFor="reportEmails">{t('apartments.report.reportEmails')} </InputLabel>,
                            <Checkbox
                                onChange={() => updateLocalSetting("sendReports", !reportSettings.sendReports)}
                                checked={reportSettings.sendReports}
                            />
                        )}
                        {tableRow(
                            <InputLabel htmlFor="email">{t('apartments.report.receiverEmails')} </InputLabel>,
                            <TextField
                                id="email"
                                size="small"
                                disabled={!reportSettings.sendReports}
                                value={reportSettings.reportEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("reportEmail", e.target.value)}
                            />
                        )}
                        {(reportFormats.length > 0) && tableRow(
                            <InputLabel htmlFor="format">{t('apartments.report.format')} </InputLabel>,
                            <DropDown
                                id={'format'}
                                options={reportFormats.map(r => { return { value: r.id, displayText: r.name } })}
                                disabled={!reportSettings.sendReports}
                                selectedValue={reportSettings.reportFormat ?? reportFormats[0]}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("reportFormat", e.target.value)}
                            />
                        )}
                        {isAdmin && tableRow(<InputLabel htmlFor="testStartDate">{t('apartments.report.adminReportStartFeature')} </InputLabel>,
                            <DatePickerComponent value={reportStart} setValue={setReportStart} />)}
                        {tableRow(
                            <InputLabel htmlFor="interval">{t('apartments.report.interval')} </InputLabel>,
                            <DropDown
                                id={'interval'}
                                options={timeSpans}
                                disabled={!reportSettings.sendReports}
                                selectedValue={reportSettings.reportIntervalMonths as unknown as string}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("reportIntervalMonths", e.target.value as unknown as number)}
                            />
                        )}
                        {tableRow(
                            <InputLabel htmlFor="raportLanguage">{t('generic.language')} </InputLabel>,
                            <DropDown
                                id={'reportLanguage'}
                                options={languages}
                                disabled={!reportSettings.sendReports}
                                selectedValue={reportSettings.reportLang}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("reportLang", e.target.value)}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <Button
            onClick={saveReportSettings}
            disabled={saveReportSettingsButtonRights}
        >
            {t('generic.save')}
        </Button>
    </>
    )
}
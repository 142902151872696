import React, { ChangeEventHandler } from "react";
import { StyledBaseReadingTextField, StyledFieldsText } from "./Modal.style";

type Props = {
    title: string,
    value: string,
    tooltip?: string,
    type?: React.HTMLInputTypeAttribute,
    style?: React.CSSProperties,
    handleFormValue: ChangeEventHandler<HTMLInputElement>
}

export function ModalInput({ title, value, tooltip, style, type, handleFormValue }: Props) {
    return <div style={{ padding: '10px 0', ...style }}>
        <StyledFieldsText data-tip={tooltip}>
            {title}
        </StyledFieldsText>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <StyledBaseReadingTextField
                type={type}
                value={value}
                onChange={handleFormValue}
            />
        </div>
    </div>
}
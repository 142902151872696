import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from '../../components/Button';
import { Modal } from "../../components/common/modal/Modal";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { CloseCreateCompanyModal, CreateCompanyAction } from "../../redux/companies/companies-actions";
import { CreateCompanyPayload } from "../../redux/companies/companies-types";
import { ReportErrorAction } from "../../redux/error/error-actions";

const defaultValues = {
  name: '',
  streetAddress: '',
  zipCode: '',
  town: ''
}

export function CreateCompaniesModal({ openModal }: { openModal: boolean }) {
  const t = useTranslation().t
  const dispatch = useDispatch()

  useEffect(() => {
    setCompanyData({
      ...defaultValues
    })
  }, [openModal]);

  const [companyData, setCompanyData] = useState<CreateCompanyPayload>(defaultValues)

  const onFormChange = (e: any, companyField: keyof typeof companyData) => {
    setCompanyData({
      ...companyData,
      [companyField]: e.target.value
    })
  }

  const onSave = () => {
   const regex = /^\s*$/
    if (regex.test(companyData.name)) {
      dispatch(ReportErrorAction(t("errors.companyNameMissing")))
    } else {
      dispatch(CreateCompanyAction(companyData))
    }
  }

  const onCancel = () => {
    dispatch(CloseCreateCompanyModal())
  }

  const getDialogActions = () => <>
    <Button
      onClick={onCancel}
    >
      {t('generic.cancel')}
    </Button>
    <Button
      variant="contained"
      onClick={onSave}
    >
      {t('generic.save')}
    </Button>
  </>

  // The reason as to why this is not a normal React component is because the input field unselects on every render
  function companyModalInput(field: keyof typeof companyData) {
    return <ModalInput
      key={field}
      title={t('companies.create.' + field)}
      tooltip={t('companies.create.' + field + ".tooltip")}
      value={companyData[field]}
      handleFormValue={e => onFormChange(e, field)}
    />
  }

  return <Modal
    title={t("companies.create.title")}
    open={openModal}
    dialogActions={getDialogActions()}
    closeModal={() => dispatch(CloseCreateCompanyModal())}
  >
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'space-between', gap: '1rem' }}>
      {companyModalInput('name')}
      {companyModalInput('streetAddress')}
      {companyModalInput('zipCode')}
      {companyModalInput('town')}
    </div>
  </Modal>
}

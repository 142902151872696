import { all, put, takeLatest } from "redux-saga/effects"
import * as api from "../../api"
import { SendClientCommandPayload } from "./centers-types"
import { FetchApartmentsAction } from "../apartments/apartments-actions"
import { ReportErrorAction } from "../error/error-actions"
import {
    FetchClientCommandsAction,
    FetchClientCommandsFailureAction,
    FetchClientCommandsSuccessAction,
    SendClientCommandAction,
    SendClientCommandFailureAction,
    SendClientCommandSuccessAction
} from "./centers-actions"
import { ApiClientCommand } from "../../api/api-types"
import { convertApiClientCommand } from "../../api/api-conversions"

// Fetches translations and info for client commands, not client commands for a specific center
export function* fetchClientCommands(action: { payload: void }) {
    yield api.get(
        `/client_commands`,
        (res: ApiClientCommand[]) => put(FetchClientCommandsSuccessAction(res.map(convertApiClientCommand))),
        err => all([
            put(FetchClientCommandsFailureAction()),
            put(ReportErrorAction(err))
        ])
    )
}

export function* postClientCommand(action: { payload: SendClientCommandPayload }) {
    yield api.post(
        `/clients/${action.payload.clientId}/commands`,
        { tag: action.payload.tag },
        () => all([
            put(SendClientCommandSuccessAction()),
            put(FetchApartmentsAction({ companyId: action.payload.companyId }))
        ]),
        err => all([
            put(SendClientCommandFailureAction()),
            put(ReportErrorAction(err))
        ])
    )
}

export function* centersSaga() {
    yield takeLatest(FetchClientCommandsAction, fetchClientCommands)
    yield takeLatest(SendClientCommandAction, postClientCommand)
}
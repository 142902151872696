import { ReactElement } from 'react'
import { Button, Grid, Typography } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../interfaces/RootState'
import HeaderBackground from '../../../assets/hydrolink_header_v1.svg'
import HydrolinkLogo from '../../../assets/LOGO_header.svg'
import { useTranslation } from 'react-i18next'
import HamburgerMenu from '../hamburger-menu/HamburgerMenu'
import LanguageSelector from '../language-selector/LanguageSelector'
import { logout } from '../../../redux/auth/auth'
import { colors } from '../../../theme'

type Props = {
    email: string,
    isAdmin: boolean,
    showUserUtils: boolean
}


export function Header({ email, isAdmin, showUserUtils }: Props): JSX.Element {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const doLogout = () => {
        dispatch(logout())
    }

    const headerBgStyle = {
        backgroundImage: `url(${HeaderBackground})`,
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '150px',
    }

    return (
        <div style={headerBgStyle}>
            <div style={{ margin: '0 auto', maxWidth: '1280px' }}>
                <Grid
                    container
                    justifyContent={'space-between'}
                    wrap={'nowrap'}
                >
                    {/* LEFT SIDE */}
                    <Grid item xs={10} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        {showUserUtils && <HamburgerMenu isAdmin={isAdmin} />}
                    </Grid>

                    {/* LOGO */}
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <img src={HydrolinkLogo} alt="hydrolink-logo" style={{ width: '200px', margin: '0 auto' }} />
                    </Grid>

                    {/* RIGHT SIDE */}
                    <Grid item xs={10}>
                        <Grid container wrap={'nowrap'} justifyContent={'flex-end'} style={{ height: '100%', paddingTop: '1em' }} >

                            {showUserUtils && <>
                                <Grid item style={{ padding: '7px 6px' }}>
                                    <Typography style={{ color: colors.neutral00, fontSize: '.85rem' }}>
                                        {email}
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Button
                                        endIcon={<ChevronRight />}
                                        style={{ color: colors.neutral00, fontSize: '.85rem', textTransform: 'inherit', whiteSpace: 'nowrap' }}
                                        onClick={doLogout}
                                    >
                                        {t("landing.logout")}
                                    </Button>
                                </Grid>
                            </>}

                            <Grid item>
                                <LanguageSelector />
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function ConnectedHeader({ showUserUtils }: { showUserUtils: boolean }): JSX.Element {
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const email = useSelector((state: RootState): string => state.hydrolink.auth.email)

    return <Header email={email} isAdmin={isAdmin} showUserUtils={showUserUtils} />
}

// Maybe showUserUtils could have a better name? It's functionality is to hide the hamburger menu, email and logout button
export const elementWithHeader = (element: ReactElement, showUserUtils: boolean = true) => {
    return <>
        <ConnectedHeader showUserUtils={showUserUtils} />
        {element}
    </>
}

import { createAction } from "@reduxjs/toolkit"
import {
    CreateCompanyPayload,
    FetchCompaniesSuccessPayload,
    FetchCompanyApiKeyInput,
    FetchCompanyApiKeySuccessPayload,
    RefreshCompanyApiKeyInput,
    RefreshCompanyApiKeySuccessPayload,
    SaveCompanyPayload,
    UpdateUserRolePayload,
    DeleteCompanyUserPayload,
    UpdateUserRoleAsManagerPayload,
    UpdateApiAccessPayload,
    FilterCompaniesPayload,
    SortCompaniesPayload,
    PremiumTogglePayload,
    DeleteCompanyPayload,
    FetchAlarmTypesSuccessPayload,
    MergeApartmentsPayload
} from "./companies-types"

export const UpdateUserRoleAction = createAction<UpdateUserRolePayload>('UPDATE_USER_ROLE')
export const UpdateUserAsManagerAction = createAction<UpdateUserRoleAsManagerPayload>('UPDATE_USER_AS_MANAGER')
export const UpdateUserAsManagerSuccessAction = createAction<void>('UPDATE_USER_AS_MANAGER_SUCCESS')
export const UpdateUserRoleSuccessAction = createAction<any>('UPDATE_USER_ROLE_SUCCESS')
export const UpdateUserAsManagerFailureAction = createAction<void>('UPDATE_USER_AS_MANAGER_FAILURE')
export const UpdateUserRoleFailureAction = createAction<void>('UPDATE_USER_ROLE_FAILURE')


export const DeleteCompanyUserAction = createAction<DeleteCompanyUserPayload>('DELETE_COMPANY_USER')
export const DeleteCompanyUserSuccessAction = createAction<void>('DELETE_COMPANY_USER_SUCCESS')
export const DeleteCompanyUserFailureAction = createAction<void>('DELETE_COMPANY_USER_FAILURE')

export const FetchCompaniesAction = createAction<void>('FETCH_COMPANIES')
export const FetchCompaniesSuccessAction = createAction<FetchCompaniesSuccessPayload>('FETCH_COMPANIES_SUCCESS')
export const FetchCompaniesFailureAction = createAction<void>('FETCH_COMPANIES_FAILURE')

export const FetchAlarmTypesAction = createAction<void>('FETCH_ALARM_TYPES')
export const FetchAlarmTypesSuccessAction = createAction<FetchAlarmTypesSuccessPayload>('FETCH_ALARM_TYPES_SUCCESS')
export const FetchAlarmTypesFailureAction = createAction<void>('FETCH_ALARM_TYPES_FAILURE')



export const FetchCompanyApiKeyAction = createAction<FetchCompanyApiKeyInput>('FETCH_COMPANY_API_KEY')
export const FetchCompanyApiKeySuccessAction = createAction<FetchCompanyApiKeySuccessPayload>('FETCH_COMPANY_API_KEY_SUCCESS')
export const FetchCompanyApiKeyFailureAction = createAction<void>('FETCH_COMPANY_API_KEY_FAILURE')

export const FilterCompaniesAction = createAction<FilterCompaniesPayload>('FILTER_COMPANIES')
export const SortCompaniesAction = createAction<SortCompaniesPayload>('SORT_COMPANIES')

export const RefreshCompanyApiKeyAction = createAction<RefreshCompanyApiKeyInput>('REFRESH_COMPANY_API_KEY')
export const RefreshCompanyApiKeySuccessAction = createAction<RefreshCompanyApiKeySuccessPayload>('REFRESH_COMPANY_API_KEY_SUCCESS')
export const RefreshCompanyApiKeyFailureAction = createAction<void>('REFRESH_COMPANY_API_KEY_FAILURE')

export const CreateCompanyAction = createAction<CreateCompanyPayload>('CREATE_COMPANY')
export const CreateCompanySuccessAction = createAction<void>('CREATE_COMPANY_SUCCESS')
export const CreateCompanyFailureAction = createAction<string>('CREATE_COMPANY_FAILURE')
export const CloseCreateCompanyModal = createAction<void>('CLOSE_CREATE_COMPANY_MODAL')
export const OpenCreateCompanyModal = createAction<void>('OPEN_CREATE_COMPANY_MODAL')
export const SaveCompanyBasicInfoAction = createAction<SaveCompanyPayload>('SAVE_COMPANY_BASIC_INFO')
export const SaveCompanyBasicInfoSuccessAction = createAction<void>('SAVE_COMPANY_BASIC_INFO_SUCCESS')
export const SaveCompanyBasicInfoFailureAction = createAction<void>('SAVE_COMPANY_BASIC_INFO_FAILURE')


export const CompaniesPaginationPageChangeAction = createAction<number>('COMPANIES_PAGINATION_PAGE_CHANGE')
export const CompaniesPaginationItemsPerPageChangeAction = createAction<number>('COMPANIES_PAGINATION_ITEMS_PER_PAGE_CHANGE')

export const UpdateApiAccessAction = createAction<UpdateApiAccessPayload>('UPDATE_API_ACCESS')
export const UpdateApiAccessSuccessAction = createAction<UpdateApiAccessPayload>('UPDATE_API_ACCESS_SUCCESS')

export const MergeApartmentsAction = createAction<MergeApartmentsPayload>('MERGE_APARTMENTS')
export const MergeApartmentsSuccessAction = createAction<void>('MERGE_APARTMENTS_SUCCESS')
export const MergeApartmentsFailureAction = createAction<void>('MERGE_APARTMENTS_FAILURE')

export const PremiumToggleAction = createAction<PremiumTogglePayload>('PREMIUM_TOGGLE')
export const PremiumToggleSuccessAction = createAction<void>('PREMIUM_TOGGLE_SUCCESS')
export const PremiumToggleFailureAction = createAction<void>('PREMIUM_TOGGLE_FAILURE')
export const DeleteCompanyAction = createAction<DeleteCompanyPayload>('DELETE_COMPANY')
export const DeleteCompanySuccessAction = createAction<void>('DELETE_COMPANY_SUCCESS')

import { AnyAction } from "redux"
import {
    DeleteNoteAction,
    DeleteNoteFailureAction,
    DeleteNoteSuccessAction,
    FetchNotesAction,
    FetchNotesFailureAction,
    FetchNotesSuccessAction,
    FilterNotesAction,
    InsertNoteAction,
    InsertNoteFailureAction,
    InsertNoteSuccessAction,
    SortNotesAction
} from "./notes-actions"
import { NotesState } from "./notes-types"


const initialState: NotesState = {
    notes: [],
    loading: false,
    filteredAndSortedNotes: [],
    sortBy: 'date',
    ascending: true
}

const loadingTrue = (state: NotesState) => ({ ...state, loading: true })
const loadingFalse = (state: NotesState) => ({ ...state, loading: false })

const actions = (state: NotesState, action: AnyAction) => {

    if (FetchNotesAction.match(action)) {
        return loadingTrue(state)
    }

    if (InsertNoteAction.match(action)) {
        return loadingTrue(state)
    }

    if (DeleteNoteAction.match(action)) {
        return loadingTrue(state)
    }
    return state
}

const successActions = (state: NotesState, action: AnyAction) => {
    if (FetchNotesSuccessAction.match(action)) {
        action.payload.sort((a, b) => {
            if (a.created < b.created) { return -1 }
            if (a.created > b.created) { return 1 }
            return 0;
        })
        return {
            ...state,
            notes: action.payload,
            filteredAndSortedNotes: action.payload,
            loading: false
        }
    }

    if (InsertNoteSuccessAction.match(action)) {
        return loadingFalse(state)
    }

    if (DeleteNoteSuccessAction.match(action)) {
        return loadingFalse(state)
    }
    return state
}

const failureActions = (state: NotesState, action: AnyAction) => {

    if (FetchNotesFailureAction.match(action)) {
        return loadingFalse(state)
    }

    if (InsertNoteFailureAction.match(action)) {
        return loadingFalse(state)
    }

    if (DeleteNoteFailureAction.match(action)) {
        return loadingFalse(state)
    }
    return state

}

const otherActions = (state: NotesState, action: AnyAction) => {
    if (SortNotesAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
            filteredAndSortedNotes: action.payload.notes
        }
    }

    if (FilterNotesAction.match(action)) {
        return {
            ...state,
            filteredAndSortedNotes: action.payload
        }
    }
    return state
}



export default function notesReducer(state: NotesState = initialState, action: AnyAction): NotesState {

    state = actions(state, action)
    state = successActions(state, action)
    state = failureActions(state, action)
    state = otherActions(state, action)

    return state
}


import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementWithHeader } from '../../components/common/header/Header';
import { RootState } from '../../interfaces/RootState';
import { CompaniesHeader } from './Companies.style';
import CreateCompaniesButton from './CreateCompaniesButton';
import CompaniesTable from './CompaniesTable';
import { CreateCompaniesModal } from './CreateCompaniesModal';
import { CompaniesPaginationItemsPerPageChangeAction, CompaniesPaginationPageChangeAction, FilterCompaniesAction, OpenCreateCompanyModal } from '../../redux/companies/companies-actions';
import { ViewContainer } from "../../components/ViewContainer";
import { FetchUsersAction } from '../../redux/users/users-actions';
import Pagination from '../../components/common/pagination';
import CommonSearchBar from '../../components/common/search-bar/CommonSearchBar';
import { Company, Manager } from '../../redux/companies/companies-types';


function Companies(): ReactElement {
    const dispatch = useDispatch()
    const openModal = useSelector((state: RootState) => state.hydrolink.companies.createCompanyModalOpen)
    const users = useSelector((state: RootState) => state.hydrolink.users.users)
    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies)
    const companiesCount = useSelector((root: RootState) => root.hydrolink.companies.companies).length
    const currentPage = useSelector((root: RootState) => root.hydrolink.companies.pagination.currentPage)
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)


    useEffect(() => {
        dispatch(FetchUsersAction())
    }, [dispatch])

    const handleOpenCreateCompanyModal = () => {
        dispatch(OpenCreateCompanyModal())
    }

    const pageChange = (pageNumber: number) => {
        dispatch(CompaniesPaginationPageChangeAction(pageNumber))
    }

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(CompaniesPaginationItemsPerPageChangeAction(itemsPerPage))
    }

    const managerEmail = (manager: Manager | undefined): string => {
        if (users == undefined || manager == undefined) {
            return ""
        }
        
        let foundManager = users.find(user => user.id === manager.userId)

        return foundManager ? foundManager.email : ""
    }

    const companyPrincipalManagerEmail = (company: Company) =>
        managerEmail(company.managers.find(manager => manager.principal))


    const searchData = (company: Company) => [
        company.name,
        company.streetAddress,
        company.town,
        companyPrincipalManagerEmail(company) ?? '',
    ]

    return <>
        <CreateCompaniesModal openModal={openModal} />
        <ViewContainer>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <CommonSearchBar
                    items={companies}
                    searchData={searchData}
                    filterAction={FilterCompaniesAction}
                    pageChange={pageChange}

                />
                <CompaniesHeader>
                </CompaniesHeader>
                {isAdmin && <CreateCompaniesButton openCreateCompanyModal={handleOpenCreateCompanyModal} />}
            </div>
        </ViewContainer>

        <ViewContainer>
            <CompaniesTable companyPrincipalManagerEmail={companyPrincipalManagerEmail} />
        </ViewContainer>
        <Pagination
            current={currentPage}
            itemsCount={companiesCount}
            currentPageCallback={pageChange}
            itemsPerPageCallback={itemsPerPageChange}
        />
    </>
}

export default function CompaniesView() {
    return elementWithHeader(<Companies />)
}

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';
import { DeleteCompanyAction, PremiumToggleAction } from '../../redux/companies/companies-actions';
import { AlarmStatus, Company } from '../../redux/companies/companies-types';
import { TableCell, TableRow } from '../../components/Table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import LeakIcon from '../../assets/ICON_Leak.svg'
import DisconnectIcon from '../../assets/ICON_moduuli_irti.svg'
import AlertIcon from '../../assets/ICON_alert.svg'
import OkIcon from '../../assets/ICON_ok.svg'
import { FormControlLabel, Switch, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledIconButton } from './Companies.style';
import PremiumIcon from '../../assets/ICON_star.svg'

export default function CompanyTableRow({ company }: { company: Company }) {
    const t = useTranslation().t
    const dispatch = useDispatch()
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const users = useSelector((state: RootState) => state.hydrolink.users.users)

    const deleteCompany = () => {
        let confirmation = prompt(t("companies.deleteConfirmation", { companyName: company.name }))

        if (confirmation && confirmation.toLocaleLowerCase() === company.name.toLowerCase()) {
            dispatch(DeleteCompanyAction(company.id))
        }
    }

    const changePremium = () => {
        let confirmed = false
        if (company.premium && window.confirm(t("companies.removePremiumConfirmation", { companyName: company.name }))) {
            confirmed = true
        } else if (!company.premium && window.confirm(t("companies.addPremiumConfirmation", { companyName: company.name }))) {
            confirmed = true
        }
        if (confirmed) {
            dispatch(PremiumToggleAction({ companyId: company.id, premium: !company.premium }))
        }
    }

    const getManagerCell = () => {
        const principalManager = company.managers.find((manager) => manager.principal)

        if (principalManager != undefined) {
            let foundPrincipalManager = users.find(u => u.id === principalManager.userId)
            if (foundPrincipalManager != undefined) {
                return foundPrincipalManager.email + (company.managers.length > 1 ? "..." : "")
            }
        }
    }

    // These alarm icons are only used in these table rows so here they shall stay (Unless you want them somewhere else idc)
    const StyledAlarmIcon = ({ src, alt }: { src: string, alt: string }) =>
        <img style={{ width: '20px', marginRight: '20px' }} src={src} alt={alt} />

    const getAlarmIcons = (alarmStatus: AlarmStatus): JSX.Element => <div style={{ width: '75%', display: 'flex', justifyContent: 'space-between' }}>
        <Tooltip title={(alarmStatus.leakage ? t('meters.alarmStatus.leakage') : t('meters.alarmStatus.ok')) ?? ""}  >
            <div  >
                <StyledAlarmIcon src={!alarmStatus.leakage ? OkIcon : LeakIcon} alt={"leakage-icon"} />
            </div>
        </Tooltip>
        <Tooltip title={(alarmStatus.alarmActive ? t('meters.alarmStatus.alarmActive') : t('meters.alarmStatus.ok')) ?? ""}  >
            <div  >
                <StyledAlarmIcon src={!alarmStatus.alarmActive ? OkIcon : AlertIcon} alt={"alarm-icon"} />
            </div>
        </Tooltip>
        <Tooltip title={(alarmStatus.disconnectedMeters ? t('meters.alarmStatus.disconnectedMeters') : t('meters.alarmStatus.ok')) ?? ""}  >
            <div  >
                <StyledAlarmIcon src={!alarmStatus.disconnectedMeters ? OkIcon : DisconnectIcon} alt={"disconnect-icon"} />
            </div>
        </Tooltip>
    </div >


    return <TableRow>
        <TableCell> {isAdmin ?
            <FormControlLabel control={<Switch checked={company.premium} onChange={() => changePremium()} />} label="Premium" />
            : company.premium && <img src={PremiumIcon} alt="premium-icon" width={'20px'} />}
        </TableCell>
        <TableCell>
            <Link to={`/companies/${company.id}/apartments`}>
                {company.name}
            </Link>
        </TableCell>
        <TableCell>
            {getAlarmIcons(company.alarmStatus)}
        </TableCell>
        <TableCell>{company.streetAddress}</TableCell>
        <TableCell>{company.town}</TableCell>
        <TableCell>
            {getManagerCell()}
        </TableCell>
        <TableCell>
            <StyledIconButton
                disabled={!isAdmin}
                onClick={() => deleteCompany()}
            >
                <FontAwesomeIcon size='xs' icon={faTrash} />
            </StyledIconButton>
        </TableCell>
    </TableRow>
}
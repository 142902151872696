import React from "react";
import { Dialog } from "@mui/material";
import { StyledDialogActions, StyledTitle } from "./Modal.style";
import CloseIcon from '@mui/icons-material/Close'
import { spacings } from "../../../theme";

export function Modal({ open, title, children, dialogActions, closeModal }: { open: boolean, title: string, children: React.ReactNode, dialogActions: JSX.Element, closeModal: () => void }) {

  const onCloseHandler = (_: React.SyntheticEvent, reason: string) => {
    // Called when the user clicks outside of modal
    if (reason === "backdropClick") {
      closeModal()
    }
  }

  return <Dialog
    open={open}
    maxWidth='md'
    fullWidth
    onClose={onCloseHandler}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: `${spacings.narrow} ${spacings.stardardSpacing} 0 ${spacings.stardardSpacing}` }}>
      <StyledTitle>{title}</StyledTitle>
      <CloseIcon onClick={closeModal} />
    </div>
    <div style={{ padding: `0 ${spacings.stardardSpacing} 0 ${spacings.stardardSpacing}` }}>
      {children}
    </div>
    <StyledDialogActions>
      {dialogActions}
    </StyledDialogActions>
  </Dialog>
}
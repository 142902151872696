import React, { ElementType } from "react";
import * as Mui from "@mui/material";
import { TableCellBaseProps } from "@mui/material";
import { colors } from "../theme";
import styled from "styled-components";

// Table Container
type TableContainerProps = {
  component: ElementType<any>,
  children: React.ReactNode,
};
export const TableContainer = (props: TableContainerProps) => {
  return <Mui.TableContainer {...props} />;
};

type TableProps = {
  children: React.ReactNode,
};
export const Table = (props: TableProps) => {
  return <Mui.Table {...props} />;
};

// Table Head
type TableHeadProps = {
  children: React.ReactNode,
};
const StyledTableHead = styled(Mui.TableHead)(({ theme }) => ({
  color: colors.neutral06,
  fontWeight: "bolder",
}));
export const TableHead = (props: TableHeadProps) => {
  return <StyledTableHead {...props} />;
};

// Table Body
type TableBodyProps = {
  children: React.ReactNode,
};
export const TableBody = (props: TableBodyProps) => {
  return <Mui.TableBody {...props} />;
};

// Table Row
type TableRowProps = {
  children: React.ReactNode,
  onClick?: () => void, //what happens when you click the "open" icon
  style?: React.CSSProperties
};
const StyledTableRow = styled(Mui.TableRow)(({ theme }) => ({
}));
export const TableRow = (props: TableRowProps) => {
  return <StyledTableRow {...props} />;
};

// Table Cell
type TableCellProps = {
  children?: React.ReactNode,
  component?: ElementType<TableCellBaseProps>,
  style?: React.CSSProperties,
  align?: "inherit" | "left" | "center" | "right" | "justify",
  scope?: string,
  colSpan?: number,
  rowSpan?: number,
  width?: string,
  title?: string
  onClick?: () => void
};
const StyledTableCell = styled(Mui.TableCell)(({ theme }) => ({
  color: "inherit",
  maxWidth: '30em',
  fontWeight: "inherit",
}));
export const TableCell = (props: TableCellProps) => {
  return <StyledTableCell {...props}/>;
};


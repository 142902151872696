import ReactFlagsSelect from 'react-flags-select'
import '../../../assets/App.css'
import '../../../assets/Mobile.css'
import i18next from 'i18next'
import { useEffect } from 'react'
import {useTranslation} from "react-i18next";

type Language = 'sv' | 'fi' | 'en'

const LanguageSelector = () => {

  useEffect(() => {
    let l = localStorage.getItem("language")
    if (l) {
      i18next.changeLanguage(l)
    }
  }, [])


  const getLanguage = (country: string): Language => {
    let la: Language = "fi"
    switch (country) {
      case "FI":
        la = "fi"
        break;
      case "SE":
        la = "sv"
        break;
      case "GB":
        la = "en"
        break;
    }
    localStorage.setItem("language", la)
    return la
  }

  return (
    <ReactFlagsSelect
      countries={['FI', 'SE', 'GB']}
      placeholder={i18next.language}
      onSelect={(country) => {
        i18next.changeLanguage(getLanguage(country))
      }}
      selected={i18next.language}
      showOptionLabel={false}
      showSelectedLabel={false}
      className={'language-selector'}
      selectButtonClassName={'language-select-button'}
    />
  )
}

export const getCurrentLocale = (): Language => {

  const language = useTranslation().i18n.language

  if (language != 'sv' && language != 'fi' && language != 'en'){
    return 'fi'
  }

  return language

}

export default LanguageSelector

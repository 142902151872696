import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh'
import { TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  FetchCompanyApiKeyAction,
  RefreshCompanyApiKeyAction,
} from "../../redux/companies/companies-actions";
import { Button } from "../../components/Button";
import { alignments, spacings } from "../../theme";
import { RootState } from "../../interfaces/RootState";
import { useButtonRights } from "../../utils/useButtonRights";


type Props = {
  companyId: number,
  apiKey: string | null,
};

const ApiKey = ({ companyId, apiKey }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiAccessEnabled = useSelector((state: RootState) => state.hydrolink.companies.companies.find((c) => c.id === companyId))?.apiAccessEnabled
  const refreshApiKeyButtonRights = useButtonRights(companyId, "manager", !apiAccessEnabled)

  useEffect(() => {
    if (apiAccessEnabled) {
      dispatch(FetchCompanyApiKeyAction({ companyId }));
    }
  }, [dispatch, companyId, apiAccessEnabled]);

  const onRefreshApiKeyClicked = () => {
    dispatch(RefreshCompanyApiKeyAction({ companyId }));
  };

  return (<div style={{ ...alignments.evenHorizontal, ...alignments.twoElementGrid, marginTop: spacings.inlineSpacing }}>
    <TextField disabled InputProps={{
      style: { width: '280px' },
    }}
      value={!apiAccessEnabled ? t('companies.info.apiAccessDisabled') : (apiKey ?? "")} />
    <Button disabled={refreshApiKeyButtonRights} onClick={onRefreshApiKeyClicked} startIcon={<RefreshIcon />} >
      {t('companies.info.newApiKey')}
    </Button>
  </div>);
};

export default ApiKey;

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { UpdateApiAccessAction } from "../../redux/companies/companies-actions"
import { RootState } from "../../interfaces/RootState"
import Checkbox from "../../components/Checkbox"
import { Tooltip } from "@mui/material"

type Props = {
    companyId: number,
    isAdmin: boolean
}

export default function ApiAccess({ companyId, isAdmin }: Props) {
    const dispatch = useDispatch()
    const t = useTranslation().t
    
    const apiAccessEnabled = useSelector((state: RootState) => state.hydrolink.companies.companies.find((c) => c.id === companyId))?.apiAccessEnabled

    const toggleApiAccess = () => {
        if (isAdmin) {
            dispatch(UpdateApiAccessAction({ companyId, apiAccessEnabled: !apiAccessEnabled }))
        }
    }

    return <Tooltip title={!isAdmin ? t("companies.info.apiAccessContactKoka") as string : ""}>
        <div> {/* div required for tooltip */}
            <Checkbox
                label={t('companies.info.apiAccess')}
                checked={apiAccessEnabled!!}
                onChange={toggleApiAccess}
                disabled={!isAdmin}
            />
        </div>
    </Tooltip>
}
import { all, call, Effect, put, select, takeLatest } from "redux-saga/effects"
import { ReportErrorAction } from "../error/error-actions"
import * as api from "../../api"
import { API_URL } from "../../constants/urls"
import {
    FetchReportsAction,
    FetchReportsFailureAction,
    FetchReportsSuccessAction,
    FetchCSVAction,
    FetchCSVFailureAction,
    FetchCSVSuccessAction,
    FetchInitReportAction,
    FetchInitReportSuccessAction,
    FetchInitReportFailureAction,
} from "./reports-actions"
import {
    FetchReportsPayload,
    FetchCSVPayload,
    FetchInitReportPayload,
    Report,
} from "./reports-types"
import JsFileDownloader from 'js-file-downloader';
import selectIdToken from "../auth/select-id-token"

export function* fetchReports(action: { payload: FetchReportsPayload }) {
    yield api.get(
        `/companies/${action.payload.companyId}/consumption_report?from_incl=${action.payload.from}&to_incl=${action.payload.until}`,
        (resp: Report[]) => put(FetchReportsSuccessAction({ reports: resp, apartments: action.payload.apartments })),
        err => all([
            put(FetchReportsFailureAction()),
            put(ReportErrorAction(err))
        ])
    )
}

export function* fetchInitReport(action: { payload: FetchInitReportPayload }) {
    const idToken: string = yield select(selectIdToken)
    const result: Effect = yield call(() => new JsFileDownloader({
        url: `${API_URL}/companies/${action.payload.companyId}/initreport.pdf`,
        method: 'GET',
        headers: [
            { name: 'Authorization', value: `Bearer ${idToken}` },
        ]
    })
        .then(function () {
            return put(FetchInitReportSuccessAction())
        })
        .catch(function (err) {
            return all([
                put(FetchInitReportFailureAction()),
                put(ReportErrorAction(err))
            ])
        }))
    yield result
}

export function* fetchCSV(action: { payload: FetchCSVPayload }) {
    const idToken: string = yield select(selectIdToken)
    const fileUrl = `${API_URL}/companies/${action.payload.companyId}/report/${action.payload.format}?from_incl=${action.payload.from}&to_incl=${action.payload.until}`;

    // js-file-downloader doesn't give an export to their `DownloadError` type so we make it here
    interface DownloadError extends Error {
        request: XMLHttpRequest
    }

    const result: Effect = yield call(() => new JsFileDownloader({
        url: fileUrl,
        method: 'GET',
        headers: [
            { name: 'Authorization', value: `Bearer ${idToken}` },
        ]
    })
        .then(function () {
            return put(FetchCSVSuccessAction())
        })
        .catch(function (err: DownloadError) {
            // `request.responseText` won't work here as the `responseType` is `arraybuffer`
            // so we have to decode it to a normal string
            let errorMessage = new TextDecoder().decode(err.request.response)

            return all([
                put(FetchCSVFailureAction()),
                put(ReportErrorAction(errorMessage))
            ])
        }))

    yield result
}

export function* reportsSaga() {
    yield takeLatest(FetchReportsAction, fetchReports)
    yield takeLatest(FetchCSVAction, fetchCSV)
    yield takeLatest(FetchInitReportAction, fetchInitReport)
}


import { useTranslation } from "react-i18next"
import { all, put, takeLatest } from "redux-saga/effects"
import * as api from "../../api"
import { convertApiUser } from "../../api/api-conversions"
import { ApiUser } from "../../api/api-types"
import { ReportErrorAction } from "../error/error-actions"
import {
  FetchUsersAction,
  FetchUsersSuccessAction,
  UpdateUserAction,
  UpdateUserAdminAction,
  UpdateUserSuccessAction,
  CloseCreateUserModalAction,
  CreateUserAction,
  CreateUserFailureAction,
  CreateUserSuccessAction,
  CreateNewUserPasswordAction,
  UpdateUserEmailAction,
  CreateNewUserPasswordFailureAction,
  UpdateUserEmailFailureAction,
  UpdateUserEmailSuccessAction,
  CreateNewUserPasswordSuccessAction,
  DeleteUserAction,
  DeleteUserSuccessAction,
  FetchUsersFailureAction,
  UpdateUserFailureAction,
  UpdateUserAdminFailureAction,
  DeleteUserFailureAction,
} from "./users-actions"

import {
  CreateNewUserPasswordPayload,
  CreateUserPayload,
  DeleteUserPayload,
  UpdateUserEmailPayload,
  UpdateUserPayload,
  UpdateUserAdminPayload
} from "./users-types"


export function* fetchAllUsers() {

  yield api.get(
    `/users`,
    (resp: ApiUser[]) => put(FetchUsersSuccessAction(resp.map(convertApiUser))),
    err => all([
      put(FetchUsersFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* updateUser(action: { payload: UpdateUserPayload }) {

  yield api.put(
    `/users/${action.payload.id}/basic_info`,
    {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      username: action.payload.username,
      phone: action.payload.phone
    },
    () => all([
      put(UpdateUserSuccessAction()),
      put(FetchUsersAction())
    ]),
    err => all([
      put(UpdateUserFailureAction()),
      put(FetchUsersAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* updateUserAdmin(action: { payload: UpdateUserAdminPayload }) {

  yield api.put(
    `/users/${action.payload.userId}/user_admin`,
    action.payload.admin,
    () => all([
      put(UpdateUserSuccessAction()),
      put(FetchUsersAction())
    ]),
    err => all([
      put(UpdateUserAdminFailureAction()),
      put(FetchUsersAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* deleteUser(action: { payload: DeleteUserPayload }) {

  yield api.remove(
    `/users/${action.payload}`,
    {},
    () => all([
      put(DeleteUserSuccessAction(action.payload)),
      put(FetchUsersAction())
    ]),
    err => all([
      put(DeleteUserFailureAction()),
      put(FetchUsersAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* createUser(action: { payload: CreateUserPayload }) {

  yield api.post(
    `/users`,
    action.payload,
    () => all([
      put(CreateUserSuccessAction()),
      put(CloseCreateUserModalAction()),
      put(FetchUsersAction())
    ]),
    err => all([
      put(CreateUserFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* createNewUserPassword(action: { payload: CreateNewUserPasswordPayload }) {
  yield api.post(
    `/users/${action.payload}/password`,
    {},
    () => put(CreateNewUserPasswordSuccessAction()),
    err => all([
      put(CreateNewUserPasswordFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* updateUserEmail(action: { payload: UpdateUserEmailPayload }) {

  yield api.put(
    `/users/${action.payload.userId}/email`,
    { email: action.payload.newEmail },
    () => all([
      put(UpdateUserEmailSuccessAction()),
      put(FetchUsersAction())
    ]),
    err => all([
      put(UpdateUserEmailFailureAction()),
      put(FetchUsersAction()),
      put(ReportErrorAction(err))
    ]))
}


export function* usersSaga() {
  yield takeLatest(FetchUsersAction, fetchAllUsers)
  yield takeLatest(UpdateUserAction, updateUser)
  yield takeLatest(UpdateUserAdminAction, updateUserAdmin)
  yield takeLatest(FetchUsersAction, fetchAllUsers)
  yield takeLatest(CreateUserAction, createUser)
  yield takeLatest(CreateNewUserPasswordAction, createNewUserPassword)
  yield takeLatest(UpdateUserEmailAction, updateUserEmail)
  yield takeLatest(DeleteUserAction, deleteUser)
}

import { all, put, takeLatest } from "redux-saga/effects"
import { ReportErrorAction } from "../error/error-actions"
import * as api from "../../api"
import {
    DeleteNoteAction,
    DeleteNoteFailureAction,
    DeleteNoteSuccessAction,
    FetchNotesAction,
    FetchNotesFailureAction,
    FetchNotesSuccessAction,
    InsertNoteAction,
    InsertNoteFailureAction,
    InsertNoteSuccessAction
} from "./notes-actions"
import {
    DeleteNotePayLoad,
    FetchNotesPayLoad,
    InsertNotePayLoad
} from "./notes-types"
import { ApiNote } from "../../api/api-types"
import { convertApiNote } from "../../api/api-conversions"



export function* fetchNotes(action: { payload: FetchNotesPayLoad }) {
  yield api.get(
    `/companies/${action.payload.companyId}/notes`,
    (resp: ApiNote[]) => put(FetchNotesSuccessAction(resp.map(convertApiNote))),
    err => all([
      put(FetchNotesFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* deleteNote(action: { payload: DeleteNotePayLoad }) {
    yield api.remove(
        `/companies/${action.payload.companyId}/notes/${action.payload.id}`,
        {},
        () => all([
          put(DeleteNoteSuccessAction()),
          put(FetchNotesAction({ companyId: action.payload.companyId }))
        ]),
        err => all([
          put(DeleteNoteFailureAction()),
          put(ReportErrorAction(err))
        ]))
}

export function* insertNote(action: { payload: InsertNotePayLoad }) {
    yield api.post(
        `/companies/${action.payload.companyId}/notes`,
        { comment: action.payload.comment },
        () => all([
          put(InsertNoteSuccessAction()),
          put(FetchNotesAction({ companyId: action.payload.companyId }))
        ]),
        err => all([
          put(InsertNoteFailureAction()),
          put(ReportErrorAction(err))
        ])
    )
}


export function* notesSaga() {
    yield takeLatest(FetchNotesAction, fetchNotes)
    yield takeLatest(DeleteNoteAction, deleteNote)
    yield takeLatest(InsertNoteAction, insertNote)
}

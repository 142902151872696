import { useTranslation } from 'react-i18next';
import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TextField } from '@mui/material';
import { Button } from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { RootState } from '../../interfaces/RootState';
import { UpdateUserRoleAction, DeleteCompanyUserAction, UpdateUserAsManagerAction } from '../../redux/companies/companies-actions';
import { Company, Role, Manager } from '../../redux/companies/companies-types';
import ManagerRow from './ManagerRow';
import DropDown from '../../components/DropDown';
import { colors } from "../../theme";
import { User } from '../../redux/users/users-types';
import CreateUserModal from '../users/CreateUserModal';
import { ReportErrorAction } from '../../redux/error/error-actions';
import CreateUserButton from '../users/CreateUserButton';
import ButtonsDisplayer from '../../components/FloatButtonsRight';


type DropDownItem = {
    value: string,
    displayText: string
}

const actionColors = {
    neutralColor: colors.neutral00,
    addColor: colors.success06,
    removeColor: colors.warm06
}

//New users of this type are users which somebody with the manager role may add. Admins adds users from the global user list instead.
type NewUser = {
    id: number,
    email: string,
    firstName: string,
    lastName: string
}

const Managers = ({ company }: { company: Company }) => {

    const t = useTranslation().t
    const dispatch = useDispatch()
    const managers = useSelector((state: RootState) => state.hydrolink.companies.companies.find(c => c.id === company.id)?.managers!!)
    const [companyManagers, setCompanyManagers] = React.useState<Manager[]>(managers)
    const users = useSelector((state: RootState) => state.hydrolink.users.users)
    const companies = useSelector((state: RootState) => state.hydrolink.companies)
    const [selectedUserId, setSelectedUserId] = React.useState<string>('')
    const [allUsersDropDownList, setAllUsersDropDownList] = React.useState<DropDownItem[]>([])
    const [role, setRole] = React.useState<Role>('viewer')
    const [changes, setChanges] = React.useState<boolean>(false)
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const myRole = useSelector((state: RootState): Role => state.hydrolink.auth.roleByCompanyId.find(c => c.companyId === company.id)?.role ?? "viewer")
    const [newUserEmail, setNewUserEmail] = React.useState<string>('')
    const [newUserFirstName, setNewUserFirstName] = React.useState<string>('')
    const [newUserLastName, setNewUserLastName] = React.useState<string>('')
    const [newUsers, setNewUsers] = React.useState<NewUser[]>([])
    const [newUserId, setNewUserId] = React.useState<number>(-1)
    const newUserLastNameField = useRef()
    const newUserFirstNameField = useRef()
    const isAllowedToAddUsers: boolean = isAdmin || myRole === 'manager'

    useEffect(() => {
        //Creating a drop down list of availabe users
        let dropDownList: DropDownItem[] = []
        for (let u of users) {
            if ((u.firstName !== '' || u.lastName !== '') && !companyManagers!!.find(m => m.userId === u.id)) {
                dropDownList.push({ value: u.id.toString(), displayText: u.firstName + ' ' + u.lastName })
            }
        }
        setAllUsersDropDownList(dropDownList)
    }, [users, companyManagers, companies])

    useEffect(() => {
        setCompanyManagers(managers)
    }, [managers])


    const add = () => {
        if (selectedUserId !== '' || newUserEmail !== '') {
            let id = parseInt(selectedUserId)
            let user = users.find(u => u.id === id)
            if (user !== undefined) {
                addUserAsAdmin()
            } else if (myRole === 'manager') {
                if (!addUserAsManager()) {
                    return
                }
                id = newUserId
            }
            let manager: Manager = { userId: id, role: role, principal: false, toBeSaved: true }
            setSelectedUserId('')
            setCompanyManagers([...companyManagers, manager])
            setChanges(true)
        }
    }

    const addUserAsAdmin = () => {
        let allUsersDropDownListCopy: DropDownItem[]
        allUsersDropDownListCopy = allUsersDropDownList.filter(u => u.value !== selectedUserId)
        setAllUsersDropDownList(allUsersDropDownListCopy)
    }

    const addUserAsManager = (): boolean => {
        if (newUserFirstName == "") {
            (newUserFirstNameField.current!! as HTMLElement).focus()
            dispatch(ReportErrorAction(t("errors.firstNameMissing")))
            return false
        }

        if (newUserLastName == "") {
            (newUserLastNameField.current!! as HTMLElement).focus()
            dispatch(ReportErrorAction(t("errors.lastNameMissing")))
            return false
        }

        const newUser: NewUser = {
            id: newUserId,
            email: newUserEmail,
            firstName: newUserFirstName,
            lastName: newUserLastName,
        }
        setNewUsers([...newUsers, newUser])
        setNewUserEmail('')
        setNewUserFirstName('')
        setNewUserLastName('')
        setNewUserId(newUserId - 1)
        setChanges(true)
        return true
    }

    const setPrincipal = (id: number, enable: boolean) => {
        let newManagers: typeof companyManagers = JSON.parse(JSON.stringify(companyManagers))
        for (let m of newManagers) {
            if (m.userId === id) {
                if (enable) {
                    m.principal = true
                }
                else {
                    m.principal = false
                }
                m.toBeSaved = true
            }
            else {
                if (m.principal) {
                    m.toBeSaved = true
                }
                m.principal = false
            }
        }
        setCompanyManagers(newManagers)
        setChanges(true)
    }

    const save = () => {
        let managersCopy: typeof companyManagers = JSON.parse(JSON.stringify(companyManagers))
        let newUser: NewUser | undefined = undefined
        for (let m of managersCopy) {
            newUser = newUsers.find(n => n.id === m.userId)
            if (m.toBeSaved) {
                if (newUser) {
                    dispatch(UpdateUserAsManagerAction(
                        {
                            companyId: company.id,
                            email: newUser.email,
                            firstName: newUser.firstName,
                            lastName: newUser.lastName,
                            role: m.role,
                            principal: m.principal,
                        }))

                } else {
                    dispatch(UpdateUserRoleAction({
                        companyId: company.id,
                        userId: m.userId,
                        role: m.role,
                        principal: m.principal
                    }))
                }
                m.toBeSaved = false
            }
            if (m.toBeDeleted) {
                if (!newUser) {
                    dispatch(DeleteCompanyUserAction({ companyId: company.id, userId: m.userId }))
                }
                // TODO: Update from state after company manager reload
                managersCopy = managersCopy.filter(ma => ma.userId !== m.userId)
            }
        }
        setNewUsers([])
        setCompanyManagers(managersCopy)
        setChanges(false)
    }

    const deleteUser = (managerId: number) => {
        let newManagers: typeof companyManagers = JSON.parse(JSON.stringify(companyManagers))
        let m = newManagers.find(mm => mm.userId === managerId)
        if (m) {
            if (m.toBeSaved != null && m.toBeSaved) {
                let managersCopy: typeof companyManagers = JSON.parse(JSON.stringify(companyManagers))
                setCompanyManagers(managersCopy.filter((manager) => manager.userId !== managerId))
                return
            }

            m.toBeDeleted = !m.toBeDeleted
        }
        setCompanyManagers(newManagers)
        let u = users!!.find(man => man.id === managerId)
        if (u) {
            setAllUsersDropDownList([...allUsersDropDownList, { value: u.id.toString(), displayText: u.firstName + ' ' + u.lastName }])
        }
        setChanges(true)
    }

    const changeRole = (id: number, newRole: Role) => {
        let managersCopy: typeof companyManagers = JSON.parse(JSON.stringify(companyManagers))
        let u = managersCopy.find(m => m.userId === id)
        if (u) {
            u.role = newRole
            u.toBeSaved = true
            setCompanyManagers(managersCopy)
            setChanges(true)
        }
    }

    const managerRole = (selectedValue: Role, onchange: (value: Role) => void) => {
        return <DropDown id={'managerRole'} options={[{ value: 'manager', displayText: t('roles.manager') }, { value: 'viewer', displayText: t('roles.viewer') }]} selectedValue={selectedValue} onChange={(e) => onchange(e.target.value)}></DropDown>
    }

    const getManagerRow = (manager: Manager, i: number) => {
        let user: User | NewUser
        user = users.find(u => u.id === manager.userId)!!
        if (!user && newUsers.length > 0) {
            user = newUsers.find(u => u.id === manager.userId)!!
        }
        let getColor = (manager: Manager): string => {
            if (manager.toBeDeleted) {
                return actionColors.removeColor
            }
            if (manager.toBeSaved) {
                return actionColors.addColor
            }
            return actionColors.neutralColor
        }
        if (user) {
            return (<ManagerRow
                key={i}
                manager={manager}
                firstName={user.firstName}
                lastName={user.lastName}
                color={getColor(manager)}
                delete={deleteUser}
                changeRole={changeRole}
                managerRole={managerRole}
                setPrincipal={setPrincipal}
                myRole={myRole as Role} />)
        }
    }

    return (<div style={{ width: '100%' }}>
        <CreateUserModal />
        <TableContainer component='div'>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('users.firstName')}</TableCell>
                        <TableCell>{t('users.lastName')}</TableCell>
                        <TableCell>{t('users.role')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companyManagers.map(getManagerRow)}
                    {isAllowedToAddUsers &&
                        <TableRow>
                            <TableCell colSpan={2}>
                                {isAdmin ?
                                    <DropDown width={'80%'} id={'allUsers'} options={allUsersDropDownList} selectedValue={selectedUserId} onChange={(e) => setSelectedUserId(e.target.value)}></DropDown>
                                    :
                                    <div style={{ display: (myRole === 'manager') ? 'block' : 'none' }}>
                                        <TextField variant='standard' sx={{ mr: 2 }} value={newUserEmail} onChange={e => setNewUserEmail(e.target.value)} label={t('users.email')} />
                                        <TextField variant='standard' sx={{ mr: 2 }} value={newUserFirstName} onChange={e => setNewUserFirstName(e.target.value)} label={t('users.firstName')} inputRef={newUserFirstNameField} />
                                        <TextField variant='standard' sx={{ mr: 2 }} value={newUserLastName} onChange={e => setNewUserLastName(e.target.value)} label={t('users.lastName')} inputRef={newUserLastNameField} />
                                        <div style={{ display: 'inline-block', marginTop: '14px' }}>
                                            {managerRole(role, setRole)}
                                        </div>
                                    </div>
                                }
                            </TableCell>
                            <TableCell>
                                {isAdmin ?
                                    managerRole(role, setRole)
                                    :
                                    null
                                }
                            </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={add}
                                    >
                                        {t('users.add')}
                                    </Button>
                                </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>


        {isAllowedToAddUsers &&
            <ButtonsDisplayer
                buttons={[
                    ...(isAdmin ? [<CreateUserButton />] : []),
                    <Button variant="contained" disabled={!(companyManagers && changes)} onClick={save}>
                        {t('generic.save')}
                    </Button>
                ]}
            />
        }
        {/*   <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: '20px' }}>
            <div style={{ display: (isAdmin || myRole === 'manager') ? 'flex' : 'none', justifyContent: 'space-around' }}>
                {isAdmin && <>
                    <CreateUserButton />
                    <div style={{ marginLeft: '20px' }} />
                </>}
                <Button variant="contained" disabled={!(companyManagers && changes)} onClick={save}>
                    {t('generic.save')}
                </Button>
            </div>
        </div> */}
    </div>
    )
}



export default Managers

import Sorter from "../../components/Sorter";
import { TableHead, TableRow, } from "../../components/Table"
import { Apartment } from "../../redux/apartments/apartments-types";
import { Report } from "../../redux/reports/reports-types";

type TableCellChilden = {
    text: string,
    key: string
}

type Props = {
    first: TableCellChilden,
    second: TableCellChilden,
    third: TableCellChilden,
    apartments: Apartment[],
    sortApartments: (items: Apartment[], sortby: string) => void
    reports: Report[],
    sortBy: string
    ascending: boolean

}

export function ReportTableHeadRows({ first, second, third, apartments, sortApartments, reports, sortBy, ascending }: Props): JSX.Element {

    return <TableHead>
        <TableRow>
            <Sorter
                sortKey={first.key}
                items={apartments}
                sortAction={sortApartments}
                sortBy={sortBy}
                title={first.text}
                sortData={(apartment: Apartment) => apartment.code}
                currentAscending={ascending}
            />
            <Sorter
                sortKey={second.key}
                items={apartments}
                sortAction={sortApartments}
                sortBy={sortBy}
                title={second.text}
                sortData={(apartment: Apartment) => {
                    let report = reports.find(r => r.apartmentId === apartment.id)
                    let n = 0
                    if (report) {
                        n = report[second.key as keyof typeof report] as unknown as number
                    }
                    return n
                }}
                currentAscending={ascending}
            />
            <Sorter
                sortKey={third.key}
                items={apartments}
                sortAction={sortApartments}
                sortBy={sortBy}
                title={third.text}
                sortData={(apartment: Apartment) => {
                    let report = reports.find(r => r.apartmentId === apartment.id)
                    let n = 0
                    if (report) {
                        n = report[third.key as keyof typeof report] as unknown as number
                    }
                    return n
                }}
                currentAscending={ascending}
            />
        </TableRow>
    </TableHead>
}
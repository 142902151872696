import { AnyAction } from "redux"
import {
    FetchReportsAction,
    FetchReportsFailureAction,
    FetchReportsSuccessAction,
    FetchCSVAction,
    FetchCSVFailureAction,
    FetchCSVSuccessAction,
    FetchInitReportSuccessAction,
    FetchInitReportFailureAction,
    FetchInitReportAction,
    SortWaterMeterApartmentsAction,
    SortEnergyMeterApartmentsAction,
} from "./reports-actions"
import { ReportsState } from "./reports-types"

const initialState: ReportsState = {
    reports: [],
    loading: false,
    filteredAndSortedWaterMeterApartments: [],
    filteredAndSortedEnergyMeterApartments: [],
    energyMeterApartmentsSortBy: 'code',
    waterMeterApartmentsSortBy: 'code',
    waterMeterApartmentsAscending: true,
    energyMeterApartmentsAscending: true
}

const loadingTrue = (state: ReportsState) => ({ ...state, loading: true })
const loadingFalse = (state: ReportsState) => ({ ...state, loading: false })

const actions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsAction.match(action)) {
        return loadingTrue(state)
    }

    if (FetchInitReportAction.match(action)) {
        return {
            ...state,
            loading: true
        }
    }

    if (FetchCSVAction.match(action)) {
        return loadingTrue(state)
    }

    return state
}

const successActions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsSuccessAction.match(action)) {
        return {
            ...state,
            reports: action.payload.reports,
            loading: false
        }
    }

    if (FetchInitReportSuccessAction.match(action)) {
        return {
            ...state,
            loading: false
        }
    }

    if (FetchCSVSuccessAction.match(action)) {
        return loadingFalse(state)
    }
    return state
}

const failureActions = (state: ReportsState, action: AnyAction) => {
    if (FetchReportsFailureAction.match(action)) {
        return loadingFalse(state)
    }

    if (FetchInitReportFailureAction.match(action)) {
        return {
            ...state,
            loading: false
        }
    }

    if (FetchCSVFailureAction.match(action)) {
        return loadingFalse(state)
    }
    return state
}

const otherActions = (state: ReportsState, action: AnyAction) => {
    if (SortWaterMeterApartmentsAction.match(action)) {
        return {
            ...state,
            waterMeterApartmentsSortBy: action.payload.waterMeterApartmentsSortBy,
            waterMeterApartmentsAscending: action.payload.waterMeterApartmentsAscending,
            filteredAndSortedWaterMeterApartments: action.payload.apartments
        }
    }

    if (SortEnergyMeterApartmentsAction.match(action)) {
        return {
            ...state,
            energyMeterApartmentsSortBy: action.payload.energyMeterApartmentsSortBy,
            energyMeterApartmentsAscending: action.payload.energyMeterApartmentsAscending,
            filteredAndSortedEnergyMeterApartments: action.payload.apartments
        }
    }
    return state
}


export default function reportsReducer(state: ReportsState = initialState, action: AnyAction): ReportsState {

    state = actions(state, action)
    state = successActions(state, action)
    state = failureActions(state, action)
    state = otherActions(state, action)

    return state
}



import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from 'react';

type Props = {
    value: Date | null,
    setValue: React.Dispatch<React.SetStateAction<Date | null>>
}

export function DatePickerComponent({ value, setValue }: Props): JSX.Element {

    return <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>

        <DatePicker
                views={['year', 'month', 'day']}
                value={value}
                inputFormat="dd-MM-yyyy"
                onChange={(date) => { setValue(date)}}
                renderInput={(props) =>
                    <TextField
                        {...props}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                        style={{
                            border: '1px solid #B5B9B9',
                            borderRadius: '5px',
                        }}
                        sx={{
                            "input": {
                                fontSize: '12px',
                                font: 'revert',
                                width: '100%',
                                height: 'auto',
                                padding: '10px',
                            },
                            "fieldset": {
                                border: '0'
                            }
                        }}
                    />
                }
            />
        </div>
    </LocalizationProvider>
}
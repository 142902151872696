import { Company, Role } from "../../redux/companies/companies-types";
import { useTranslation } from "react-i18next";
import SIMcardList from "./SIMcardList";
import { Client } from "../../redux/apartments/apartments-types";
import BasicCompanyInfo from "./BasicCompanyInfo";
import ExpandableBlock from "../../components/ExpandableBlock";
import Managers from "./Managers";
import Reporting from "./Reporting";
import Alarms from "./Alarms";
import ApiSettings from "./ApiSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";

type Props = {
  company: Company,
  clients: Client[],
  apiKey: string | null,
}

export default function CompanyInfo({ company, clients, apiKey }: Props) {
  const t = useTranslation().t
  const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
  const myRole = useSelector((state: RootState): Role => state.hydrolink.auth.roleByCompanyId.find(c => c.companyId === company.id)?.role ?? "viewer")
  return (
    <>
      <ExpandableBlock leftSide={t('companies.info.companyInfo')} grey>
        <BasicCompanyInfo company={company} />
        <ExpandableBlock leftSide={t('companies.managers')} >
          <Managers company={company} />
        </ExpandableBlock>
        <ExpandableBlock leftSide={t('companies.alarms')} >
          <Alarms companyId={company.id} />
        </ExpandableBlock>
        <ExpandableBlock leftSide={t('companies.info.reporting')} >
          <Reporting companyId={company.id} />
        </ExpandableBlock>
        <ExpandableBlock leftSide={t('companies.info.centerSimCardNumbers')} >
          <SIMcardList clients={clients} companyId={company.id} />
        </ExpandableBlock>
        {(isAdmin || myRole === "manager") &&
        <ExpandableBlock leftSide={t('companies.info.apiSettings')} >
          <ApiSettings apiKey={apiKey} companyId={company.id} />
        </ExpandableBlock>
        }
      </ExpandableBlock>
    </>)
}
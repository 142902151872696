import { TableContainer, Table, TableRow, TableBody, TableCell, InputLabel, TextField } from '@mui/material'
import DropDown from '../../components/DropDown';
import { Button } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';
import React, { useEffect, useState } from 'react';
import { AlarmSettings } from '../../redux/apartments/apartments-types';
import { SaveAlarmSettingsAction } from '../../redux/apartments/apartments-actions';
import { useButtonRights } from '../../utils/useButtonRights';

type Props = {
    companyId: number
}

export default function Alarms({ companyId }: Props) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const existingAlarmSettings = useSelector((state: RootState) => state.hydrolink.apartments.alarmSettings)
    const [alarmSettings, setAlarmSettings] = useState<AlarmSettings>(existingAlarmSettings)
    const [editing, setEditing] = useState(false)
    const saveAlarmButtonRights = useButtonRights(companyId, "manager", !editing)

    const languages = [
        { value: 'fi', displayText: t('country.finland') },
        { value: 'ee', displayText: t('country.estonia') },
        { value: 'se', displayText: t('country.sweden') }
    ]

    useEffect(() => {
        setAlarmSettings(existingAlarmSettings)
    }, [existingAlarmSettings])

    // Updates alarm setting in local state
    const updateLocalSetting = (field: keyof AlarmSettings, value: string) => {
        setEditing(true)
        setAlarmSettings({
            ...alarmSettings,
            [field]: value
        })
    }

    const saveAlarmSettings = () => {
        setEditing(false)
        dispatch(SaveAlarmSettingsAction({ alarmSettings: alarmSettings, companyId: companyId }))
    }

    const tableRow = (label: JSX.Element, input: JSX.Element) => <TableRow >
        <TableCell>
            {label}
        </TableCell>
        <TableCell>
            {input}
        </TableCell>
    </TableRow>


    return (<>
        <div style={{ width: "50%" }}>
            <TableContainer component='div' >
                <Table aria-label="simple table">
                    <TableBody >
                        {tableRow(
                            <InputLabel htmlFor="email">{t('apartments.report.emailForAlarms')} </InputLabel>,
                            <TextField
                                id="email"
                                size="small"
                                value={alarmSettings.stuckEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("stuckEmail", e.target.value)}
                            />
                        )}
                        {tableRow(
                            <InputLabel htmlFor="alarmLanguage">{t('apartments.report.alarmLanguage')}  </InputLabel>,
                            <DropDown
                                id={'reportLanguage'}
                                options={languages}
                                selectedValue={alarmSettings.alarmLang}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateLocalSetting("alarmLang", e.target.value)}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        <Button
            onClick={saveAlarmSettings}
            disabled={saveAlarmButtonRights}
        >
            {t('generic.save')}
        </Button>
    </>

    )

}
import { Apartment, Client, Meter, MeterType, PremiumSettings, ReportSettings } from "../redux/apartments/apartments-types";
import { ClientCommand } from "../redux/centers/centers-types";
import { Company, Manager, Role } from "../redux/companies/companies-types";
import { Note } from "../redux/notes/notes-types";
import { User } from "../redux/users/users-types";
import {
    ApiApartment,
    ApiClient,
    ApiClientCommand,
    ApiCompany,
    ApiManager,
    ApiManagerRole,
    ApiMeter,
    ApiMeterType,
    ApiNote,
    ApiPremiumSettings,
    ApiReportSettings,
    ApiUser
} from "./api-types";


export function convertApiCompany(apiCompany: ApiCompany): Company {
    return {
        id: apiCompany.id,
        name: apiCompany.name ?? "",
        premium: apiCompany.premium,
        streetAddress: apiCompany.streetAddress ?? "",
        town: apiCompany.town ?? "",
        managers: apiCompany.managers.map(convertApiManager),
        alarmStatus: apiCompany.alarmStatus,
        zipCode: apiCompany.zipCode ?? "",
        apiAccessEnabled: apiCompany.apiAccessEnabled,
        reportFormat: apiCompany.reportFormat
    }
}

export function convertApiManager(apiManager: ApiManager): Manager {
    return {
        userId: apiManager.userId,
        role: convertApiManagerRole(apiManager.role),
        principal: apiManager.principal
    }
}

export function convertApiManagerRole(apiManagerRole: ApiManagerRole): Role {
    switch (apiManagerRole) {
        case "admin":
            return "admin"

        case "manager":
            return "manager"

        case "viewer":
            return "viewer"

        default:
            return "viewer"
        // throw new Error(`${apiManagerRole} does not match Role (unknown type)`)
    }
}

export function convertApiApartment(apiApartment: ApiApartment): Apartment {
    return {
        id: apiApartment.id,
        code: apiApartment.code,
        client: convertApiClient(apiApartment.client),
        meters: apiApartment.meters.map(convertApiMeter)
    }
}

export function convertApiClient(apiClient: ApiClient): Client {
    return {
        id: apiClient.id,
        code: apiClient.code,
        softwareVersion: apiClient.softwareVersion ?? null,
        telephoneNumber: apiClient.telephoneNumber ?? null,
        commandQueue: apiClient.commandQueue,
        supportedCommands: apiClient.supportedCommands
    }
}

export function convertApiMeter(apiMeter: ApiMeter): Meter {
    return {
        id: apiMeter.id,
        type: convertApiMeterType(apiMeter.type),
        warm: apiMeter.warm ?? false,
        heating: apiMeter.heating ?? false,
        code: apiMeter.code,
        reading: apiMeter.reading ?? 0,
        updated: apiMeter.updated ? parseInt(apiMeter.updated) : 0,
        meterResetAt: apiMeter.meterResetAt ? new Date(apiMeter.meterResetAt) : null,
        activeAlarmIds: apiMeter.activeAlarmIds,
        supportsHydrodigitLRAlarmReset: apiMeter.supportsHydrodigitLRAlarmReset
    }
}

export function convertApiMeterType(apiMeterType: ApiMeterType): MeterType {
    switch (apiMeterType) {
        case "energy":
            return "energy"

        case "leakage":
            return "leakage"

        case "temperature":
            return "temperature"

        case "water":
            return "water"

        default:
            throw new Error(`${apiMeterType} does not match MeterType (unknown type)`)
    }
}

export function convertApiPremiumSettings(apiPremiumSettings: ApiPremiumSettings): PremiumSettings {
    return {
        phoneNumbers: apiPremiumSettings.phoneNumbers ?? "",
        warmWaterPrice: apiPremiumSettings.warmWaterPrice ?? "",
        coldWaterPrice: apiPremiumSettings.coldWaterPrice ?? ""
    }
}

export function convertApiReportSettings(apiReportSettings: ApiReportSettings): ReportSettings {
    return {
        reportingId: apiReportSettings.reportingId,
        sendReports: apiReportSettings.sendReports,
        reportEmail: apiReportSettings.reportEmail,
        reportFormat: apiReportSettings.reportFormat,
        reportIntervalMonths: apiReportSettings.reportIntervalMonths ?? 0,
        reportLang: apiReportSettings.reportLang,
        reportLastSent: apiReportSettings.reportLastSent
    }
}

export function convertApiClientCommand(apiClientCommand: ApiClientCommand): ClientCommand {
    return {
        tag: apiClientCommand.tag,
        nameByLang: apiClientCommand.nameByLang,
        requiresAdmin: apiClientCommand.requiresAdmin
    }
}

export function convertApiNote(apiNote: ApiNote): Note {
    return {
        id: apiNote.id,
        created: new Date(apiNote.created),
        comment: apiNote.comment,
        creator: apiNote.creator
    }
}

export function convertApiUser(apiUser: ApiUser): User {
    return {
        id: apiUser.id,
        email: apiUser.email,
        firstName: apiUser.firstName,
        lastName: apiUser.lastName,
        username: apiUser.username,
        phone: apiUser.phone ?? "",
        admin: apiUser.admin
    }
}

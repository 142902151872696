import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "../../components/Table"
import { Paper } from "@mui/material"
import { DatePickerComponent } from "../../components/DatePicker"
import { useTranslation } from "react-i18next"

type Props = {
    from: Date | null
    until: Date | null
    setFrom: React.Dispatch<React.SetStateAction<Date | null>>,
    setUntil: React.Dispatch<React.SetStateAction<Date | null>>
}

export function DateChoosingTable({ from, until, setFrom, setUntil }: Props): JSX.Element {
    const t = useTranslation().t

    return <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{t("consumptionReporting.determinePeriod")}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{t("consumptionReporting.from")}</TableCell>
                    <TableCell>{t("consumptionReporting.until")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <DatePickerComponent value={from} setValue={setFrom} />
                    </TableCell>
                    <TableCell>
                        <DatePickerComponent value={until} setValue={setUntil} />
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
}
import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { elementWithHeader } from "../../components/common/header/Header";
import { Typography } from "@mui/material";
import { Button } from "../../components/Button";
import { ModalInput } from "../../components/common/modal/ModalInput";
import { useDispatch } from "react-redux";
import { SendPasswordResetAction } from "../../redux/auth/auth-actions";
import Centered from "../../components/Centered";

type Props = {
    token?: string
}

type Passwords = {
    password: string,
    passwordConfirmation: string
}

function PasswordResetView({ token }: Props): JSX.Element {
    const t = useTranslation().t
    const dispatch = useDispatch()
    const [passwords, setPasswords] = React.useState<Passwords>({ password: '', passwordConfirmation: '' })

    const canSave = (): boolean => {
        return passwords.password.length > 4 // Min. length
            && passwords.password === passwords.passwordConfirmation
    }

    const submit = () => {
        dispatch(SendPasswordResetAction({ password: passwords.password, token: token!! }))
    }

    // Token missing from URL
    if (!token) {
        return <Centered style={{ marginTop: '8em' }}>
            <Typography>
                {t('passwordReset.invalidToken')}
            </Typography>
            <Link
                to={'/login'}
                style={{ marginTop: '1em' }}
            >
                <Button>
                    {t('passwordReset.toLoginPage')}
                </Button>
            </Link>
        </Centered>
    }

    const createPasswordInput = (field: keyof Passwords) => {
        return <ModalInput
            title={t(`passwordReset.${field}`)}
            type={"password"}
            style={{
                width: '20em',
                maxWidth: '50%'
            }}
            value={passwords[field]}
            handleFormValue={(e) => setPasswords({ ...passwords, [field]: e.target.value })}
        />
    }

    return <Centered style={{ marginTop: '8em' }}>
        {createPasswordInput("password")}
        {createPasswordInput("passwordConfirmation")}
        <Button
            disabled={!canSave()}
            onClick={submit}
            style={{ marginTop: '3em' }}
        >
            {t('passwordReset.save')}
        </Button>
    </Centered>
}

export default function PasswordReset() {
    const token = useParams().token

    return elementWithHeader(<PasswordResetView token={token} />, false)
}
import { createAction } from '@reduxjs/toolkit'
import {
    SendClientCommandPayload,
    FetchClientCommandsSuccessPayload
} from './centers-types'

export const FetchClientCommandsAction = createAction<void>('FETCH_CLIENT_COMMANDS')
export const FetchClientCommandsSuccessAction = createAction<FetchClientCommandsSuccessPayload>('FETCH_CLIENT_COMMANDS_SUCCESS')
export const FetchClientCommandsFailureAction = createAction<void>('FETCH_CLIENT_COMMANDS_FAILURE')

export const SendClientCommandAction = createAction<SendClientCommandPayload>('SEND_CLIENT_COMMAND')
export const SendClientCommandSuccessAction = createAction<void>('SEND_CLIENT_COMMAND_SUCCESS')
export const SendClientCommandFailureAction = createAction<void>('SEND_CLIENT_COMMAND_FAILURE')

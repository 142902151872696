import { User } from '../../redux/users/users-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUserAdminAction } from '../../redux/users/users-actions';
import { useTranslation } from 'react-i18next';
import { Button } from "../../components/Button"

type Props = {
    user: User
}

function AdminRightsButton({ user }: Props): JSX.Element {

    const dispatch = useDispatch()
    const t = useTranslation().t
    const [admin, setAdmin] = React.useState<boolean>(user.admin)

    const change = () => {
        dispatch(UpdateUserAdminAction({ userId: user.id, admin: !admin }))
        setAdmin(!admin)
    }
    return (
        <Button
            onClick={change}
        >{admin ? t('users.removeAdminRights') : t('users.grantAdminRights')}
        </Button>
    )
}

export default AdminRightsButton
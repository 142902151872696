import { useDispatch } from "react-redux"
import { useTranslation } from 'react-i18next'
import { useFilePicker } from 'use-file-picker'
import { UploadCSVAction } from '../../redux/apartments/apartments-actions'
import { Button } from "../../components/Button"


export default function UploadCSV({ companyId }: { companyId: number }): JSX.Element {
    const dispatch = useDispatch()
    const t = useTranslation().t
    const [openFileSelector, { filesContent, loading, clear }] = useFilePicker({
        accept: [".csv", ".CSV"],
        multiple: false
    })

    const canUpload = () => !loading && filesContent.length > 0

    const handleUpload = () => {
        clear() // Clear files from file picker
        dispatch(UploadCSVAction({ companyId: companyId, file: filesContent[0].content }))
    }

    return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
            onClick={openFileSelector}
            variant='outlined'
        >
            {t('csv.selectFile')}
        </Button>
        {loading && <span>{t('csv.loading')}</span>}
        {canUpload() && <span>{filesContent[0].name}</span>}
        <Button
            variant='outlined'
            onClick={handleUpload}
            disabled={!canUpload()}
        >
            {t('generic.save')}
        </Button>
    </div>
}
import { Theme } from '@mui/material'
import '../../assets/App.css'
import '../../assets/Mobile.css'
import '../../assets/index.css'
import { Provider } from 'react-redux'
import ErrorToast from '../common/error-toast/ErrorToast'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { HydrolinkStore } from '../../configureStore'
import Toasts from '../common/toast/Toasts'
import Authorized from '../common/authorized/Authorized'
import Login from '../../pages/login'
import Users from '../../pages/users'
import Landing from '../../pages/landing'
import Companies from '../../pages/companies'
import Apartments from '../../pages/apartments'
import { ThemeProvider } from 'styled-components'
import CompanyTabs, { Tabs } from '../../pages/companies/CompanyTabs'
import { ViewContainer } from '../ViewContainer'
import { elementWithHeader } from '../common/header/Header'
import Centers from '../../pages/centers'
import Residents from '../../pages/residents'
import ResidentsManagement from '../../pages/residents/ResidentsManagement'
import Spinner from '../Spinner'
import Notes from '../../pages/notes'
import PasswordReset from '../../pages/password-reset'
import ConsumptionReport from '../../pages/reports'
import InitReport from '../../pages/initreport'

export default function Application({ store, theme }: { store: HydrolinkStore, theme: Theme }): JSX.Element {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorToast />
        <Toasts />
        <BrowserRouter>
          <Spinner />
          <Routes>
            <Route path='/login' element={<Authorized props={{ requireIsAuthenticated: false, requireIsAdmin: false }} child={<Login />} />} />
            <Route path='/password-reset/' element={<Authorized props={{ requireIsAuthenticated: false, requireIsAdmin: false }} child={<PasswordReset />} />} />
            <Route path='/password-reset/:token' element={<Authorized props={{ requireIsAuthenticated: false, requireIsAdmin: false }} child={<PasswordReset />} />} />
            <Route path='/' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Landing />} />} />
            <Route path='/companies' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Companies />} />} />
            <Route path='/companies/:companyId/apartments' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Apartments />} />} />
            <Route path='/companies/:companyId/reports' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={elementWithHeader(<ViewContainer><CompanyTabs tab={Tabs.REPORTS} /><ConsumptionReport /></ViewContainer>)} />} />
            <Route path='/companies/:companyId/initialization-report' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={elementWithHeader(<ViewContainer><CompanyTabs tab={Tabs.INITIALIZATIONREPORT} /><InitReport /></ViewContainer>)} />} />
            <Route path='/companies/:companyId/centers' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={elementWithHeader(<ViewContainer><CompanyTabs tab={Tabs.CENTERS} /><Centers /></ViewContainer>)} />} />
            <Route path='/companies/:companyId/notes' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={elementWithHeader(<ViewContainer><CompanyTabs tab={Tabs.NOTES} /><Notes /></ViewContainer>)} />} />
            <Route path='/residents' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Residents />} />} />
            <Route path='/companies/:companyId/residents' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false, requireIsPremium: true }} child={<ResidentsManagement />} />} />
            <Route path='/guides' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Companies />} />} />
            <Route path='/users' element={<Authorized props={{ requireIsAuthenticated: true, requireIsAdmin: false }} child={<Users />} />} />

            <Route path="*" element={<Navigate replace to='/' />} /> {/* 404: Redirect to home */}
          </Routes>
        </BrowserRouter>

      </ThemeProvider>

    </Provider>
  )
}

import { ReactElement, useEffect } from 'react';
import { Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { elementWithHeader } from '../../components/common/header/Header';
import { RootState } from '../../interfaces/RootState';
import { useTranslation } from 'react-i18next';
import { FetchCompaniesAction } from '../../redux/companies/companies-actions';
import { ViewContainer } from "../../components/ViewContainer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { Company } from '../../redux/companies/companies-types';
import { Link } from 'react-router-dom';
import { alignments } from '../../theme';

function CompaniesWithResidentsComponent(): ReactElement {
    const dispatch = useDispatch()
    const t = useTranslation().t
    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies)

    useEffect(() => {
        dispatch(FetchCompaniesAction())
    }, [])

    const companyTableHead = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>{t('residents.service')}</TableCell>
                    <TableCell>{t('residents.name')}</TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const companyTableRow = (company: Company, i: number) => {
        return (
            <TableRow key={company.id}>
                <TableCell>
                    {company.premium ?
                        t('residents.premium') : t('residents.basic')}
                </TableCell>
                <TableCell> {company.premium ?
                    <Link to={`/companies/${company.id}/residents`}>
                        {company.name}
                    </Link> : company.name}
                </TableCell>
            </TableRow>)
    }

    return <ViewContainer>
        <h2>{t('residents.residentsManagement')}</h2>
        <div style={alignments.rightSide}>
            <TableContainer component={Paper}>
                <Table>
                    {companyTableHead()}
                    <TableBody>
                        {[...companies].map((c, i) => {
                            return (companyTableRow(c, i))
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    </ViewContainer>
}

export default function CompaniesWithResidents() {

    return elementWithHeader(<CompaniesWithResidentsComponent />)
}




import React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcons from "../../components/ExpandIcons";
import { TableCell, TableRow } from "../../components/Table";
import { Company } from "../../redux/companies/companies-types";
import { getHighestRoleForUser } from "../../redux/users/users-reducer";
import { User } from "../../redux/users/users-types";
import UsersInfo from "./UserInfo";
import { borders } from "../../theme";

type Props = {
    user: User,
    companies: Company[]
}

export default function UsersRow({ user, companies }: Props): JSX.Element {
    const t = useTranslation().t
    const [expanded, setExpanded] = React.useState<boolean>(false)

    // A bit 'hacky' but works flawlessly!
    const getHighestRoleTranslation = (): string => {
        let translated = t(`roles.${getHighestRoleForUser(companies, user)}`)
        return translated.includes("roles.") ? "" : translated
    }

    return <>
        <TableRow key={user.id} onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
            <TableCell>{user.firstName} {user.lastName}</TableCell>
            <TableCell>{user.username}</TableCell>
            <TableCell>{companies.map((c: Company) => <Chip key={c.id} label={c.name} />)}</TableCell>
            <TableCell>{getHighestRoleTranslation()}</TableCell>
            <TableCell>
                <ExpandIcons closed={!expanded} />
            </TableCell>
        </TableRow>
        {expanded && <TableRow key={`exp_${user.id}`}>
            <TableCell colSpan={5}>
                <div style={borders.basicOutline}>
                    <UsersInfo companies={companies} user={user} />
                </div>
            </TableCell>
        </TableRow>}
    </>
}
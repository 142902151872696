import { TableContainer, Table, TableBody } from "../../components/Table"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchApartmentsAction } from "../../redux/apartments/apartments-actions";
import { RootState } from "../../interfaces/RootState";
import { useParams } from "react-router-dom"
import { SortWaterMeterApartmentsAction, SortEnergyMeterApartmentsAction } from "../../redux/reports/reports-actions";
import { ReportTableHeadRows } from "./ReportTableHeadRows";
import { ReportTableBodyRows } from "./ReportTableBodyRows";
import { Apartment } from "../../redux/apartments/apartments-types";
import { TopBlock } from "./TopBlock";
import { Dispatch } from "redux";


const reportTables = (head: JSX.Element, body: JSX.Element[]) => <>
    <div style={{ marginTop: '50px' }}>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                {head}
                <TableBody>
                    {body}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
</>

const sortWaterMeterFunction = (dispatch: Dispatch) => (a: Apartment[], sortBy: string, ascending: boolean = true) => {
    dispatch(SortWaterMeterApartmentsAction({ apartments: a, waterMeterApartmentsSortBy: sortBy, waterMeterApartmentsAscending: ascending }))
}

const sortEnergyMeterFunction = (dispatch: Dispatch) => (a: Apartment[], sortBy: string, ascending: boolean = true) => {
    dispatch(SortEnergyMeterApartmentsAction({ apartments: a, energyMeterApartmentsSortBy: sortBy, energyMeterApartmentsAscending: ascending }))
}


const ConsumptionReport = (): JSX.Element => {

    const t = useTranslation().t
    const dispatch = useDispatch()
    const params = useParams()
    const companyId = parseInt(params.companyId ?? "0")
    const reports = useSelector((state: RootState) => state.hydrolink.reports.reports)
    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments)
    const waterMeterApartments = useSelector((state: RootState) => state.hydrolink.reports.filteredAndSortedWaterMeterApartments)
    const energyMeterApartments = useSelector((state: RootState) => state.hydrolink.reports.filteredAndSortedEnergyMeterApartments)
    const currentWaterSortBy = useSelector((state: RootState) => state.hydrolink.reports.waterMeterApartmentsSortBy)
    const currentEnergySortBy = useSelector((state: RootState) => state.hydrolink.reports.energyMeterApartmentsSortBy)
    const currentWaterAscending = useSelector((state: RootState) => state.hydrolink.reports.waterMeterApartmentsAscending)
    const currentEnergyAscending = useSelector((state: RootState) => state.hydrolink.reports.energyMeterApartmentsAscending)

    useEffect(() => {
        dispatch(FetchApartmentsAction({ companyId: companyId }))
    }, [companyId])

    useEffect(() => {
        sortWaterMeterApartments(apartments, currentWaterSortBy)
        sortEnergyMeterApartments(apartments, currentEnergySortBy)
    }, [apartments])


    const sortWaterMeterApartments = (apartmentsToSort: Apartment[], sortBy: string, ascending: boolean = true) => sortWaterMeterFunction(dispatch)(apartmentsToSort, sortBy, ascending)

    const sortEnergyMeterApartments = (apartmentsToSort: Apartment[], sortBy: string, ascending: boolean = true) => sortEnergyMeterFunction(dispatch)(apartmentsToSort, sortBy, ascending)

    return <>
        <TopBlock
            companyId={companyId}
            apartments={apartments}
        />
        <>
            {reportTables(
                <ReportTableHeadRows
                    first={{
                        text: t("consumptionReporting.apartment"),
                        key: "code",
                    }}
                    second={{
                        text: `${t("consumptionReporting.cold")} (${t("consumptionReporting.inCubics")})`,
                        key: 'coldWaterConsumptionM3',
                    }}
                    third={{
                        text: `${t("consumptionReporting.warm")} (${t("consumptionReporting.inCubics")})`,
                        key: 'warmWaterConsumptionM3',
                    }}
                    apartments={waterMeterApartments}
                    sortApartments={sortWaterMeterApartments}
                    reports={reports}
                    sortBy={currentWaterSortBy}
                    ascending={currentWaterAscending}
                />,
                waterMeterApartments.map(a => {
                    const report = reports.find(r => r.apartmentId == a.id);
                    return <ReportTableBodyRows key={a.id} id={a.id}
                        title={a.code}
                        firstValue={report?.coldWaterConsumptionM3}
                        secondValue={report?.warmWaterConsumptionM3}
                    />
                }))}
            {reportTables(
                <ReportTableHeadRows
                    first={{
                        text: `${t("consumptionReporting.apartment")} (${t("consumptionReporting.meter")})`,
                        key: "code",
                    }}
                    second={{
                        text: t("consumptionReporting.heating"),
                        key: 'heatingEnergyConsumptionKwh',

                    }
                    }
                    third={{
                        text: t("consumptionReporting.cooling"),
                        key: 'coolingEnergyConsumptionKwh',
                    }}
                    apartments={energyMeterApartments}
                    sortApartments={sortEnergyMeterApartments}
                    reports={reports}
                    sortBy={currentEnergySortBy}
                    ascending={currentEnergyAscending}
                />,
                energyMeterApartments.map(a => {
                    const report = reports.find(r => r.apartmentId == a.id);
                    return <ReportTableBodyRows
                        key={a.id}
                        id={a.id}
                        title={a.code}
                        firstValue={report?.heatingEnergyConsumptionKwh}
                        secondValue={report?.coolingEnergyConsumptionKwh}
                    />
                }))}
        </>
    </>
}

export default ConsumptionReport
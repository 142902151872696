import { colors, spacings } from "../theme";


export default function SeparatorLine() {
    return <hr
        style={{
            marginTop: spacings.inlineSpacing,
            border: `1px solid ${colors.neutral04}`
        }}
    />
}
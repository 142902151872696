import { Meter } from '../../../redux/apartments/apartments-types';
import MetersTableHead from './MetersTableHead';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableContainer, } from '../../../components/Table';

import { Paper } from '@mui/material';

import MeterRow from './MeterRow';

const MeterList = (props: { meters: Meter[], companyId: number, apartmentId: number }) => {

  const { t } = useTranslation()
  const alreadyDisplayedChartIcons = { water: false, temperature: false, energy: false }

  const getCurrentChartIconStateByMeterType = (meterType: string) => {
    return alreadyDisplayedChartIcons[meterType as keyof typeof alreadyDisplayedChartIcons]
  }

  const thisMeterTypeWillDisplayChartIcon = (meterType: string) => {
    alreadyDisplayedChartIcons[meterType as keyof typeof alreadyDisplayedChartIcons] = true
  }

  const meterTableRow = (meter: Meter, meterType: string) => {
    let c = getCurrentChartIconStateByMeterType(meter.type)
    thisMeterTypeWillDisplayChartIcon(meter.type)
    return <MeterRow meter={meter} key={meter.code} translatedMeterType={meterType} apartmentId={props.apartmentId} companyId={props.companyId} displayChartSymbol={c} />
  }

  const waterMeterTableRow = (meter: Meter) => {
    return meterTableRow(meter, meter.warm === true ? t('meters.warmWater') : t('meters.coldWater'))
  }

  const temperatureMeterTableRow = (meter: Meter) => {
    return meterTableRow(meter, t('meters.temperature'))
  }

  const leakageMeterTableRow = (meter: Meter) => {
    return meterTableRow(meter, t('meters.leakage'))
  }

  const energyMeterTableRow = (meter: Meter) => {
    return meterTableRow(meter, t('meters.energy'))
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <MetersTableHead />
        <TableBody>
          {props.meters.map((meter) => {
            switch (meter.type) {
              case 'water':
                return (waterMeterTableRow(meter))
              case 'temperature':
                return (temperatureMeterTableRow(meter))
              case 'energy':
                return (energyMeterTableRow(meter))
              case 'leakage':
                return (leakageMeterTableRow(meter))
              default:
                return null
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}


export default MeterList
import { createAction } from "@reduxjs/toolkit"
import {
    FetchReportsPayload,
    FetchReportsSuccessPayLoad,
    FetchCSVPayload,
    SortWaterMeterApartmentsPayload,
    SortEnergyMeterApartmentsPayload,
    FetchInitReportPayload
} from "./reports-types"

export const FetchReportsAction = createAction<FetchReportsPayload>('FETCH_CONSUMPTION_REPORTS')
export const FetchReportsSuccessAction = createAction<FetchReportsSuccessPayLoad>('FETCH_CONSUMPTION_REPORTS_SUCCESS')
export const FetchReportsFailureAction = createAction<void>('FETCH_CONSUMPTION_REPORTS_SUCCESS')

export const FetchInitReportAction = createAction<FetchInitReportPayload>('FETCH_INIT_REPORT')
export const FetchInitReportSuccessAction = createAction<void>('FETCH_INIT_REPORT_SUCCESS')
export const FetchInitReportFailureAction = createAction<void>('FETCH_INIT_REPORT_FAILURE')

export const FetchCSVAction = createAction<FetchCSVPayload>('FETCH_CSV')
export const FetchCSVSuccessAction = createAction<void>('FETCH_CSV_SUCCESS')
export const FetchCSVFailureAction = createAction<void>('FETCH_CSV_SUCCESS')

export const SortWaterMeterApartmentsAction = createAction<SortWaterMeterApartmentsPayload>('FILTER_OR_SORT_WATER_APARTMENTS')
export const SortEnergyMeterApartmentsAction = createAction<SortEnergyMeterApartmentsPayload>('FILTER_OR_SORT_ENERGY_APARTMENTS')

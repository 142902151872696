import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import createRootReducer from './redux/rootReducer'
import rootSaga from './redux/root-saga'
import developmentTrue from './constants/developmentTrue'


export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()

const middlewares = [routerMiddleware(history),
  thunkMiddleware,
  sagaMiddleware]

if (developmentTrue) {
  middlewares.push(logger);
}

const configureStore = () => {
  const composeEnhancer = compose
  const store = createStore(
    createRootReducer(history),
    composeEnhancer(
      applyMiddleware(...middlewares)
    )
  )
  sagaMiddleware.run(rootSaga)
  return store
}

const store = configureStore()

export default function getStore() {
  return store
}

export type HydrolinkStore = typeof store

export type HydrolinkDispatch = typeof store.dispatch

import React from "react";
import { faWifi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableCell, TableRow } from "../../components/Table";
import { Center } from "../../redux/centers/centers-types";
import ExpandIcons from "../../components/ExpandIcons";
import { borders } from "../../theme";
import CentersInfo from "./CentersInfo";

type Props = {
    center: Center
}

export function CentersRow({ center }: Props): JSX.Element {
    const [expanded, setExpanded] = React.useState<boolean>(false)

    return <>
        <TableRow key={center.id} onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
            <TableCell>
                {center.code}
                <span style={{ paddingLeft: '1em' }}>
                    {center.wireless ? <FontAwesomeIcon icon={faWifi} size='1x' /> : null}
                </span>
            </TableCell>
            <TableCell style={{ width: '1.8em' }}>
                <ExpandIcons
                    closed={!expanded} />
            </TableCell>
        </TableRow>
        {expanded && <TableRow key={`exp_${center.id}`}>
            <TableCell colSpan={2}>
                <div style={borders.basicOutline}>
                    <CentersInfo center={center} />
                </div>
            </TableCell>
        </TableRow>}
    </>
}
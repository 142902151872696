import { useEffect, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import '../../assets/App.css'
import '../../assets/Mobile.css'
import '../../assets/index.css'
import { REDIRECT_URL } from '../../constants/urls'
import Auth0Lock from 'auth0-lock'
import { useDispatch, useSelector } from "react-redux"
import { AUTH0_CLIENT_ID, LOGIN_DOMAIN } from "../../constants/auth";
import i18n from '../../i18n'
import { RootState } from '../../interfaces/RootState'
import { FetchUserInformationAction, LockSuccessAction } from '../../redux/auth/auth-actions'
import { persistToken } from '../../utils/persistence-utils'
import { Button } from '../../components/Button'
import log from '../../utils/logUtils'
import { ReportErrorAction } from '../../redux/error/error-actions'
import { useTranslation } from 'react-i18next'
import { lastLoginErrorKey } from '../../constants/lastLoginErrorKey'

interface AuthResult {
  idToken: string
}

interface Auth0LockInstance {
  show: () => void
  hide: () => void
}

export function useAuth0Lock(locale: string, onAuthenticated: (authResult: AuthResult) => void, onAuthorizationError: (err: string) => void): null | Auth0LockInstance {
  const callbacks = useRef({ onAuthenticated, onAuthorizationError })
  const [lock, setLock] = useState<Auth0LockInstance | null>(null)
  const previousLoginError = localStorage.getItem(lastLoginErrorKey)
  const dispatch = useDispatch()
  const t = useTranslation().t

  useEffect(() => {
    const lock = new Auth0Lock(AUTH0_CLIENT_ID ?? '', LOGIN_DOMAIN ?? '', {
      language: locale,
      theme: {
        primaryColor: '#2C7BE5'
      },
      languageDictionary: {
        title: '',
        signUpTitle: '',
      },
      auth: {
        redirectUrl: REDIRECT_URL,
        responseType: 'token',
        params: {
          scope: 'openid email app_metadata'
        }
      }
    })

    lock.on("authenticated", (authResult: AuthResult) => {
      callbacks.current.onAuthenticated(authResult)
    })

    lock.on("authorization_error", (err: string) => {
      callbacks.current.onAuthorizationError(err)
    })

    setLock(lock)
  }, [locale])

  useEffect(() => {
    const err = previousLoginError?.replace('"', '').replace('"', '')
    if (previousLoginError) {
      dispatch(ReportErrorAction(t(`errors.${err}`)))
      localStorage.removeItem(lastLoginErrorKey)
    }
  }, [])

  useEffect(() => {
    callbacks.current = {
      onAuthenticated,
      onAuthorizationError
    }
  }, [onAuthenticated, onAuthorizationError])

  return lock
}

export function Login({ isAuthenticated, lock }: { isAuthenticated: boolean, lock: Auth0LockInstance | null }): JSX.Element {

  function onLoginClicked() {
    lock!.show();
  }

  return (
    <div className="Aligner">
      <div className="Aligner-item Aligner-item--top"></div>
      <div className="Aligner-item">
        <div className="App">
          <div className="App-header">
          </div>
          <div className="App-intro">
            <Button variant="contained" onClick={onLoginClicked} data-testid="login_btn">
              <Typography color="inherit">{i18n.t('login')}</Typography>
            </Button>
            <br /><br />
          </div>
        </div>
      </div>
      <div className="Aligner-item Aligner-item--bottom"></div>
    </div>
  )
}

export default function ConnectedLogin(): JSX.Element {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state: RootState): boolean => state.hydrolink.auth.isAuthenticated)
  const lock = useAuth0Lock(i18n.language, onAuthenticated, onAuthorizationError)

  function onAuthenticated(authResult: AuthResult): void {
    persistToken(authResult.idToken)
    dispatch(LockSuccessAction({ token: authResult.idToken }))
    dispatch(FetchUserInformationAction())

    setTimeout(() => {
      lock!.hide()
    }, 2000)
  }

  function onAuthorizationError(err: string): void {
    log.debug('Failed login')
  }

  return <Login isAuthenticated={isAuthenticated} lock={lock} />
}


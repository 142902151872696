import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TableContainer, } from '../../components/Table';
import { useDispatch } from 'react-redux';
import { InsertNoteAction } from '../../redux/notes/notes-actions';
import React from 'react';
import { Button } from '../../components/Button';
import { StyledFieldsText } from '../../components/common/modal/Modal.style';
import { spacings } from '../../theme';


const AddNote = ({ companyId, }: { companyId: number }) => {

    const t = useTranslation().t
    const dispatch = useDispatch()

    const [note, setNote] = React.useState<string>('')

    const add = () => {
        dispatch(InsertNoteAction({ companyId: companyId, comment: note }));
        setNote('')
    }

    return <div style={{ marginTop: '40px' }}>
        <TableContainer component={Paper}>
            <div style={{ padding: spacings.stardardSpacing, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                <div>
                    <StyledFieldsText>
                        {t("notes.addNote")}
                    </StyledFieldsText>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <textarea
                            value={note}
                            rows={10}
                            cols={130}
                            onChange={e => setNote(e.target.value)}
                        />
                    </div>
                </div>
                <Button onClick={add}>
                    {t('notes.add')}
                </Button>
            </div>

        </TableContainer>
    </div>


}

export default AddNote
import { Navigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../interfaces/RootState'
import { ReportErrorAction } from '../../../redux/error/error-actions'
import { useEffect } from 'react'
import { FetchCompaniesAction } from '../../../redux/companies/companies-actions'
import { useTranslation } from 'react-i18next'

interface AuthorizedProps {
  isAuthenticated: boolean,
  isAdmin: boolean,
  requireIsAuthenticated: boolean,
  requireIsAdmin: boolean,
  requireIsPremium?: boolean,
  companyId: number,
}

interface ConnectedAuthorizedProps {
  requireIsAuthenticated: boolean,
  requireIsAdmin: boolean,
  requireIsPremium?: boolean
}

export function Authorized({ props, child }: { props: AuthorizedProps, child: JSX.Element }): JSX.Element {
  const dispatch = useDispatch()
  const company = useSelector((state: RootState) => state.hydrolink.companies?.companies.find(c => c.id === props.companyId))
  const t = useTranslation().t

  useEffect(() => {
    if (!company && props.isAuthenticated && props.requireIsPremium) {
      dispatch(FetchCompaniesAction());
    }
  }, [])

  if (props.requireIsAuthenticated && !props.isAuthenticated) return <Navigate replace to='/login' />
  if (!props.requireIsAuthenticated && props.isAuthenticated) return <Navigate replace to='/companies' />
  if (props.requireIsAdmin && !props.isAdmin) {
    dispatch(ReportErrorAction(t("errors.unauthorized")))
    return <Navigate replace to='/login' />
  }

  if (!company && props.requireIsPremium) return <></>

  if (props.requireIsPremium && !company?.premium) {
    dispatch(ReportErrorAction(t("errors.premiumRequired")))
    let path = "/companies"
    if (props.companyId !== 0) {
      path = `/companies/${props.companyId}/apartments`
    }
    return <Navigate replace to={path} />
  }
  return child

}

export default function ConnectedAuthorized({ props, child }: { props: ConnectedAuthorizedProps, child: JSX.Element }): JSX.Element {
  const {
    isAuthenticated,
    isAdmin,
  } = useSelector((state: RootState) => state.hydrolink.auth)
  const params = useParams()
  const companyId = parseInt(params.companyId ?? "0")


  return <Authorized props={{ ...props, isAuthenticated, isAdmin, companyId }} child={child} />
}

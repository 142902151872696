import { AlarmCustomization } from '../../../redux/apartments/apartments-types';
import { TableCell, TableRow } from '../../../components/Table';
import { AlarmType, Company } from '../../../redux/companies/companies-types';
import { getCurrentLocale } from '../../../components/common/language-selector/LanguageSelector';
import { useDispatch } from 'react-redux';
import { SaveAlarmCustomizationAction } from '../../../redux/apartments/apartments-actions';
import { CustomTooltip } from '../../../components/CustomTooltip';
import HighPriorityDropdown from './HighPriorityDropdown';
import NotifyOnNewDropdown from './NotifyOnNewDropdown';
import IntervalDropdown from './IntervalDropdown';
import ResetToDefaults from './ResetToDefaults';








export type Props = {
  alarmType: AlarmType;
  alarmCustomization: AlarmCustomization | null;
  company: Company;
};

const AlarmCustomizationDropDowns = ({
  alarmType,
  alarmCustomization,
  company
}: Props) => {

  const dispatch = useDispatch()

  const saveCustomization = (customization: AlarmCustomization, companyId: number) => {

    let payload = {
      companyId: companyId,
      alarmCustomization: customization
    }

    dispatch(SaveAlarmCustomizationAction(payload))

  }
  

  const custom: AlarmCustomization = alarmCustomization || 
    { alarmInterval: null, highPriority: null, notifyOnNew: null, alarmType: alarmType.alarmName, companyId: company.id }
  const language = getCurrentLocale()
  const localization = alarmType.localizations[language]

  return (
    <TableRow key={alarmType.alarmName}>
      <TableCell>
        <CustomTooltip title={localization.explanation}>
          <span className="contains-tooltip">{localization.alarmName}</span>
        </CustomTooltip>
      </TableCell>
      <TableCell>
        <HighPriorityDropdown custom={custom} alarmType={alarmType} company={company} onChange={saveCustomization}/>
      </TableCell>
      <TableCell>
        <NotifyOnNewDropdown custom={custom} alarmType={alarmType} company={company} onChange={saveCustomization}/>
      </TableCell>
      <TableCell>
        <IntervalDropdown custom={custom} alarmType={alarmType} company={company} onChange={saveCustomization}/>
      </TableCell>
      <TableCell>
        <ResetToDefaults custom={custom} alarmType={alarmType} company={company} onChange={saveCustomization}/>
      </TableCell>
    </TableRow >
  )
};

export default AlarmCustomizationDropDowns;

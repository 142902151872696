import { ChangeEvent, useEffect, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AmountSelectText, StyledPagination, PaginationContainer, SelectContainer } from "./Pagination.style";
import { useTranslation } from "react-i18next";

type Props = {
    itemsCount: number,
    current: number,
    currentPageCallback: (currentPage: number) => void
    itemsPerPageCallback: (itemsPerPage: number) => void
}

export type Pagination = {
    currentPage: number,
    itemsPerPage: number
}

export default function Pagination({ itemsCount, current, currentPageCallback, itemsPerPageCallback }: Props) {
    const t = useTranslation().t

    const itemsPerPageOptions = [10, 25, 50, 100]

    const [currentPage, setCurrentPage] = useState(current)
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0])

    useEffect(() => {
        setCurrentPage(current)
    }, [current])

    const changePage = (e: ChangeEvent<unknown>, pageNumber: number) => {
        setCurrentPage(pageNumber)
        currentPageCallback(pageNumber)
    }

    const changeItemsPerPage = (e: SelectChangeEvent<number>) => {
        setItemsPerPage(e.target.value as number)
        itemsPerPageCallback(e.target.value as number)
    }

    return <PaginationContainer>
            <StyledPagination
                siblingCount={2}
                page={currentPage}
                count={Math.ceil(itemsCount / itemsPerPage)}
                onChange={changePage}
            />
        <SelectContainer>
            <AmountSelectText>{t("pagination.showPerPage")}</AmountSelectText>
            <Select
                disableUnderline
                variant="standard"
                value={itemsPerPage}
                onChange={changeItemsPerPage}
            >
                {itemsPerPageOptions.map((v) =>
                    <MenuItem
                        key={v}
                        value={v}
                    >
                        {v}
                    </MenuItem>
                )}
            </Select>
        </SelectContainer>
    </PaginationContainer>
}
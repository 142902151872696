import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { elementWithHeader } from '../../components/common/header/Header';
import { RootState } from '../../interfaces/RootState';
import { FetchCompaniesAction } from '../../redux/companies/companies-actions';
import { useParams } from 'react-router-dom';
import ResidentApartmentsList from './ResidentApartmentsList';
import { FetchApartmentsAction } from '../../redux/apartments/apartments-actions';

export default function ResidentsManagement() {

    const dispatch = useDispatch()
    const params = useParams()
    const companyId = parseInt(params.companyId ?? "0")
    const company = useSelector(selectCompany(companyId))

    useEffect(() => {
        if (company === undefined) {
            dispatch(FetchCompaniesAction());
        }
    }, [company, dispatch])

    useEffect(() => {
        dispatch(FetchApartmentsAction({ companyId }))
    }, [companyId, dispatch])


    if (company === undefined) {
        return null
    }

    return elementWithHeader(<ResidentApartmentsList companyId={company.id} />)
}

const selectCompany = (companyId: number) => (state: RootState) =>
    state.hydrolink.companies.companies.find(c => c.id === companyId)
import React from 'react';
import { Block } from './common/block';
import ExpandIcons from './ExpandIcons';


const defaultStyle: React.CSSProperties = {
    flexDirection: 'row',
    justifyContent: 'space-between'
}

const titleBlockLeftSideStyle: React.CSSProperties = { display: 'inline-flex', alignItems: 'center' }

interface BlockProps {
    children: React.ReactNode,
    clickAble?: boolean,
    grey?: boolean,
    style?: React.CSSProperties,
    closeOrOpenBlock: () => void
}

function VisibleBlock (props: BlockProps): JSX.Element  {
    const mergedStyle = { ...defaultStyle, cursor: props.clickAble ? 'pointer' : 'auto', ...props.style }
    const onClick = props.clickAble ? () => props.closeOrOpenBlock() : undefined
    return <Block variant={props.grey ? "grey" : "white"} onClick={onClick} style={{...mergedStyle}} >{props.children}</Block>
}

function InvisibleBlock (props: BlockProps): JSX.Element  {
    const mergedStyle = { ...defaultStyle, cursor: props.clickAble ? 'pointer' : 'auto', ...props.style }
    const onClick = props.clickAble ? () => props.closeOrOpenBlock() : undefined
    return <div onClick={onClick} style={{...mergedStyle}} >{props.children}</div>
}

interface ExpandableBlockProps {
    leftSide: React.ReactNode
    children?: React.ReactNode,
    grey?: boolean,
    childrenNoFlex?: boolean,
    checkbox?: JSX.Element //This really appears only when merging apartments
    isOpen?: boolean,
    onToggleOpen?: () => void
}


export default function ExpandableBlock (props: ExpandableBlockProps): JSX.Element  {

    const [blockOpen, setBlockOpen] = React.useState<boolean>(false)

    const titleBlockStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginTop: (!props.childrenNoFlex && !props.grey )? '25px' : '0px',
        marginBottom: !props.grey  || (props.grey && blockOpen)  ? '1px' : '25px'
        }

    const contentStyle: React.CSSProperties = props.grey ? {} :{
        display: (!props.childrenNoFlex ? 'flex' : ''),
        alignItems: 'end' }

    const titleBlockLeftSide = <>{props.leftSide}{props.checkbox}</>


    const isThisBlockOpen = props.isOpen ?? blockOpen       //Apartment blocks are having their open/close state in the ApartmentList
                                                            //for making it possible to open/close all the apartments at once.
                                                            //Other components will use this local useState

    const closeOrOpenBlock = () => {                         //Opening/closing affects either to the props (apartment blocks) or the
        if (props.onToggleOpen && props.isOpen !== undefined) { //local useState, depending if the open/close state exists in the props
            props.onToggleOpen()
        } else  {
            setBlockOpen(!blockOpen)
        }
    }

    const titleBlockClickable = props.checkbox ? false : true //If the block includes checkbox (when merging apartments)
                                                                //the whole block is not clickable. Only the expanding
                                                                //icon

    return (<>
            <VisibleBlock closeOrOpenBlock={closeOrOpenBlock} grey={props.grey} clickAble={titleBlockClickable} style={titleBlockStyle}>
                <InvisibleBlock closeOrOpenBlock={closeOrOpenBlock} style={titleBlockLeftSideStyle}>
                    {titleBlockLeftSide}
                </InvisibleBlock>
                <InvisibleBlock closeOrOpenBlock={closeOrOpenBlock} clickAble>
                    <ExpandIcons closed={!blockOpen} />
                </InvisibleBlock>
            </VisibleBlock>
            {isThisBlockOpen
                &&
                <VisibleBlock closeOrOpenBlock={closeOrOpenBlock} grey={props.grey}  style={contentStyle}>
                    {props.children}
                </VisibleBlock>
            }
        </>
        )

}
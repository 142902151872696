import React from "react";
import * as Mui from "@mui/material";
import styled from "styled-components";
import { colors } from "../theme";

type Props = {
    onClick?: () => void,
    startIcon?: React.ReactNode,
    variant?: "text" | "outlined" | "contained",
    disabled?: boolean,
    style?: React.CSSProperties,
    children: React.ReactNode,
};

const StyledButton = styled(Mui.Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontWeight: "bold",
    border: `1px solid ${colors.primary04}`,
    borderRadius: '0.4em',
    ":hover": {
        backgroundColor: colors.primary06
    },
    ":active": {
        backgroundColor: colors.primary04
    }
}));

export const Button = (props: Props) => {
    const { variant, ...rest } = props;
    return <StyledButton color="primary" variant={variant ?? "outlined"} {...rest} />;
};

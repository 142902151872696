import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Message } from '../../../redux/messages/messages-types'
import { RootState } from '../../../interfaces/RootState'
import { HydrolinkDispatch } from '../../../configureStore'
import { AcknowledgeMessageAction } from '../../../redux/messages/messages-actions'

const Toasts = ({ messages, dispatch }: { messages: Message[], dispatch: HydrolinkDispatch }): JSX.Element => {

  return (
    <React.Fragment>
      {messages.map(m => {
        return (
          <Snackbar open={true} key={m.id} autoHideDuration={10000} onClose={() => { dispatch(AcknowledgeMessageAction({ id: m.id })) }}>
            <Alert onClose={() => { dispatch(AcknowledgeMessageAction({ id: m.id })) }} severity="error" sx={{ width: '100%' }}>
              {m.message}
            </Alert>
          </Snackbar>
          // <Snackbar
          //   open={true}
          //   key={m.id}
          //   autoHideDuration={10000}
          //   onClose={() => { dispatch(AcknowledgeMessageAction({ id: m.id })) }}
          //   anchorOrigin={{
          //     vertical: 'top',
          //     horizontal: 'center'
          //   }}
          // >
          //   <MySnackbarContentWrapper
          //     variant={m.type}
          //     id={m.id}
          //     message={<span style={{ fontSize: '10pt' }}>{m.message}</span>}
          //     className=""
          //     onClose={() => { }}
          //   />
          // </Snackbar>)
        )
      })}
    </React.Fragment>
  )
}

export default function ConnectedToasts(): JSX.Element {
  const { messages } = useSelector((state: RootState) => state.hydrolink.messages)
  const dispatch = useDispatch()
  return (<Toasts messages={messages} dispatch={dispatch} />)
}

import ExpandableBlock from "../../components/ExpandableBlock";
import { Apartment } from "../../redux/apartments/apartments-types";
import { faPen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { DeleteApartmentAction, UpdateApartmentNameAction } from "../../redux/apartments/apartments-actions";
import React from "react";
import { useTranslation } from "react-i18next";
import MeterList from "./Meters/MeterList";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import { Checkbox, TextField } from "@mui/material";
import { RootState } from "../../interfaces/RootState";
import { useButtonRights } from '../../utils/useButtonRights';
import { Role } from "../../redux/companies/companies-types";

type Props = {
    apartment: Apartment,
    id: number,
    companyId: number,
    apartmentsOpen: Record<number, boolean>,
    setApartmentOpenById: (apartmentId: number) => (isOpen: boolean) => void
    mergeApartmentsOn: boolean
    apartmentsToMerge: number[],
    setApartmentsToMerge: React.Dispatch<React.SetStateAction<number[]>>
}

export default function ApartmentTableRow({ apartment, id, companyId, apartmentsOpen, setApartmentOpenById,  apartmentsToMerge, setApartmentsToMerge,  mergeApartmentsOn }: Props) {
    const dispatch = useDispatch()
    const [metersOpen, setMetersOpen] = React.useState<number[]>([])
    const [nameEditingOn, setNameEditingOn] = React.useState<boolean>(false)
    const [name, setName] = React.useState<string>(apartment.code)
    const myRole = useSelector((state: RootState): Role => state.hydrolink.auth.roleByCompanyId.find(c => c.companyId === companyId)?.role ?? "viewer")
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const checked = (): boolean => apartmentsToMerge.includes(id) && mergeApartmentsOn
    const deleteApartmentButtonRights = useButtonRights(companyId, "admin")
    const onToggleOpen = () => setApartmentOpenById(apartment.id)(!apartmentsOpen[apartment.id])
    const t = useTranslation().t

    const deleteApartment = () => {
        if (window.confirm(`${t("apartments.deleteApartmentConfirmation")} "${apartment.code}"?`)) {
            dispatch(DeleteApartmentAction({ id: apartment.id, companyId: companyId }))
            setMetersOpen([...metersOpen.filter(k => k !== id)]) // Close expanded apartment so it doesn't open a new one after
        }
    }

    const check = () => {
        if (apartmentsToMerge.includes(apartment.id)) {
            setApartmentsToMerge(apartmentsToMerge.filter(a => a !== apartment.id))
        } else {
            setApartmentsToMerge([...apartmentsToMerge, apartment.id])
        }
    }

    const checkbox = () => {
        if (mergeApartmentsOn)
            return <Checkbox
                id={apartment.id.toString()}
                onChange={() => check()}
                checked={checked()}
            />
        else return undefined
    }

    const changeNameEditingStatus = () => {
        if (nameEditingOn && name !== apartment.code) {
            dispatch(UpdateApartmentNameAction({ companyId: companyId, apartmentId: apartment.id, name: name }))
        }
        setNameEditingOn(!nameEditingOn)
    }

    const apartmentRenaming = () => {
        return <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}>
            {!nameEditingOn ?
                <span className='text-grey'>
                    {name}
                </span> :
                <TextField
                    id="name"
                    size="small"
                    value={name}
                    autoFocus={true}
                    onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                            ev.preventDefault();
                            changeNameEditingStatus();
                        }
                    }}
                    onBlur={() => changeNameEditingStatus()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                />
            }
            <FontAwesomeIconButton
                onClick={() => changeNameEditingStatus()}
                icon={nameEditingOn ? faSave : faPen}
                style={{ marginLeft: '10px' }}
            />
        </div>

    }

    return (
        <div key={id} style={{ display: 'flex', flexDirection: 'column' }}>
            <ExpandableBlock isOpen={apartmentsOpen[apartment.id]} onToggleOpen={onToggleOpen} childrenNoFlex leftSide={
                <span className='text-bold'>
                    {apartment.code}
                </span>}checkbox={checkbox()}
            >
                <div>
                    <div>
                        {(myRole === 'manager' || isAdmin) && apartmentRenaming()}
                        <span className='text-grey' style={{ fontSize: 'small' }} >
                            {t("apartments.centerId")}: {apartment.client.code} | {t("apartments.clientSoftwareVersion")}: {apartment.client.softwareVersion ?? ""}
                        </span>
                        <FontAwesomeIconButton disabled={deleteApartmentButtonRights} onClick={() => deleteApartment()} icon={faTrash} style={{ marginBottom: '20px' }} />
                    </div>
                    <MeterList meters={apartment.meters} companyId={companyId} apartmentId={apartment.id} />
                </div>
            </ExpandableBlock>
        </div>
    )
}
import { useTranslation } from 'react-i18next';
import { Interval } from '../../../redux/apartments/apartments-types';
import { AlarmDropDown, AlarmDropdownProps } from './AlarmDropdown'






const intervalTranslation = (interval: Interval | null) => {

  const t = useTranslation().t;

  switch (interval) {
  case Interval.Daily:
    return t('alarms.interval.daily');
  case Interval.Monthly:
    return t('alarms.interval.monthly');
  case Interval.Never:
    return t('alarms.interval.never');
  case Interval.New:
    return t('alarms.interval.new');
  case Interval.Weekly:
    return t('alarms.interval.weekly');
  }
  return t('alarms.interval.unknown');
};

const intervalToString = (interval: Interval): string => {
  switch (interval) {
  case Interval.Daily:
    return 'DAILY';
  case Interval.Monthly:
    return 'MONTHLY';
  case Interval.Never:
    return 'NEVER';
  case Interval.New:
    return 'NEW';
  case Interval.Weekly:
    return 'WEEKLY';
  }
};

const toInterval = (intervalString: string | null): Interval | null => {
  switch (intervalString) {
  case 'DAILY':
    return Interval.Daily;
  case 'MONTHLY':
    return Interval.Monthly;
  case 'NEVER':
    return Interval.Never;
  case 'NEW':
    return Interval.New;
  case 'WEEKLY':
    return Interval.Weekly;
  }
  return null;
};


const IntervalDropdown =  ({custom, alarmType, company, onChange}: AlarmDropdownProps) => {

  const t = useTranslation().t;

  const saveIntervalCustomization = (e: any) => {
    let value: string = e.target.value

    let toSave = {
      alarmType: alarmType.alarmName,
      highPriority: custom.highPriority,
      notifyOnNew: custom.notifyOnNew,
      alarmInterval: value,
      companyId: company.id
    }

    onChange(toSave, company.id)
  }

  return (<AlarmDropDown
    id={`interval-dropdown-${alarmType.alarmName}`}
    disabled={!company.premium && alarmType.requiresPremium}
    selectedValue={
      (custom.alarmInterval !== null) ? custom.alarmInterval : 'null'
    }
    onChange={e => saveIntervalCustomization(e)}
    options={[
      {
        value: 'null',
        displayText: `${t('generic.default')} (${intervalTranslation(toInterval(alarmType.defaultInterval))})`,
      },
      {
        value: intervalToString(Interval.Daily),
        displayText: intervalTranslation(Interval.Daily),
      },
      {
        value: intervalToString(Interval.Weekly),
        displayText: intervalTranslation(Interval.Weekly),
      },
      {
        value: intervalToString(Interval.Monthly),
        displayText: intervalTranslation(Interval.Monthly),
      },
      {
        value: intervalToString(Interval.Never),
        displayText: intervalTranslation(Interval.Never),
      },
      {
        value: intervalToString(Interval.New),
        displayText: intervalTranslation(Interval.New),
      },
    ]}
  />)
}

export default IntervalDropdown
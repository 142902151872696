import { DialogActions, DialogContent } from "@mui/material";
import styled, { css } from "styled-components";
import { colors, spacings, typography } from "../../../theme";

export const StyledTitle = styled.p`
    color: ${colors.neutral08};
    font-size: ${typography.fontSize.title};
    font-weight: 600;
`

const SharedSettingsButtonStyle = css`
    padding: 10px 20px;
    font-weight: 600;
    border-radius : 5px;
    margin: 16px 0;
    &:hover {
        cursor: pointer;
        filter: brightness(120%);
    }
`
const SharedInputStyle = css`
    border: 1px solid #B5B9B9;
    border-radius: 5px;
    padding: 5px;
    font-size: ${typography.fontSize.default};
`

export const StyledFieldsText = styled.p`
    color: ${colors.neutral08};
    font-weight: 600;
    font-size: ${typography.fontSize.default};
    display: inline-block;
    margin-bottom: ${spacings.narrow};
`
export const StyledBaseReading = styled.p`
    font-size: ${typography.fontSize.default};
    color: ${colors.neutral08};
`
export const StyledBaseReadingTextField = styled.input`
    ${SharedInputStyle}
    width: 100%;
    padding: 10px;
`
export const StyledLabelSettingField = styled.input`
    ${SharedInputStyle}
    width: 290px;
    height: 90px;
`

export const StyledDialogContent = styled(DialogContent)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
`
export const StyledDialogActions = styled(DialogActions)`
    padding: ${spacings.stardardSpacing} !important;
`
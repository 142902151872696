import { ChangeEventHandler } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import { colors } from '../../../theme'
import { useTranslation } from 'react-i18next'

const styles = {
    search: {
        display: 'flex',
        border: 'solid #EFF0F3 1px',
        borderRadius: '5px',
        width: '30%',
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '10px',
        justifyContent: 'space-between',
        backgroundColor: colors.neutral00
    },
    searchIcon: {
        alignSelf: 'center',
        height: '100%'
    },
    searchRoot: {
        fontSize: '25px',
        padding: '5px',
        color: colors.neutral06
    },
    inputRoot: {
        width: '100%',
        color: 'inherit',
        fontSize: '16px'
    },
    inputInput: {
        padding: '5px'
    }
}

interface Props {
    searchValue: string,
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}

export default function StyledSearchBar(props: Props) {
    const t = useTranslation().t

    return (
        <div style={styles.search}>
            <InputBase
                value={props.searchValue}
                onChange={props.onChange}
                placeholder={t('generic.search') + '...'}
                style={{
                    width: '100%'
                }}
                inputProps={{
                    'aria-label': t('generic.search')
                }}
            />
            <div style={styles.searchIcon}>
                <SearchIcon />
            </div>
        </div>
    )
}

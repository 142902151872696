import { useEffect, useState } from "react"
import { Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "../../components/Table"
import { RootState } from "../../interfaces/RootState"
import { FetchApartmentsAction } from "../../redux/apartments/apartments-actions"
import { Center } from "../../redux/centers/centers-types"
import { CentersRow } from "./CentersRow"
import { FetchClientCommandsAction } from "../../redux/centers/centers-actions"


const CentersView = (): JSX.Element => {
    const t = useTranslation().t

    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments)
    const [centers, setCenters] = useState<Center[]>([])

    useEffect(() => {
        const c: Center[] = []
        for (let a of apartments) {
            if (c.find(e => e.id === a.client.id) === undefined) {
                c.push(a.client as Center)
            }
        }
        setCenters(c)
    }, [apartments])


    return <TableContainer component={Paper}>
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{t('centers.center')}</TableCell>
                    <TableCell>{/* Expand center button */}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {centers.map((c: Center) => <CentersRow key={`parent_${c.id}`} center={c} />)}
            </TableBody>
        </Table>
    </TableContainer>
}

export default function Centers() {
    const dispatch = useDispatch()
    const params = useParams()
    const companyId = parseInt(params.companyId ?? "0")

    useEffect(() => {
        dispatch(FetchApartmentsAction({ companyId }))
        dispatch(FetchClientCommandsAction())
    })

    return <CentersView />
}
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Center, ClientCommand, QueuedCommand } from "../../redux/centers/centers-types"
import { Table, TableHead, TableRow, TableCell, TableBody } from "../../components/Table"
import { displayDateTime } from '../../utils/utils'
import { Button } from "../../components/Button"
import { RootState } from "../../interfaces/RootState"
import { SendClientCommandAction } from "../../redux/centers/centers-actions"
import ButtonsDisplayer from "../../components/FloatButtonsRight"

type Props = {
    center: Center,
    clientCommandsInfo: ClientCommand[],
    companyId: number, // Only used for fetching apartments after sendClientCommand
    language: string,
}

function CentersInfoView({ center, clientCommandsInfo, language, companyId }: Props): JSX.Element {
    const dispatch = useDispatch()
    const t = useTranslation().t

    const queuedCommands = useMemo(() => center.commandQueue, [center.commandQueue])

    const parseDate = (date?: string): string => {
        if (date === null || date === undefined) {
            return "-"
        }

        return displayDateTime(new Date(date))
    }

    const getTranslatedClientCommandName = (tag: string): string =>
        clientCommandsInfo.find((val) => val.tag === tag)!!.nameByLang[language]

    const sendClientCommand = (tag: string) => {
        dispatch(SendClientCommandAction({ tag, clientId: center.id, companyId }))
    }

    const commandsTableHead = (): React.ReactNode => <TableHead>
        <TableRow>
            <TableCell>{t('centers.command')}</TableCell>
            <TableCell>{t('centers.created')}</TableCell>
            <TableCell>{t('centers.sent')}</TableCell>
            <TableCell>{t('centers.responseReceived')}</TableCell>
        </TableRow>
    </TableHead>

    const commandsTableRow = (queuedCommand: QueuedCommand) => <TableRow key={queuedCommand.id}>
        <TableCell>{queuedCommand.tag}</TableCell>
        <TableCell>{parseDate(queuedCommand.createdAt)}</TableCell>
        <TableCell>{parseDate(queuedCommand.sentAt)}</TableCell>
        <TableCell>{parseDate(queuedCommand.responseReceivedAt)}</TableCell>
    </TableRow>

    const supportedCommandButtons = () => {
        let list: JSX.Element[] = []
        for (let sc of center.supportedCommands) {
            list.push(<Button
                onClick={() => sendClientCommand(sc.tag)}
            >
                {getTranslatedClientCommandName(sc.tag)}
            </Button>)
        }
        return list
    }

    return <>
        <Table>
            {commandsTableHead()}
            <TableBody>
                {queuedCommands.map((qc) => commandsTableRow(qc))}
            </TableBody>
        </Table>
        <ButtonsDisplayer
            buttons={supportedCommandButtons()}
        />
    </>
}


export default function CentersInfo({ center }: { center: Center }) {
    const clientCommandsInfo = useSelector((state: RootState) => state.hydrolink.centers.clientCommands)
    const companyId = parseInt(useParams().companyId ?? "0")
    const language = useTranslation().i18n.language

    return <CentersInfoView
        center={center}
        clientCommandsInfo={clientCommandsInfo}
        companyId={companyId}
        language={language}
    />
}
import React from 'react';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { RootState } from "../../interfaces/RootState";
import { useDispatch, useSelector } from 'react-redux';
import ResidentRow from './ResidentRow';
import { Apartment } from '../../redux/apartments/apartments-types';
import { SortResidentsAction } from '../../redux/residents/residents-actions';
import { table } from '../../theme';
import Sorter from '../../components/Sorter';


const ResidentApartmentsTable = ({ companyId }: { companyId: number }) => {

    const t = useTranslation().t
    const dispatch = useDispatch()

    const filteredAndSortedResidentApartments = useSelector((state: RootState) => state.hydrolink.residents.filteredAndSortedResidentApartments)
    const residents = useSelector((state: RootState) => state.hydrolink.residents.residents)
    const pagination = useSelector((state: RootState) => state.hydrolink.residents.pagination)
    const authorized = useSelector((state: RootState) => state.hydrolink.residents.authorized)
    const currentSorting = useSelector((state: RootState) => state.hydrolink.residents.sortBy)
    const currentAscending = useSelector((state: RootState) => state.hydrolink.residents.ascending)

    let startIndex = (pagination.currentPage - 1) * pagination.itemsPerPage
    let endIndex = startIndex + pagination.itemsPerPage

    const apartments = filteredAndSortedResidentApartments.slice(startIndex, endIndex)

    const sortResidentApartment = (sortApartments: Apartment[], sortBy: string, ascending: boolean) => {
        dispatch(SortResidentsAction({ apartments: sortApartments, sortBy: sortBy, ascending: ascending }))
    }

    const findResident = (apartmentId: number) => {
        return residents.find(r => r.apartmentId === apartmentId)
    }

    const residentApartmentTableHead = (): React.ReactNode => <TableHead>
        <TableRow>
            <Sorter
                sortKey={"code"}
                items={filteredAndSortedResidentApartments}
                sortAction={sortResidentApartment}
                sortBy={currentSorting}
                title={t('residents.apartment')}
                width={table.tableCellWidthVeryShort}
                sortData={(apartment: Apartment) => apartment.code}
                currentAscending={currentAscending}
            />
            <Sorter
                sortKey={"residentEmails"}
                items={filteredAndSortedResidentApartments}
                sortAction={sortResidentApartment}
                sortBy={currentSorting}
                title={t('residents.emails')}
                width={table.tableCellWidthLonger}
                sortData={(apartment: Apartment) => findResident(apartment.id)?.main?.email ?? '' + findResident(apartment.id)?.other?.email ?? ''}
                currentAscending={currentAscending}
            />
            <TableCell>{/* Expand button */}</TableCell>
        </TableRow>
    </TableHead>

    return <TableContainer component={Paper}>
        <Table>
            {residentApartmentTableHead()}
            <TableBody>
                {apartments.map(a => <ResidentRow key={a.id} companyId={companyId} apartment={a} resident={authorized ? residents.find(r => r.apartmentId === a.id) : null} />)}
            </TableBody>
        </Table>
    </TableContainer>
}


export default ResidentApartmentsTable
import {
    LockSuccessAction,
    LogoutSuccessAction,
    FetchUserInformationAction,
    UserReceivedAction,
    UserReceivedFailureAction,
    SetIdTokenAction,
    ForceLogoutAction,
} from './auth-actions'
import { AuthState } from './auth-types'
import { AnyAction } from 'redux'
import { REDIRECT_URL } from '../../constants/urls'
import { clearToken } from '../../utils/persistence-utils'
import { lastLoginErrorKey } from '../../constants/lastLoginErrorKey'

const initialState: AuthState = {
    isAuthenticated: false,
    email: '',
    isAdmin: false,
    idToken: '',
    errorMessage: null,
    roleByCompanyId: []
}

export default function authReducer(state: AuthState = initialState, action: AnyAction): AuthState {
    if (LockSuccessAction.match(action)) {
        return {
            ...state,
            isAuthenticated: true,
            errorMessage: null,
            idToken: action.payload.token
        }
    }

    if (LogoutSuccessAction.match(action)) {
        return {
            ...state,
            isAuthenticated: false
        }
    }

    if (FetchUserInformationAction.match(action)) {
        return {
            ...state,
        }
    }

    if (UserReceivedAction.match(action)) {
        return {
            ...state,
            email: action.payload.email,
            isAdmin: action.payload.admin,
            roleByCompanyId: action.payload.roleByCompanyId
        }
    }

    if (UserReceivedFailureAction.match(action)) {
        localStorage.setItem(lastLoginErrorKey, JSON.stringify(action.payload))
        return {
            ...state,
            errorMessage: 'Käyttäjätietojen haku epäonnistui, istuntosi on mahdollisesti vanhentunut. Kokeile kirjautua sisään uudelleen'
        }
    }

    if (SetIdTokenAction.match(action)) {
        return {
            ...state,
            idToken: action.payload,
            isAuthenticated: true
        }
    }

    if (ForceLogoutAction.match(action)) {
         clearToken()
        window.location.replace(REDIRECT_URL!!)
    }

    return state
}

import { Select, MenuItem, SelectChangeEvent } from '@mui/material'


type Option = {
    value: string,
    displayText: string
}

interface DropDownProps {
    id: string,
    options: Option[],
    selectedValue: string,
    onChange: (event: any) => void,
    disabled?: boolean,
    width?: string
}

const DropDown = (props: DropDownProps) => {

    return (<Select
        disabled={props.disabled}
        labelId={props.id}
        id={props.id}
        value={props.selectedValue}
        label=""
        style={{ height: '35px', width: props.width }}
        onChange={props.onChange}
    >
        {props.options.map((v, i) => <MenuItem key={i} value={v.value}>{v.displayText}</MenuItem>)}
    </Select>)
}

export default DropDown
import { useTranslation } from 'react-i18next';
import { AlarmDropDown, AlarmDropdownProps, parseBool } from './AlarmDropdown'

const NotifyOnNewDropdown = ({custom, alarmType, company, onChange}: AlarmDropdownProps) => {


  const t = useTranslation().t;

  const saveNotifyOnNewCustomization = (e: any) => {
    let value: string = e.target.value
  
    let toSave =  {
      alarmType: alarmType.alarmName,
      highPriority: custom.highPriority,
      notifyOnNew: parseBool(value),
      alarmInterval: custom.alarmInterval,
      companyId: company.id
    }
  
    onChange(toSave, company.id)
  }

  return (<AlarmDropDown
    id={`notify-on-new-dropdown-${alarmType.alarmName}`}
    disabled={!company.premium && alarmType.requiresPremium}
    selectedValue={
      (custom.notifyOnNew !== null) ? custom.notifyOnNew.toString() : 'null'
    }
    onChange={e => saveNotifyOnNewCustomization(e)}
    options={[
      {
        value: 'null',
        displayText: alarmType.defaultNotifyOnNew
          ? `${t('generic.default')} (${t('generic.yes')})`
          : `${t('generic.default')} (${t('generic.no')})`,
      },
      { value: 'true', displayText: `${t('generic.yes')}` },
      { value: 'false', displayText: `${t('generic.no')}` },
    ]}
  />)
  
}

export default NotifyOnNewDropdown
import Fuse from 'fuse.js'
import React, { useEffect } from "react"
import { useDispatch } from 'react-redux';
import StyledSearchBar from './SearchBar'

type Props<T> = {
    items: T[],
    searchData: (item: T) => string[],
    filterAction: (items: T[]) => { type: string, payload: T[] },
    pageChange: (arg0: number) => void
}

function SearchBar<T>({ items, searchData, filterAction, pageChange }: Props<T>): JSX.Element {

    const dispatch = useDispatch()
    const [search, setSearch] = React.useState('')
    const itemsWithSearchData = items.map(item => ({ item, searchData: searchData(item) }))
    const fuse = new Fuse(itemsWithSearchData, {minMatchCharLength: 2, ignoreLocation: true, threshold: 0.4, keys: ["searchData"] })

    useEffect(() => {
        // We should only do a search if there are 2 or more characters, otherwise just show all items
        let result = search.length < 2 ? items : fuse.search(search).map(res => res.item.item)
        dispatch(filterAction(result))
    }, [items, search])

    useEffect(() => {
        pageChange(1)
    }, [search])

    return <StyledSearchBar
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        searchValue={search}
    />
}

export default SearchBar

import { Modal } from "../../components/common/modal/Modal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import { Button } from '../../components/Button';
import { OpenChartAction } from "../../redux/apartments/apartments-actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Apartment, ChartState, Meter, MeterHistoryReading } from "../../redux/apartments/apartments-types";
import { FormControl } from "@mui/material";
import { TFunction, useTranslation } from "react-i18next";
import { displayDate, displayDateTime } from '../../utils/utils';
import { barChartStyles, colors } from "../../theme";
import DropDown from "../../components/DropDown";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true
    }
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const sortAndDisplayDates = (dates: number[], chartState: ChartState, t: TFunction<"translation", undefined>) => {
  const labels: string[] = []
  const sortedDates = dates.sort((a, b) => a - b)
  for (let d of sortedDates) {
    let str = ''
    if (chartState.type === 'month' && chartState.meterType !== 'temperature') { //Water and energy meters' month view (shows only the month and the year)
      str = t('date.' + monthNames[new Date(d).getMonth()]).substring(0, 3) + ' ' + new Date(d).getFullYear()
    } else if (chartState.type !== 'month' && chartState.meterType === 'temperature') { //Temperature meters' day and week views (shows the time too)
      str = displayDateTime(new Date(d))
    } else {
      str = displayDate(new Date(d))
    }
    labels.push(str)
  }
  return labels
}

const getDatasetName = (meter: Meter | undefined, t: TFunction<"translation", undefined>, round: number): string => {
  if (meter?.type === 'water') {
    return meter?.warm ? t('meters.warmWater') : t('meters.coldWater')
  }
  if (meter?.type === 'energy') {
    return meter?.heating ? t('meters.values.heating') : t('meters.values.cooling')
  }
  if (meter?.type === 'temperature') {
    if (round === 2) {
      return t('meters.values.humidity')
    } else {
      return t('meters.values.temperature')
    }
  }
  return ''
}

const getBarColor = (meter: Meter | undefined, round: number): string => {
  if (meter?.type === 'water') {
    return meter?.warm ? colors.warm06 : colors.cold06
  }
  if (meter?.type === 'energy') {
    return meter?.heating ? colors.warm06 : colors.cold06
  }
  if (meter?.type === 'temperature') {
    if (round === 2) {
      return colors.cold06
    } else {
      return colors.warm06
    }
  }
  return colors.cold06
}

const getData = (usageData: Record<number, MeterHistoryReading[]>, meters: Meter[], chartState: ChartState, t: TFunction<"translation", undefined>) => {
  const dates: number[] = []
  const datasets: any = []
  
  for (let meterId in usageData) {
    const meter = meters.find((m: Meter) => m.id === parseInt(meterId))
    let rounds: number[] = [1]
    if (meter?.type === 'temperature') {
      rounds.push(2)
    }
    for (let r of rounds) {
      let datasetName = getDatasetName(meter, t, r)
      let dataset: any = {
        label: 'Dataset',
        data: [],
        backgroundColor: getBarColor(meter, r),
        ...barChartStyles,
      }
      for (let m of usageData[meterId]) {
        dataset.label = datasetName
        if (r === 2) {
          dataset.data.push(m.averageHumidityPercent)
        } else {
          dataset.data.push(m.value)
        }
        if (dates.indexOf(new Date(m.created).getTime()) === -1) {
          let d = new Date(m.created).getTime()
          dates.push(d)
        }
      }
      datasets.push(dataset)
    }
  }
  return {
    labels: sortAndDisplayDates(dates, chartState, t),
    datasets: datasets,
  }
}


type Props = {
  isOpen: boolean,
  close: () => void
}

export function ChartModal({ isOpen, close }: Props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const getDialogActions = () => <>
  </>

  const day = { value: 'day', displayText: t('meters.day') }
  const week = { value: 'week', displayText: t('meters.week') }
  const month = { value: 'month', displayText: t('meters.month') }
  const chartState = useSelector((state: RootState) => state.hydrolink.apartments.chartState)
  const usageData = useSelector((state: RootState) => state.hydrolink.apartments.usageByMeterId)
  const apartment = useSelector((state: RootState) => state.hydrolink.apartments.apartments.find((a: Apartment) => a.id === chartState.apartmentId))
  let dropDownOptions: { value: string; displayText: string }[] = []
  const meters = apartment?.meters
  let title = ''

  const changeType = (e: any) => {
    dispatch(OpenChartAction({ apartmentId: chartState.apartmentId ?? 0, type: e.target.value, offset: chartState.offset, meterType: chartState.meterType }))
  }

  let titleAddition = ''
  switch (chartState.meterType) {
    case 'temperature':
      dropDownOptions = [day, week, month]
      titleAddition = t('meters.titles.temperature')
      break;
    case 'energy':
      dropDownOptions = [day, month]
      titleAddition = t('meters.titles.energy')
      break;
    case 'water':
      dropDownOptions = [day, month]
      titleAddition = t('meters.titles.water')
      break;
  }
  title = 'Asunnon ' + apartment?.code + " " + titleAddition

  return <Modal
    title={title}
    open={isOpen}
    dialogActions={getDialogActions()}
    closeModal={close}
  >
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div></div>
      <FormControl>
        <DropDown id={"day-month"}
          options={dropDownOptions}
          selectedValue={chartState.type}
          onChange={changeType} />
      </FormControl>
    </div>
    <div>
      <Bar
        options={options}
        data={getData(usageData, meters ?? [], chartState, t)}
      />
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Button variant='outlined' onClick={() => dispatch(OpenChartAction({ apartmentId: chartState.apartmentId ?? 0, type: chartState.type, offset: chartState.offset + 1, meterType: chartState.meterType }))}>
        <FontAwesomeIcon icon={faAngleLeft} size='1x' />
      </Button>
      <Button variant='outlined' onClick={() => dispatch(OpenChartAction({ apartmentId: chartState.apartmentId ?? 0, type: chartState.type, offset: chartState.offset - 1, meterType: chartState.meterType }))}>
        <FontAwesomeIcon icon={faAngleRight} size='1x' />
      </Button>
    </div>
  </Modal>

}
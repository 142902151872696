import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';
import React, { useEffect } from 'react';
import { Block } from '../../components/common/block';
import { ChartModal } from './ChartModal';
import { CloseChartAction } from '../../redux/apartments/apartments-actions';
import { useTranslation } from 'react-i18next';
import ExpandIcons from '../../components/ExpandIcons';
import ApartmentsTableRow from './ApartmentsTableRow';


export type ApartmentBlock = Record<number, boolean>


const ApartmentList = ({ companyId, mergeApartmentsOn, apartmentsToMerge, setApartmentsToMerge }: { companyId: number, mergeApartmentsOn: boolean, apartmentsToMerge: number[], setApartmentsToMerge: React.Dispatch<React.SetStateAction<number[]>> }) => {
  const chartModalOpen = useSelector((state: RootState) => state.hydrolink.apartments.chartState.isChartOpen)
  const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments)
  const [apartmentsOpen, setApartmentsOpen] = React.useState<ApartmentBlock>({})
  const [allApartmentsOpen, setAllApartmentsOpen] = React.useState<boolean>(false)
  const dispatch = useDispatch()
  const t = useTranslation().t

  useEffect(() => {
    let aOpen: ApartmentBlock = {}
    for (let a of apartments) {
      aOpen[a.id] = allApartmentsOpen
    }
    setApartmentsOpen(aOpen)
  }, [apartments])

  const openAllApartmentBlocks = () => {
    let newApartmentsOpen: ApartmentBlock = JSON.parse(JSON.stringify(apartmentsOpen))
    if (!allApartmentsOpen) {
      for (let a in newApartmentsOpen) {
        newApartmentsOpen[a] = true
      }
      setAllApartmentsOpen(true)
    } else {
      for (let a in newApartmentsOpen) {
        newApartmentsOpen[a] = false
      }
      setAllApartmentsOpen(false)
    }
    setApartmentsOpen(newApartmentsOpen)
  }

  const setApartmentOpenById = (apartmentId: number) => (isOpen: boolean) => { setApartmentsOpen({ ...apartmentsOpen, [apartmentId]: isOpen })}

  return (<>
    <ChartModal isOpen={chartModalOpen} close={() => { dispatch(CloseChartAction()) }} />
    <Block onClick={() => openAllApartmentBlocks()} noMargin variant="white" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
      <span className='text-grey'>
        {t("apartments.apartment")}
      </span>
      <div>
        <span className='text-grey' style={{ marginRight: '20px' }}>
          {!allApartmentsOpen ? t("apartments.openAllApartments") : t("apartments.closeAllApartments")}
        </span>
        <ExpandIcons closed={!allApartmentsOpen} />
      </div>

    </Block>
    {apartments.map((c, i) => {
      return <ApartmentsTableRow key={c.id} id={c.id} apartment={c} mergeApartmentsOn={mergeApartmentsOn} companyId={companyId} setApartmentsToMerge={setApartmentsToMerge} apartmentsToMerge={apartmentsToMerge} apartmentsOpen={apartmentsOpen} setApartmentOpenById={setApartmentOpenById} />
    })}
  </>
  )
}

export default ApartmentList
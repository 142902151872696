let storage: Record<string, string> = {}

const ID_TOKEN = "id_token"

export function persistToken(value: string) {
  if (!window.localStorage) {
    storage[ID_TOKEN] = value
    return
  }
  localStorage.setItem(ID_TOKEN, value)
}

export function getToken() {
  if (!window.localStorage) {
    return storage[ID_TOKEN] || null
  }
  return localStorage.getItem(ID_TOKEN)
}

export function clearToken() {
  if (!window.localStorage) {
    storage = {}
    return
  }
  return localStorage.removeItem(ID_TOKEN)
}

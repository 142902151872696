import { AnyAction } from "redux";
import {
  ChangeMeterAction,
  ChangeMeterFailureAction,
  ChangeMeterSuccessAction,
  ClearApartmentsAction,
  CloseChartAction,
  CloseSaveCompanyBasicInfoModalAction,
  DeleteApartmentAction,
  DeleteApartmentFailureAction,
  DeleteMeterAction,
  DeleteMeterFailureAction,
  FetchAlarmSettingsAction,
  FetchAlarmSettingsFailureAction,
  FetchAlarmSettingsSuccessAction,
  FetchApartmentsAction,
  FetchApartmentsFailureAction,
  FetchApartmentsSuccessAction,
  FetchGraphsFailureAction,
  FetchGraphsSuccessAction,
  FetchPremiumSettingsAction,
  FetchPremiumSettingsFailureAction,
  FetchPremiumSettingsSuccessAction,
  FetchReportFormatsAction,
  FetchReportFormatsFailureAction,
  FetchReportFormatsSuccessAction,
  FetchReportSettingsAction,
  FetchReportSettingsFailureAction,
  FetchReportSettingsSuccessAction,
  OpenChartAction,
  OpenSaveCompanyBasicInfoModalAction,
  SaveAlarmSettingsAction,
  SaveAlarmSettingsFailureAction,
  SaveAlarmSettingsSuccessAction,
  SaveReportSettingsAction,
  SaveReportSettingsFailureAction,
  SaveReportSettingsSuccessAction,
  UpdateApartmentNameAction,
  UpdateApartmentNameFailureAction,
  UpdateApartmentNameSuccessAction,
  UpdateClientTelephoneNumberAction,
  UpdateClientTelephoneNumberFailureAction,
  UpdateClientTelephoneNumberSuccessAction,
  UploadCSVAction,
  UploadCSVFailureAction,
  UploadCSVSuccessAction,
} from "./apartments-actions";
import { Apartment, ApartmentsState, MeterHistoryReading } from "./apartments-types";


const initialState: ApartmentsState = {
  loading: false,
  apartments: [],
  isReportingOpen: false,
  saveCompanyBasicInfoModalOpen: false,
  usageByMeterId: {},
  chartState: {
    apartmentId: null,
    isChartOpen: false,
    type: "day",
    offset: 0,
    meterType: null
  },
  premiumSettings: {
    phoneNumbers: "",
    warmWaterPrice: "",
    coldWaterPrice: "",
  },
  alarmSettings: {
    stuckEmail: "",
    alarmLang: "",
    alarmCustomizations: []
  },
  reportSettings: {
    reportingId: "",
    sendReports: false,
    reportEmail: "",
    reportFormat: "",
    reportIntervalMonths: 0,
    reportLang: ""
  },
  reportFormats: []
}

const loadingTrue = (state: ApartmentsState): ApartmentsState => ({ ...state, loading: true })
const loadingFalse = (state: ApartmentsState): ApartmentsState => ({ ...state, loading: false })


const actions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {

  if (FetchApartmentsAction.match(action)) {
    return loadingTrue(state)
  }

  if (FetchReportFormatsAction.match(action)) {
     return loadingTrue(state)
  }

  if (SaveAlarmSettingsAction.match(action)) {
    return loadingTrue(state)
  }
  if (SaveReportSettingsAction.match(action)) {
    return loadingTrue(state)
  }

  if (UpdateClientTelephoneNumberAction.match(action)) {
    return loadingTrue(state)
  }
  if (ChangeMeterAction.match(action)) {
    return loadingTrue(state)
  }

  if (UpdateApartmentNameAction.match(action)) {
    return loadingTrue(state)
  }

  if (UploadCSVAction.match(action)) {
    return loadingTrue(state)
  }

  if (DeleteMeterAction.match(action)) {
    return loadingTrue(state)
  }
  if (DeleteApartmentAction.match(action)) {
    return loadingTrue(state)
  }

  if (FetchPremiumSettingsAction.match(action)) {
    return {
      ...state,
      premiumSettings: initialState.premiumSettings,
      loading: true
    }
  }
  if (FetchReportSettingsAction.match(action)) {
    return {
      ...state,
      reportSettings: initialState.reportSettings,
      loading: true
    }
  }
  if (FetchAlarmSettingsAction.match(action)) {
    return {
      ...state,
      alarmSettings: initialState.alarmSettings,
      loading: true
    }
  }

  if (OpenChartAction.match(action)) {
    return {
      ...state,
      chartState: {
        apartmentId: action.payload.apartmentId,
        isChartOpen: true,
        type: action.payload.type,
        offset: action.payload.offset,
        meterType: action.payload.meterType
      },
      loading: true
    }
  }

  if (FetchAlarmSettingsAction.match(action)) {
    return {
      ...state,
      alarmSettings: {
        ...initialState.alarmSettings,
        alarmCustomizations: state.alarmSettings.alarmCustomizations
      },
      loading: true
    }
  }

  return state
}

const successActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {

  if (SaveAlarmSettingsSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  if (SaveReportSettingsSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  if (UpdateApartmentNameSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  if (UploadCSVSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  if (ChangeMeterSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchApartmentsSuccessAction.match(action)) {
    action.payload.sort((a, b) => (a.code.localeCompare(b.code)))
    return {
      ...state,
      apartments: action.payload,
      loading: false
    }
  }

  if (FetchGraphsSuccessAction.match(action)) {
    let payload: Record<number, MeterHistoryReading[]> = {}
    if (action.payload.usageByMeterId !== undefined) {
      for (let i in action.payload.usageByMeterId) {
        payload[i] = action.payload.usageByMeterId[i].map(u => ({
          created: u.created,
          value: u.usageLitres
        }))
      }
    } else if (action.payload.usageByEnergyMeterId !== undefined) {
      for (let i in action.payload.usageByEnergyMeterId) {
        payload[i] = action.payload.usageByEnergyMeterId[i].map(u => ({
          created: u.created,
          value: u.usageKwh
        }))
      }
    } else if (action.payload.byTemperatureMeterId !== undefined) {
      for (let i in action.payload.byTemperatureMeterId) {
        payload[i] = action.payload.byTemperatureMeterId[i].map(u => ({
          created: u.timestamp,
          value: u.averageTemperatureCelsius,
          averageHumidityPercent: u.averageHumidityPercent
        }))
      }
    }
    return {
      ...state,
      usageByMeterId: payload,
      loading: false
    }
  }

  if (FetchAlarmSettingsSuccessAction.match(action)) {
    return {
      ...state,
      alarmSettings: action.payload,
      loading: false
    }
  }

  if (FetchReportSettingsSuccessAction.match(action)) {
    return {
      ...state,
      reportSettings: action.payload,
      loading: false
    }
  }

  if (FetchReportFormatsSuccessAction.match(action)) {
    return {
      ...state,
      reportFormats: action.payload,
      loading: false
    }
  }

  if (FetchPremiumSettingsSuccessAction.match(action)) {
    return {
      ...state,
      premiumSettings: action.payload,
      loading: false
    }
  }

  if (UpdateClientTelephoneNumberSuccessAction.match(action)) {
    return {
      ...state,
      apartments: state.apartments.map(apt => withClientTelephoneNumber(apt, action.payload.clientId, action.payload.newTelephoneNumber)),
      loading: false
    }
  }
  return state
}

const otherActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {

  if (OpenSaveCompanyBasicInfoModalAction.match(action)) {
    return {
      ...state,
      saveCompanyBasicInfoModalOpen: true
    }
  }

  if (CloseChartAction.match(action)) {
    return {
      ...state,
      chartState: { ...state.chartState, isChartOpen: false }
    }
  }

  if (CloseSaveCompanyBasicInfoModalAction.match(action)) {
    return {
      ...state,
      saveCompanyBasicInfoModalOpen: false
    }
  }
  if (ClearApartmentsAction.match(action)) {
    return {
      ...state,
      apartments: [],
      loading: false,
      chartState: {
        apartmentId: null,
        isChartOpen: false,
        type: "day",
        offset: 0,
        meterType: null
      },
      isReportingOpen: false,
      saveCompanyBasicInfoModalOpen: false
    }
  }

  return state
}

const failureActions = (state: ApartmentsState, action: AnyAction): ApartmentsState => {
  if (FetchApartmentsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchGraphsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchAlarmSettingsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchReportSettingsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchReportFormatsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (FetchPremiumSettingsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (UpdateClientTelephoneNumberFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (SaveAlarmSettingsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (SaveReportSettingsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (UploadCSVFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (ChangeMeterFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (DeleteMeterFailureAction.match(action)) {
    return loadingFalse(state)
  }
  if (DeleteApartmentFailureAction.match(action)) {
    return loadingFalse(state)
  }

  if (UpdateApartmentNameFailureAction.match(action)) {
    return loadingFalse(state)
  }
  return state
}


export default function apartmentsReducer(state: ApartmentsState = initialState, action: AnyAction): ApartmentsState {

  state = actions(state, action)
  state = successActions(state, action)
  state = failureActions(state, action)
  state = otherActions(state, action)
  return state
}

export function selectApartments(state: ApartmentsState): Apartment[] {
  return state.apartments
}

function withClientTelephoneNumber(apartment: Apartment, clientId: number, telephoneNumber: string | null): Apartment {
  if (apartment.client.id !== clientId) {
    return apartment;
  }

  return {
    ...apartment,
    client: {
      ...apartment.client,
      telephoneNumber,
    },
  };
}

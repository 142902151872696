import { call, Effect, select, put as putEffect } from "redux-saga/effects"
import { API_URL } from "../constants/urls"
import { ForceLogoutAction } from "../redux/auth/auth-actions"
import selectIdToken from "../redux/auth/select-id-token"
import AddLanguageToURL from "./AddLanguageToURL"

export type Action<T> = { type: string, payload: T }

export function* request<TRequest, TResponse, TResult, TError>(
    method: 'POST' | 'GET' | 'PUT' | 'DELETE',
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>
) {
    try {
        if (!path.startsWith("/")) {
            path = "/" + path
        }
        path = AddLanguageToURL(path)

        const idToken: string = yield select(selectIdToken)
        const result: TResponse = yield call(() => {
            return fetch(`${API_URL}${path}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            }).then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    if (response.status === 400) {
                        return response.text().then((text: string) => {
                            throw new Error(response.statusText.toUpperCase() + "\n" + text)
                        })
                    }
                    throw response
                }
            })
        })

        yield onOk(result)
    } catch (error: any) {
        if (error.status === 401) {
            yield putEffect(ForceLogoutAction())
        }
        let code = undefined
        let message = ""
        if (error.statusText) {
            message = error.statusText
            code = error.status
        } else if (error.message) {
            message = error.message
        } else {
            message = JSON.stringify(error)
        }
        yield onErr(message, code)
    }
}

export function* post<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>
) {
    yield request('POST', path, body, onOk, onErr)
}

export function* put<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>
) {
    yield request('PUT', path, body, onOk, onErr)
}

export function* remove<TRequest, TResponse, TResult, TError>(
    path: string,
    body: TRequest,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>
) {
    yield request('DELETE', path, body, onOk, onErr)
}

export function* get<TResponse, TResult, TError>(
    path: string,
    onOk: (res: TResponse) => Effect<string, TResult>,
    onErr: (err: string, code: number | undefined) => Effect<string, TError>
) {
    yield request('GET', path, undefined, onOk, onErr)
}

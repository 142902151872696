
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '../../components/Button';
import { Modal } from '../../components/common/modal/Modal';
import { ModalInput } from '../../components/common/modal/ModalInput';
import { SaveCompanyBasicInfoAction } from '../../redux/companies/companies-actions';
import { Company, SaveCompanyPayload } from '../../redux/companies/companies-types';
import { ReportErrorAction } from '../../redux/error/error-actions';
import {useButtonRights} from '../../utils/useButtonRights';


type Props = {
  isOpen: boolean,
  close: () => void,
  company: Company
}

export function BasicInfoEditModal({ isOpen, close, company }: Props) {

  const t = useTranslation().t
  const dispatch = useDispatch()
  const saveBasicInfoButtonRights = useButtonRights(company.id, 'admin')

  useEffect(() => {
    setCompanyData({
      ...company
    })
  }, [company, isOpen]);

  const [companyData, setCompanyData] = useState<SaveCompanyPayload>({
    id: company.id,
    name: company.name,
    streetAddress: company.streetAddress,
    zipCode: company.zipCode,
    town: company.town
  })

  const onFormChange = (e: React.ChangeEvent<HTMLInputElement>, companyField: keyof typeof companyData) => {
    setCompanyData({
      ...companyData,
      [companyField]: e.target.value
    })
  }

  const onSave = () => {
    const regex = /^\s*$/
    if (regex.test(companyData.name)) {
      dispatch(ReportErrorAction(t('errors.companyNameMissing')))
    } else {
      dispatch(SaveCompanyBasicInfoAction(companyData))
    }
  }

  const getDialogActions = () => <>
    <Button
      onClick={close}
    >
      {t('generic.cancel')}
    </Button>
    <Button
      disabled={saveBasicInfoButtonRights}
      variant="contained"
      onClick={onSave}
    >
      {t('generic.save')}
    </Button>
  </>

  // The reason as to why this is not a normal React component is because the input field unselects on every render
  function companyModalInput(field: keyof typeof companyData) {
    return <ModalInput
      key={field}
      title={t('companies.create.' + field)}
      tooltip={t('companies.create.' + field + '.tooltip')}
      value={String(companyData[field])}
      handleFormValue={e => onFormChange(e, field)}
    />
  }

  return <Modal
    title={t('companies.basicInfo')}
    open={isOpen}
    dialogActions={getDialogActions()}
    closeModal={close}
  >
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'space-between', gap: '1rem' }}>
      {companyModalInput('name')}
      {companyModalInput('streetAddress')}
      {companyModalInput('zipCode')}
      {companyModalInput('town')}
    </div>
  </Modal>
}
import { createAction } from "@reduxjs/toolkit"
import {
    DeleteResidentPayload,
    FetchResidentsPayload,
    FetchResidentsSuccessPayload,
    FilterResidentApartmentPayload,
    SortResidentApartmentPayload,
    UpsertResidentPayload,
    UpsertResidentSuccessPayload
} from "./residents-types"

export const FetchResidentsAction = createAction<FetchResidentsPayload>('FETCH_RESIDENTS')
export const FetchResidentsSuccessAction = createAction<FetchResidentsSuccessPayload>('FETCH_RESIDENTS_SUCCESS')
export const FetchResidentsUnauthorizedAction = createAction<string>('FETCH_RESIDENTS_UNAUTHORIZED')
export const FetchResidentsFailureAction = createAction<void>('FETCH_RESIDENTS_FAILURE')

export const UpsertResidentAction = createAction<UpsertResidentPayload>('UPSERT_RESIDENT')
export const UpsertResidentSuccessAction = createAction<UpsertResidentSuccessPayload>('UPSERT_RESIDENT_SUCCESS')
export const UpsertResidentFailureAction = createAction<void>('UPSERT_RESIDENT_FAILURE')

export const DeleteResidentAction = createAction<DeleteResidentPayload>('DELETE_RESIDENT')
export const DeleteResidentSuccessAction = createAction<void>('DELETE_RESIDENT_SUCCESS')
export const DeleteResidentFailureAction = createAction<void>('DELETE_RESIDENT_FAILURE')

export const FilterResidentsAction = createAction<FilterResidentApartmentPayload>('FILTER_USERS')
export const SortResidentsAction = createAction<SortResidentApartmentPayload>('SORT_USERS')

export const ResidentsPaginationPageChangeAction = createAction<number>('RESIDENTS_PAGINATION_PAGE_CHANGE')
export const ResidentsPaginationItemsPerPageChangeAction = createAction<number>('RESIDENTS_PAGINATION_ITEMS_PER_PAGE_CHANGE')


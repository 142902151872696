import { Alert, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { selectNextError } from '../../../redux/error/error-selectors'
import { AcknowledgeNextErrorAction } from '../../../redux/error/error-actions'


const ErrorToast = () => {
    const dispatch = useDispatch();
    const error = useSelector(selectNextError)

    return (
        <Snackbar
            sx={{
                height: "100%",
               textAlign: 'center'
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
            open={!!error}
            onClose={() => {
                dispatch(AcknowledgeNextErrorAction())
            }}>
            <Alert
                onClose={() => { dispatch(AcknowledgeNextErrorAction()) }}
                severity="error"
                sx={{
                    width: '100%',
                    alignItems: 'center',
                    fontSize: 'large',
                    whiteSpace: 'pre-line'
                }}
            >
                <span>{error}</span>
            </Alert>
        </Snackbar >
    )
}

export default ErrorToast

import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { Button } from "../../components/Button"
import { FetchInitReportAction } from "../../redux/reports/reports-actions"

const InitReport = () => {

  const params = useParams()
  const companyId = parseInt(params.companyId ?? "0")

  const dispatch = useDispatch()


  const fetchReport = () => {
    dispatch(FetchInitReportAction({ companyId: companyId }))
  }

  return <>
    <Button onClick={fetchReport}>lataa raportti</Button>
  </>
}

export default InitReport
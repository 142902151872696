import React, { useEffect } from "react";
import { Chip, TextField, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Block } from "../../components/common/block";
import { Button } from "../../components/Button";
import { SavePremiumSettingsAction } from "../../redux/apartments/apartments-actions";
import { colors } from "../../theme";
import { RootState } from "../../interfaces/RootState";
import { PremiumSettings as PremiumSettingsType } from "../../redux/apartments/apartments-types";
import { useButtonRights } from "../../utils/useButtonRights";
import AlarmCustomization from "./alarm-customization/AlarmCustomization";
import SeparatorLine from "../../components/SeparatorLine";
import { Company } from "../../redux/companies/companies-types";


const GrayText = ({ children }: { children: React.ReactNode }) => (
  <span style={{ color: colors.neutral06 }}>{children}</span>
);

export default function PremiumSettings({
  company,
}: {
  company: Company;
}): JSX.Element {
  const dispatch = useDispatch();
  const t = useTranslation().t;


  const existingCustomizations = useSelector((state: RootState) => state.hydrolink.apartments.alarmSettings.alarmCustomizations)

  const existingSettings = useSelector(
    (state: RootState) => state.hydrolink.apartments.premiumSettings
  );
  const [premiumSettings, setPremiumSettings] =
    React.useState<PremiumSettingsType>(existingSettings);
  const [editing, setEditing] = React.useState(false);
  const savePremiumSettingsButtonRights = useButtonRights(company.id, "manager", !editing)

  useEffect(() => {
    setPremiumSettings(existingSettings);
  }, [existingSettings]);

  // Updates premium setting in local state
  const updateLocalSetting = (
    field: keyof PremiumSettingsType,
    value: string
  ) => {
    setEditing(true);
    setPremiumSettings({
      ...premiumSettings,
      [field]: value,
    });
  };

  const savePremiumSettings = () => {
    setEditing(false);
    dispatch(
      SavePremiumSettingsAction({
        settings: premiumSettings,
        companyId: company.id,
      })
    );
  };

  const textWithChipsInputKeyHandler = (
    e: React.KeyboardEvent,
    field: keyof PremiumSettingsType
  ) => {
    if (e.key === "Enter") {
      // Mobile compatible
      let targetElement = e.target as HTMLInputElement;
      updateLocalSetting(
        field,
        addValueToString(premiumSettings[field] as string, targetElement.value)
      );
      targetElement.value = "";
    }
  };

  // Removes a value from a semicolor separated string and cleans it
  const removeValueFromString = (list: string, value: string): string =>
    list
      .replace(value, "") // Erase value from list
      .replace(";;", ";") // Remove double semicolons
      .replace(/^(;)|(;)$/g, ""); // Remove start and end semicolons

    const textWithChipsInput = (target: HTMLInputElement, field: keyof PremiumSettingsType) => {
            let targetElement = target
            updateLocalSetting(field, addValueToString(premiumSettings[field] as string, targetElement.value));
            targetElement.value = ''
    }

    const numericInputKeyHandler = (e: React.ChangeEvent<HTMLInputElement>, field: keyof PremiumSettingsType) => {
        // This regex makes sure that only 1 comma and numbers are allowed
        let newVal = e.currentTarget.value.match(/[0-9]+(,)?([0-9]+)?/i)?.at(0) ?? "0"

        // Remove unnecessary 0 from the start
        if (newVal.at(0) === "0" && newVal.length > 1) {
            newVal = newVal.substring(1)
        }

        if (e.currentTarget.value.length <= 10 ) {
            updateLocalSetting(field, newVal)
        }
    }

  // This can't be reduced to it's own <Section /> component because text fields lose focus after every input
  const sectionStyling = (bordered: boolean = false): React.CSSProperties => {
    return {
      display: "grid",
      paddingLeft: "28px",
      justifyContent: "space-between",
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: "1rem",
      border: bordered ? `2px solid ${colors.neutral04}` : "",
      borderRadius: "8px",
      marginBottom: bordered ? "2.5em" : "",
      marginTop: !bordered ? "1.5em" : "6px",
    };
  };

    // Adds a value to a string. Also adds `;` if there are other values already in the list
    const addValueToString = (list: string, value: string): string => {
        if (value.trim() !== "") {
            list += (list.length == 0 || list.endsWith(';')) ? value : `;${value}`
        }
        return list
    }

  // Props for the 2 numeric inputs (same reason as above as to why all this wasn't put into it's own component)
  const numericInputProps = (): TextFieldProps => {
    return {
      size: "small",
      type: "number",
      variant: "outlined",
      inputMode: "decimal",
      inputProps: {
        min: "0",
        step: "0.01",
        style: {
          padding: "4px 8px",
        },
      },
      style: {
        width: "18em",
        margin: "12px 0",
      },
    };
  };

  // Props for the 2 textfield inputs (^^^)
  const textInputProps = (): TextFieldProps => {
    return {
      size: "small",
      variant: "outlined",
      inputProps: {
        style: {
          padding: "4px 8px",
        },
      },
      sx: {
        ".MuiInputBase-root": {
          display: "block",
          padding: "2px 10px",
        },
      },
      style: {
        width: "28em",
        margin: "12px 0",
      },
    };
  };

  const getChips = (field: keyof PremiumSettingsType): React.ReactNode =>
    (premiumSettings[field] as string)
      .split(";")
      .filter((value) => !!value)
      .map((value) => (
        <Chip
          size="small"
          key={value}
          label={value}
          onDelete={() =>
            updateLocalSetting(
              field,
              removeValueFromString(premiumSettings[field] as string, value)
            )
          }
        />
      ));

    return <>
        <Block variant='white' style={{ marginTop: '20px' }}>
            {t('apartments.premiumSettings.alarmSettings')}
            <div style={sectionStyling()}>
                <GrayText>{t('apartments.premiumSettings.emailRecipients')}</GrayText>
                <GrayText>{t('apartments.premiumSettings.phoneNumbers')}</GrayText>
            </div>
            <div style={sectionStyling(true)}>
                <p>{t('apartments.premiumSettings.emailRecipientsMoved')}</p>
                <TextField
                 onBlur={(e) =>  textWithChipsInput(e.target as HTMLInputElement, 'phoneNumbers')}
                    {...textInputProps()}
                    InputProps={{
                        startAdornment: getChips('phoneNumbers')
                    }}
                    onKeyUp={(e) => textWithChipsInputKeyHandler(e, 'phoneNumbers')}
                />
            </div>
            {t('apartments.premiumSettings.priceSettings')}
            <div style={sectionStyling()}>
                <GrayText>{t('apartments.premiumSettings.warmWaterPrice')}</GrayText>
                <GrayText>{t('apartments.premiumSettings.coldWaterPrice')}</GrayText>
            </div>
            <div style={sectionStyling(true)}>
                <TextField
                    {...numericInputProps()}
                    value={premiumSettings.warmWaterPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        numericInputKeyHandler(e, 'warmWaterPrice')
                    }
                />
                <TextField
                    {...numericInputProps()}
                    value={premiumSettings.coldWaterPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        numericInputKeyHandler(e, 'coldWaterPrice')
                    }
                />
            </div>
            <div style={{ marginTop: '28px', display: 'flex', justifyContent: 'right' }}>
                <Button
                    onClick={savePremiumSettings}
                    disabled={savePremiumSettingsButtonRights}
                >
                    {t('generic.save')}
                </Button>
            </div>
               {/*TODO get rid of prop drilling and move components to some central place?*/}
               <SeparatorLine />
                    <AlarmCustomization
                      company={company}
                      alarmCustomizations={existingCustomizations}
                    />
        </Block>

    </>

}


import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components/Button";
import { Block } from "../../components/common/block";
import { RootState } from "../../interfaces/RootState";
import { CloseSaveCompanyBasicInfoModalAction, OpenSaveCompanyBasicInfoModalAction } from "../../redux/apartments/apartments-actions";
import { Company } from "../../redux/companies/companies-types";
import { BasicInfoEditModal } from "./BasicInfoEditModal";


export default function BasicCompanyInfo({ company }: { company: Company }) {
  const t = useTranslation().t
  const dispatch = useDispatch()
  const modalOpen = useSelector((state: RootState) => state.hydrolink.apartments.saveCompanyBasicInfoModalOpen)

  return <>
    <BasicInfoEditModal isOpen={modalOpen} company={company} close={() => { dispatch(CloseSaveCompanyBasicInfoModalAction()) }} />
    <Block variant={"white"} >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('companies.basicInfo')}
        <Button variant='outlined' onClick={() => dispatch(OpenSaveCompanyBasicInfoModalAction())}>
          {t('generic.edit')}
        </Button>
      </div>
    </Block></>
}
import { TextField } from "@mui/material";
import React from "react";
import { Client } from "../../redux/apartments/apartments-types";
import { TableCell, TableRow } from "../../components/Table";


export type Props = {
  client: Client,
  onNumberChanged: (id: number, e: string) => void,
  telephoneNumber: string | null
};

const SimCardNumber = (props: Props) => {
  const onNumberChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onNumberChanged(props.client.id, e.target.value)
  };

  return (
    <TableRow >
      <TableCell>
        <span style={{ marginRight: "1em" }}>
          {props.client.code}
        </span>
      </TableCell>
      <TableCell>
        <TextField value={props.telephoneNumber} onChange={onNumberChanged} />
      </TableCell>
    </TableRow>
  )
};

export default SimCardNumber;
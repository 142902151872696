import { IconButton } from '@mui/material';
import { CustomTooltip } from '../../../components/CustomTooltip';
import { AlarmDropdownProps } from './AlarmDropdown';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';

const ResetToDefaults =  ({custom, alarmType, company, onChange}: AlarmDropdownProps) => {

  const disableReset = (custom.alarmInterval === null && custom.highPriority === null && custom.notifyOnNew === null)
  const t = useTranslation().t;

  const resettedAlarms = {
    alarmType: alarmType.alarmName,
    highPriority: null,
    notifyOnNew: null,
    alarmInterval: null,
    companyId: company.id
  }
  

  return (
    <CustomTooltip
      title={t('alarms.resetToDefaults') || ''}
      arrow
    >
      <span>
        <IconButton
          style={{ margin: '0em', marginTop: '0em' }}
          disabled={disableReset}
          onClick={ () => onChange(resettedAlarms, company.id)}
        >
          <DeleteIcon />
        </IconButton >
      </span>
    </CustomTooltip>
  )
}


export default ResetToDefaults
import { createAction } from "@reduxjs/toolkit"
import {
    DeleteNotePayLoad,
    FetchNotesPayLoad,
    FetchNotesSuccessPayLoad,
    FilterNotesPayload,
    InsertNotePayLoad,
    SortNotesPayload
} from "./notes-types"

export const FetchNotesAction = createAction<FetchNotesPayLoad>('FETCH_NOTES')
export const FetchNotesSuccessAction = createAction<FetchNotesSuccessPayLoad>('FETCH_NOTES_SUCCESS')
export const FetchNotesFailureAction = createAction<void>('FETCH_NOTES_FAILURE')

export const DeleteNoteAction = createAction<DeleteNotePayLoad>('DELETE_NOTE')
export const DeleteNoteSuccessAction = createAction<void>('DELETE_NOTE_SUCCESS')
export const DeleteNoteFailureAction = createAction<void>('DELETE_NOTE_FAILURE')

export const InsertNoteAction = createAction<InsertNotePayLoad>('INSERT_NOTES')
export const InsertNoteSuccessAction = createAction<void>('INSERT_NOTES_SUCCESS')
export const InsertNoteFailureAction = createAction<void>('INSERT_NOTES_FAILURE')

export const FilterNotesAction = createAction<FilterNotesPayload>('FILTER_NOTES')
export const SortNotesAction = createAction<SortNotesPayload>('SORT_NOTES')
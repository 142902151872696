import { all, put, takeLatest } from "redux-saga/effects"
import * as api from "../../api"
import { ReportErrorAction } from "../error/error-actions"
import {
    DeleteResidentAction,
    FetchResidentsAction,
    FetchResidentsUnauthorizedAction,
    FetchResidentsSuccessAction,
    UpsertResidentAction,
    UpsertResidentSuccessAction,
    DeleteResidentSuccessAction,
    FetchResidentsFailureAction,
    UpsertResidentFailureAction,
    DeleteResidentFailureAction
} from "./residents-actions"

import {
    DeleteResidentPayload,
    FetchResidentsPayload,
    FetchResidentsSuccessPayload,
    UpsertResidentPayload,
    UpsertResidentSuccessPayload
} from "./residents-types"

export function* fetchResidents(action: { type: string, payload: FetchResidentsPayload }) {
    yield api.get(
        `/companies/${action.payload.companyId}/residents`,
        (resp: FetchResidentsSuccessPayload) => put(FetchResidentsSuccessAction(resp)),
        (err, code) => {
            put(FetchResidentsFailureAction())
            if (code === 403) {
                return put(FetchResidentsUnauthorizedAction(err))
            } else {
                return put(ReportErrorAction(err))
            }
        })

}

export function* upsertResident(action: { type: string, payload: UpsertResidentPayload }) {
  yield api.put(
    `/companies/${action.payload.companyId}/residents`,
    { toUpsertByApartmentId: action.payload.toUpsertByApartmentId },
    (res: UpsertResidentSuccessPayload) => all([
      put(UpsertResidentSuccessAction(res)),
      put(FetchResidentsAction({ companyId: action.payload.companyId }))]),
    err => all([
      put(UpsertResidentFailureAction()),
      put(ReportErrorAction(err))
    ]))
}


export function* deleteResident(action: { payload: DeleteResidentPayload }) {
    yield api.remove(
        `/companies/${action.payload.companyId}/residents/${action.payload.residentId}`,
        {},
        () => all([
            put(DeleteResidentSuccessAction()),
            put(FetchResidentsAction({ companyId: action.payload.companyId }))
        ]),
        err => all([
          put(DeleteResidentFailureAction()),
          put(ReportErrorAction(err))
        ]))
}

export function* residentsSaga() {
    yield takeLatest(FetchResidentsAction, fetchResidents)
    yield takeLatest(UpsertResidentAction, upsertResident)
    yield takeLatest(DeleteResidentAction, deleteResident)
}

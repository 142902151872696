import { AnyAction } from "redux";
import {
    FetchClientCommandsAction,
    FetchClientCommandsFailureAction,
    FetchClientCommandsSuccessAction,
    SendClientCommandAction,
    SendClientCommandFailureAction,
    SendClientCommandSuccessAction
} from "./centers-actions";
import { CentersState } from "./centers-types";

const initialState: CentersState = {
    clientCommands: [],
    loading: false
}

export default function centersReducer(state: CentersState = initialState, action: AnyAction): CentersState {

    //ACTION
    if (FetchClientCommandsAction.match(action)) {
        return {
            ...state,
            loading: true
        }
    }

    if (SendClientCommandAction.match(action)) {
        return {
            ...state,
            loading: true
        }
    }

    //SUCCESS
    if (FetchClientCommandsSuccessAction.match(action)) {
        return {
            ...state,
            clientCommands: action.payload,
            loading: false
        }
    }

    if (SendClientCommandSuccessAction.match(action)) {
        return {
            ...state,
            loading: false
        }
    }

    //FAILURE
    if (FetchClientCommandsFailureAction.match(action)) {
        return {
            ...state,
            loading: false
        }
    }

    if (SendClientCommandFailureAction.match(action)) {
        return {
            ...state,
            loading: false
        }
    }

    return state
}

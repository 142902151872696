import { AnyAction } from "redux";
import { Company, Manager, Role } from "../companies/companies-types";
import {
    UpdateUserAdminAction,
    UpdateUserAdminSuccessAction,
    UpdateUserSuccessAction,
    FetchUsersAction,
    FetchUsersSuccessAction,
    UpdateUserAction,
    OpenCreateUserModalAction,
    CloseCreateUserModalAction,
    DeleteUserSuccessAction,
    UsersPaginationPageChangeAction,
    UsersPaginationItemsPerPageChangeAction,
    DeleteUserAction,
    CreateUserAction,
    CreateUserSuccessAction,
    CreateNewUserPasswordAction,
    CreateNewUserPasswordSuccessAction,
    UpdateUserEmailAction,
    UpdateUserEmailSuccessAction,
    FetchUsersFailureAction,
    UpdateUserFailureAction,
    UpdateUserAdminFailureAction,
    CreateUserFailureAction,
    CreateNewUserPasswordFailureAction,
    UpdateUserEmailFailureAction,
    DeleteUserFailureAction,
    SortUsersAction,
    FilterUsersAction
} from "./users-actions";
import { User, UsersState } from "./users-types";

const initialState: UsersState = {
    createUserModalOpen: false,
    users: [],
    filteredAndSortedUsers: [],
    pagination: {
        currentPage: 1,
        itemsPerPage: 10
    },
    loading: false,
    sortBy: 'userFullName',
    ascending: true
}

export const getCompanyManagerByUser = (company: Company, user: User): Manager | undefined =>
    company.managers.find((i: Manager) => i.userId === user.id)

// Returns the highest role for user out of a list of companies where the user is
export const getHighestRoleForUser = (companies: Company[], user: User): Role => {
    let roles = companies.map((c: Company) => getCompanyManagerByUser(c, user)?.role).filter((i) => i !== undefined) as Role[]
    return roles.sort()[0]
}

const loadingTrue = (state: UsersState) => ({ ...state, loading: true })
const loadingFalse = (state: UsersState) => ({ ...state, loading: false })

const actions = (state: UsersState, action: AnyAction) => {
    if (FetchUsersAction.match(action)) {
        loadingTrue(state)
    }

    if (UpdateUserAction.match(action)) {
        loadingTrue(state)
    }

    if (UpdateUserAdminAction.match(action)) {
        loadingTrue(state)
    }

    if (CreateUserAction.match(action)) {
        loadingTrue(state)
    }

    if (CreateNewUserPasswordAction.match(action)) {
        loadingTrue(state)
    }

    if (UpdateUserEmailAction.match(action)) {
        loadingTrue(state)
    }

    if (DeleteUserAction.match(action)) {
        loadingTrue(state)
    }
    return state
}


const successActions = (state: UsersState, action: AnyAction) => {

    if (FetchUsersSuccessAction.match(action)) {
        action.payload.sort((a, b) => ((a.firstName + a.lastName).localeCompare((b.firstName + b.lastName))))
        return {
            ...state,
            users: action.payload,
            loading: false
        }
    }

    if (UpdateUserSuccessAction.match(action)) {
        loadingFalse(state)
    }

    if (UpdateUserAdminSuccessAction.match(action)) {
        loadingFalse(state)
    }

    if (CreateUserSuccessAction.match(action)) {
        loadingFalse(state)
    }

    if (CreateNewUserPasswordSuccessAction.match(action)) {
        loadingFalse(state)
    }

    if (UpdateUserEmailSuccessAction.match(action)) {
        loadingFalse(state)
    }

    if (DeleteUserSuccessAction.match(action)) {
        loadingFalse(state)
    }
    return state
}


const failureActions = (state: UsersState, action: AnyAction) => {
    if (FetchUsersFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (UpdateUserFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (UpdateUserAdminFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (CreateUserFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (CreateNewUserPasswordFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (UpdateUserEmailFailureAction.match(action)) {
        loadingFalse(state)
    }

    if (DeleteUserFailureAction.match(action)) {
        loadingFalse(state)
    }
    return state
}

const otherActions = (state: UsersState, action: AnyAction) => {
    if (SortUsersAction.match(action)) {
        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
            filteredAndSortedUsers: action.payload.users
        }
    }

    if (FilterUsersAction.match(action)) {
        return {
            ...state,
            filteredAndSortedUsers: action.payload
        }
    }

    if (OpenCreateUserModalAction.match(action)) {
        return {
            ...state,
            createUserModalOpen: true
        }
    }

    if (CloseCreateUserModalAction.match(action)) {
        return {
            ...state,
            createUserModalOpen: false
        }
    }

    if (UsersPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload
            }
        }
    }

    if (UsersPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload
            }
        }
    }
    return state
}

export default function usersReducer(state: UsersState = initialState, action: AnyAction): UsersState {

    state = actions(state, action)
    state = successActions(state, action)
    state = failureActions(state, action)
    state = otherActions(state, action)

    return state
}

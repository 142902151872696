import {  Tooltip } from '@mui/material';
import { Meter, MeterHistoryType, MeterType } from '../../../redux/apartments/apartments-types';
import DoneIcon from "@mui/icons-material/Done";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation, faTrash, faChartColumn, faWrench } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteMeterAction, OpenChartAction } from '../../../redux/apartments/apartments-actions';
import { displayAlarms, displayDateTime } from '../../../utils/utils';
import { colors } from '../../../theme';
import MeterProperties from './MeterProperties';
import { TableCell, TableRow } from '../../../components/Table';
import React from 'react';
import { RootState } from '../../../interfaces/RootState';
import FontAwesomeIconButton from '../../../components/FontAwesomeIconButton';
import { useButtonRights } from '../../../utils/useButtonRights';

type Props = {
    meter: Meter,
    translatedMeterType: string,
    apartmentId: number,
    companyId: number,
    displayChartSymbol: boolean
}

const MeterTableRow = ({ meter, translatedMeterType, apartmentId, companyId, displayChartSymbol}: Props): JSX.Element => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
    const [propertiesOpen, setPropertiesOpen] = React.useState<boolean>(false)
    const propertiesOpenButtonRights = useButtonRights(companyId, "admin")
    const deleteMeterButtonRights = useButtonRights(companyId, "admin")
    const openChartButtonRights = useButtonRights(companyId, "manager")

    const handleOpenChartModal = (meterType: MeterHistoryType) => {
        dispatch(OpenChartAction({ apartmentId: apartmentId, type: 'month', offset: 0, meterType: meterType }))
    }

    const getIcon = (meterType: MeterHistoryType | null) => {
        if (meterType === null) {
            return null
        }
        return <FontAwesomeIconButton disabled={openChartButtonRights} style={{float: 'none'}}  onClick={() => handleOpenChartModal(meterType)} icon={faChartColumn} />
    }

    const getMeterHistoryType = (meterType: MeterType): MeterHistoryType | null => {
        if (['water', 'energy', 'temperature'].indexOf(meterType) > -1) {
            return meterType as MeterHistoryType
        }
        return null
    }

    const deleteMeter = (meter: Meter) => {
        if (window.confirm(`${t("apartments.deleteMeterConfirmation")} "${meter.id}"?`)) {
            dispatch(DeleteMeterAction({ companyId: companyId, id: meter.id, type: meter.type }))
        }
    }

    return (<><TableRow style={{ backgroundColor: colors.neutral00 }}>
        <TableCell>
            {!displayChartSymbol
                ? <>
                    {getIcon(getMeterHistoryType(meter.type))}
                </> : ""}
        </TableCell>
        <TableCell>
            {meter.code}
        </TableCell>
        <TableCell>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {displayAlarms(meter.activeAlarmIds, isAdmin).length === 0
                    ?
                    <Tooltip title={t('meters.alarmStatus.ok') ?? ""}  >
                        <DoneIcon className='text-green' />
                    </Tooltip> :
                    <>
                        {displayAlarms(meter.activeAlarmIds, isAdmin).map(a =>
                            <Tooltip key={meter.id + a} title={t(`alarms.descriptions.${a}`) ?? ""}  >
                                <div>
                                    <FontAwesomeIcon color={colors.warm06} icon={faTriangleExclamation} />  &nbsp; {t(`alarms.${a}`) + " "}
                                </div>
                            </Tooltip>
                        )}
                    </>}
            </div>
        </TableCell>
        <TableCell>
            {translatedMeterType}
        </TableCell>
        <TableCell>
            {meter.meterResetAt !== null ? displayDateTime(new Date(meter.meterResetAt)) : "-"}
        </TableCell>
        <TableCell>
            {(meter.updated !== null && meter.updated !== 0) ? displayDateTime(new Date(meter.updated)) : "-"}
        </TableCell>
        <TableCell>
            {meter.reading ?? "-"}
        </TableCell>
        <TableCell>
            <FontAwesomeIconButton disabled={propertiesOpenButtonRights} style={propertiesOpen ? {transform: 'rotate(-180deg)'} :{} } onClick={() => setPropertiesOpen(!propertiesOpen)} icon={faWrench} />
        </TableCell>
        <TableCell>
            <FontAwesomeIconButton disabled={deleteMeterButtonRights}  onClick={() => deleteMeter(meter)} icon={faTrash} />
        </TableCell>
    </TableRow>{propertiesOpen &&
        <TableRow >
            <TableCell colSpan={11}>
                <MeterProperties companyId={companyId} apartmentId={apartmentId} meter={meter} deleteMeter={() => deleteMeter(meter)} />
            </TableCell>
        </TableRow>}
    </>
    )
}

export default MeterTableRow
import { all, put, takeLatest } from "redux-saga/effects"
import * as api from "../../api"
import { convertApiApartment, convertApiPremiumSettings, convertApiReportSettings } from "../../api/api-conversions";
import { ApiAlarmSettings, ApiApartment, ApiPremiumSettings, ApiReportFormat, ApiReportSettings } from "../../api/api-types";
import { ReportErrorAction } from "../error/error-actions";
import {
  UploadCSVFailureAction,
  UploadCSVSuccessAction,
  DeleteMeterAction,
  DeleteMeterFailureAction,
  DeleteMeterSuccessAction,
  FetchApartmentsSuccessAction,
  UpdateClientTelephoneNumberAction,
  UpdateClientTelephoneNumberFailureAction,
  UpdateClientTelephoneNumberSuccessAction,
  FetchApartmentsAction,
  OpenChartAction,
  FetchGraphsSuccessAction,
  DeleteApartmentAction,
  DeleteApartmentSuccessAction,
  DeleteApartmentFailureAction,
  UploadCSVAction,
  FetchPremiumSettingsAction,
  FetchPremiumSettingsSuccessAction,
  FetchPremiumSettingsFailureAction,
  SavePremiumSettingsAction,
  SavePremiumSettingsSuccessAction,
  SavePremiumSettingsFailureAction,
  FetchAlarmSettingsAction,
  FetchAlarmSettingsSuccessAction,
  FetchAlarmSettingsFailureAction,
  SaveAlarmSettingsSuccessAction,
  SaveAlarmSettingsFailureAction,
  SaveAlarmSettingsAction,
  FetchReportSettingsSuccessAction,
  FetchReportSettingsFailureAction,
  FetchReportSettingsAction,
  SaveReportSettingsAction,
  SaveReportSettingsSuccessAction,
  SaveReportSettingsFailureAction,
  FetchReportFormatsSuccessAction,
  FetchReportFormatsAction,
  FetchApartmentsFailureAction,
  FetchGraphsFailureAction,
  FetchReportFormatsFailureAction,
  ChangeMeterAction,
  ChangeMeterSuccessAction,
  UpdateApartmentNameAction,
  UpdateApartmentNameSuccessAction,
  UpdateApartmentNameFailureAction,
  SaveAlarmCustomizationFailureAction,
  SaveAlarmCustomizationSuccessAction,
  SaveAlarmCustomizationAction,
  ChangeMeterFailureAction,
  HydrodigitLRAlarmResetAction,
  HydrodigitLRAlarmResetSuccessAction,
  HydrodigitLRAlarmResetFailureAction
} from "./apartments-actions";
import {
  UploadCSVPayload,
  DeleteMeterPayload,
  FetchApartmentsPayload,
  FetchApartmentsSuccessPayload,
  UpdateClientTelephoneNumberPayload,
  FetchGraphsPayload,
  FetchGraphsSuccessPayload,
  DeleteApartmentPayload,
  FetchPremiumSettingsPayload,
  SavePremiumSettingsPayload,
  PremiumSettings,
  FetchAlarmSettingsPayload,
  AlarmSettings,
  SaveAlarmSettingsPayload,
  FetchReportSettingsPayload,
  ReportSettings,
  SaveReportSettingsPayload,
  ReportFormatsPayload,
  ChangeMeterPayload,
  UpdateApartmentNamePayload,
  SaveAlarmCustomizationPayload,
  HydrodigitLRAlarmResetPayload,
} from "./apartments-types";

export function* fetchApartments(action: { type: string, payload: FetchApartmentsPayload }) {

  yield api.get(
    `/companies/${action.payload.companyId}/apartments`,
    (resp: ApiApartment[]) => put(FetchApartmentsSuccessAction(resp.map(convertApiApartment))),
    err => all([
      put(FetchApartmentsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchGraphs(action: { payload: FetchGraphsPayload }) {
  let getReport = 'usage_report'
  if (action.payload.meterType === 'temperature') {
    getReport = 'report'
  }
  yield api.get(
    `/apartments/${action.payload.apartmentId}/${action.payload.meterType}_${getReport}?type=${action.payload.type}&offset=${action.payload.offset}`,
    (resp: FetchGraphsSuccessPayload) => put(FetchGraphsSuccessAction(resp)),
    err => all([
      put(FetchGraphsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchAlarmSettings(action: { payload: FetchAlarmSettingsPayload }) {

  yield api.get(
    `/companies/${action.payload.companyId}/alarm_settings`,
    (res: ApiAlarmSettings) => put(FetchAlarmSettingsSuccessAction(res)),
    err => all([
      put(FetchAlarmSettingsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchPremiumSettings(action: { payload: FetchPremiumSettingsPayload }) {
  yield api.get(
    `/companies/${action.payload.companyId}/premium_settings`,
    (res: ApiPremiumSettings) => put(FetchPremiumSettingsSuccessAction(convertApiPremiumSettings(res))),
    err => all([
      put(FetchPremiumSettingsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchReportSettings(action: { payload: FetchReportSettingsPayload }) {
  yield api.get(
    `/companies/${action.payload.companyId}/report_settings`,
    (res: ApiReportSettings) => put(FetchReportSettingsSuccessAction(convertApiReportSettings(res))),
    err => all([
      put(FetchReportSettingsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchReportFormats() {
  yield api.get(
    `/report_formats`,
    (res: ApiReportFormat[]) => put(FetchReportFormatsSuccessAction(res)),
    err => all([
      put(FetchReportFormatsFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* uploadCSV(action: { type: string, payload: UploadCSVPayload }) {

  yield api.post(
    `/companies/${action.payload.companyId}/apartments/csv`,
    { file: action.payload.file },
    () => all([
      put(UploadCSVSuccessAction()),
      put(FetchApartmentsAction({ companyId: action.payload.companyId }))
    ]),
    err => all([
      put(UploadCSVFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* updateClientTelephoneNumber(action: { payload: UpdateClientTelephoneNumberPayload }) {

  yield api.put(
    `/clients/${action.payload.clientId}/telephone_number`,
    { newTelephoneNumber: action.payload.newTelephoneNumber },
    () => put(UpdateClientTelephoneNumberSuccessAction(action.payload)),
    err => all([
      put(UpdateClientTelephoneNumberFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* deleteMeter(action: { payload: DeleteMeterPayload }) {
  const companyId: number = action.payload.companyId!!
  delete action.payload.companyId
  yield api.remove(
    `/meter`, action.payload,
    () => all([
      put(DeleteMeterSuccessAction()),
      put(FetchApartmentsAction({ companyId: companyId }))
    ]),
    err => all([
      put(DeleteMeterFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* deleteApartment(action: { payload: DeleteApartmentPayload }) {

  const companyId: number = action.payload.companyId
  yield api.remove(
    `companies/${companyId}/apartments/${action.payload.id}`, action.payload,
    () => all([
      put(DeleteApartmentSuccessAction()),
      put(FetchApartmentsAction({ companyId: companyId }))
    ]),
    err => all([
      put(DeleteApartmentFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* savePremiumSettings(action: { payload: SavePremiumSettingsPayload }) {
  yield api.put(
    `/companies/${action.payload.companyId}/premium_settings`,
    action.payload.settings,
    () => all([
      put(SavePremiumSettingsSuccessAction()),
      put(FetchPremiumSettingsAction({ companyId: action.payload.companyId }))
    ]),
    err => all([
      put(SavePremiumSettingsFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* saveAlarmSettings(action: { payload: SaveAlarmSettingsPayload }) {

  const alarmSettings = {
    alarmLang: action.payload.alarmSettings.alarmLang,
    stuckEmail: action.payload.alarmSettings.stuckEmail
  }

  yield api.put(
    `/companies/${action.payload.companyId}/alarm_settings`,
    alarmSettings,
    () => all([
      put(SaveAlarmSettingsSuccessAction()),
      put(FetchAlarmSettingsAction({ companyId: action.payload.companyId }))]),
    err => all([
      put(SaveAlarmSettingsFailureAction()),
      put(ReportErrorAction(err))
    ]))
}


export function* saveAlarmCustomization(action: { payload: SaveAlarmCustomizationPayload }) {



  const alarmCustomization = {
    alarmType: action.payload.alarmCustomization.alarmType,
    highPriority: action.payload.alarmCustomization.highPriority,
    notifyOnNew: action.payload.alarmCustomization.notifyOnNew,
    alarmInterval: action.payload.alarmCustomization.alarmInterval,
  }

  yield api.post(
    `/companies/${action.payload.companyId}/alarm_customization`,
      alarmCustomization,
    () => all([
      put(SaveAlarmCustomizationSuccessAction(action.payload.alarmCustomization)),
      put(FetchAlarmSettingsAction({ companyId: action.payload.companyId }))
    ]),
    err => all([
      put(SaveAlarmCustomizationFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}


export function* saveReportSettings(action: { payload: SaveReportSettingsPayload }) {

  delete action.payload.reportSettings.reportLastSent // Make sure this isn't sent because sending this will cause errors in the backend
  yield api.put(
    `/companies/${action.payload.companyId}/report_settings`,
    action.payload.reportSettings,
    () => all([
      put(SaveReportSettingsSuccessAction()),
      put(FetchReportSettingsAction({ companyId: action.payload.companyId }))]),
    err => all([
      put(SaveReportSettingsFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* changeMeter(action: { payload: ChangeMeterPayload }) {

  yield api.post(
    `/meter/${action.payload.meterId}/mark_as_changed`,
    { apartmentId: action.payload.apartmentId,
      date: action.payload.date,
      valueBefore:  action.payload.valueBefore,
      valueAfter: action.payload.valueAfter
     },
    () => all([put(ChangeMeterSuccessAction()),
    put(FetchApartmentsAction({ companyId: action.payload.companyId }))]),
    err => all([put(ChangeMeterFailureAction()),
    put(ReportErrorAction(err))
    ]))
}

export function* updateApartmentName(action: { payload: UpdateApartmentNamePayload }) {

  yield api.put(
    `/apartments/${action.payload.apartmentId}/name`,
    { apartmentCode: action.payload.name },
    () => all([
      put(UpdateApartmentNameSuccessAction()),
      put(FetchApartmentsAction({ companyId: action.payload.companyId }))]),
    err => all([
      put(UpdateApartmentNameFailureAction()),
      put(ReportErrorAction(err))
    ]))
}

export function* hydrodigitLRAlarmReset(action: { payload: HydrodigitLRAlarmResetPayload }) {

  yield api.post(
    `/meter/${action.payload.meterId}/alarm_reset`,
    {},
    () => put(HydrodigitLRAlarmResetSuccessAction()),
    err => all([put(HydrodigitLRAlarmResetFailureAction()),
    put(ReportErrorAction(err))
    ])
  )
}

export function* apartmentsSaga() {
  yield takeLatest(FetchApartmentsAction, fetchApartments)
  yield takeLatest(UploadCSVAction, uploadCSV)
  yield takeLatest(UpdateClientTelephoneNumberAction, updateClientTelephoneNumber)
  yield takeLatest(DeleteMeterAction, deleteMeter)
  yield takeLatest(OpenChartAction, fetchGraphs)
  yield takeLatest(DeleteApartmentAction, deleteApartment)
  yield takeLatest(FetchPremiumSettingsAction, fetchPremiumSettings)
  yield takeLatest(SavePremiumSettingsAction, savePremiumSettings)
  yield takeLatest(FetchAlarmSettingsAction, fetchAlarmSettings)
  yield takeLatest(SaveAlarmSettingsAction, saveAlarmSettings)
  yield takeLatest(FetchReportSettingsAction, fetchReportSettings)
  yield takeLatest(SaveReportSettingsAction, saveReportSettings)
  yield takeLatest(SaveAlarmCustomizationAction, saveAlarmCustomization)
  yield takeLatest(FetchReportFormatsAction, fetchReportFormats)
  yield takeLatest(UpdateApartmentNameAction, updateApartmentName)
  yield takeLatest(HydrodigitLRAlarmResetAction, hydrodigitLRAlarmReset)
  yield takeLatest(ChangeMeterAction, changeMeter)
}

import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow } from '../../../components/Table';


export default function MetersTableHead() {
  const t = useTranslation().t
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {t('apartments.meters')}
        </TableCell>
        <TableCell>
          {t('apartments.code')}
        </TableCell>
        <TableCell>
          {t('apartments.alarm')}
        </TableCell>
        <TableCell>
          {t('apartments.type')}
        </TableCell>
        <TableCell>
          {t('apartments.meterResetAt')}
        </TableCell>
        <TableCell>
          {t('apartments.meterUpdated')}
        </TableCell>
        <TableCell>
          {t('apartments.meterReading')}
        </TableCell>
      </TableRow>
    </TableHead>
  )
}


import { List, ListItem, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteNoteAction, FetchNotesAction, SortNotesAction } from '../../redux/notes/notes-actions';
import { RootState } from '../../interfaces/RootState';
import { Note } from '../../redux/notes/notes-types';
import { displayDateTime } from '../../utils/utils';
import Sorter from '../../components/Sorter';
import { table } from '../../theme';
import FontAwesomeIconButton from '../../components/FontAwesomeIconButton';


const ViewNotes = ({ companyId, }: { companyId: number }) => {

    const t = useTranslation().t
    const dispatch = useDispatch()
    const notes = useSelector((state: RootState) => state.hydrolink.notes.filteredAndSortedNotes)
    const currentSorting = useSelector((state: RootState) => state.hydrolink.notes.sortBy)
    const currentAscending = useSelector((state: RootState) => state.hydrolink.notes.ascending)

    useEffect(() => {
        dispatch(FetchNotesAction({ companyId: companyId }));
    }, [companyId])

    const deleteNote = (id: number) => {
        if (window.confirm(`${t("notes.deleteNoteConfirmation")}?`)) {
            dispatch(DeleteNoteAction({ id: id, companyId: companyId }))
        }
    }

    const sortNotesFunction = (sortNotes: Note[], sortBy: string, ascending: boolean) => {
        dispatch(SortNotesAction({ notes: sortNotes, sortBy: sortBy, ascending: ascending }))
    }

    const tableHead = () => {
        return <TableHead>
            <TableRow>
                <Sorter
                    sortKey={"date"}
                    items={notes}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.date")}
                    sortData={(note: Note) => (note.created)}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"comment"}
                    items={notes}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.comment")}
                    width={table.tableCellWidthLonger}
                    sortData={(note: Note) => (note.comment)}
                    currentAscending={currentAscending}
                />
                <Sorter
                    sortKey={"creator"}
                    items={notes}
                    sortAction={sortNotesFunction}
                    sortBy={currentSorting}
                    title={t("notes.creator")}
                    sortData={(note: Note) => (note.creator)}
                    currentAscending={currentAscending}
                />
                <TableCell>{/* Delete button column */}</TableCell>
            </TableRow>
        </TableHead>

    }

    const createDisplayComment = (n: Note) =>
        n.comment.split('\n').map((line: string, i) =>
            <ListItem key={n.id + n.comment + i} style={{ padding: 0 }}>{line}</ListItem>)

    const tableRow = (n: Note) => {
        return <TableRow key={n.id}>
            <TableCell>
                {displayDateTime(new Date(n.created))}
            </TableCell>
            <TableCell>
                <List style={{ padding: 0 }}>
                    {createDisplayComment(n)}
                </List>
            </TableCell>
            <TableCell>
                {n.creator}
            </TableCell>
            <TableCell>
                <FontAwesomeIconButton onClick={() => deleteNote(n.id)} icon={faTrash} />
            </TableCell>
        </TableRow>
    }

    return <TableContainer component={Paper}>
        <Table>
            {tableHead()}
            <TableBody>
                {notes.map(n => tableRow(n))}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ViewNotes
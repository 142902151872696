import React from 'react';
import { TableRow, TableCell, FormControlLabel, Switch } from '@mui/material';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { spacings } from '../../theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../interfaces/RootState';
import { Role, Manager } from '../../redux/companies/companies-types';
import FontAwesomeIconButton from '../../components/FontAwesomeIconButton';

interface Props {
    firstName: string,
    lastName: string,
    manager: Manager,
    delete: (managerId: number) => void,
    changeRole: (id: number, role: Role) => void,
    setPrincipal: (id: number, enable: boolean) => void,
    managerRole: (selectedValue: Role, onchange: (value: Role) => void) => JSX.Element,
    color: string,
    myRole: Role,
}

const ManagerRow = (props: Props) => {
  const { t } = useTranslation()
  const [role, setRole] = React.useState<Role>(props.manager.role)
  const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)

  const changeRole = (value: Role) => {
    setRole(value)
    props.changeRole(props.manager.userId, value)
  }

  const setPrincipal = (e: any) => {
    props.setPrincipal(props.manager.userId, e.target.checked)
  }

  const getManagerControls = (): React.ReactNode => {
    if (!isAdmin) {
      return <>{props.manager.principal ? t(`roles.principal`) : t(`roles.${props.manager.role}`)}</>
    }
    return <>
      {props.managerRole(role, changeRole)}
      <FormControlLabel
        style={{ marginLeft: spacings.inlineSpacing, display: props.manager.role === 'manager' ? '' : 'none' }}
        control={<Switch checked={props.manager.principal === true} onChange={setPrincipal} />}
        label={String(t('roles.principal'))}
      />
    </>
  }

  return (
    <TableRow style={{ backgroundColor: props.color }}>
      <TableCell>
        {props.firstName}
      </TableCell>
      <TableCell>
        {props.lastName}
      </TableCell>
      <TableCell>
        {getManagerControls()}
      </TableCell>
      <TableCell>
        {(isAdmin || props.myRole === 'manager') && <FontAwesomeIconButton onClick={() => props.delete(props.manager.userId)} icon={faTrash} />}
      </TableCell>
    </TableRow >

  )

}

export default ManagerRow

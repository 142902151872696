export function displayDateTime(d: Date | null): string {
  if (d === null) {
    return '';
  }
  if (d.getDate === undefined) {
    return '';
  }
  if (isNaN(d.getDate())) {
    return '';
  }
  var dd = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  var t = `0${d.getHours()}`.slice(-2) + ':' + `0${d.getMinutes()}`.slice(-2);
  return dd + ' ' + t;
}

export function displayDate(d: Date | null): string {
  if (d === null) {
    return '';
  }
  if (d.getDate === undefined) {
    return '';
  }
  if (isNaN(d.getDate())) {
    return '';
  }
  var dd = `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
  return dd
}

export function alarmsToDisplay(admin: boolean): string[] {
  return [...(admin ?
    ["batteryChange",
      "batteryChangeWired"] : []),
    "leak", //this doesn't exist
    "sensorError",
    "temperatureOutOfRange",
    "humidityOutOfRange",
    "bwflow",
    "overflow",
    "removed",
    "optFraud",
    "magFraud",
    "waterlossCalculated",
    "waterlossExternal",
    "bwflowCalculated",
    "stuckCalculated",
    "disconnect"
  ]
}

export function displayAlarms(alarms: string[], admin: boolean): string[] {
  return alarms.filter((a: string) => alarmsToDisplay(admin).find(b => b === a))
}

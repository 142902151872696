import { TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Client } from '../../redux/apartments/apartments-types';
import SimCardNumber from './SimCardNumber';
import { useTranslation } from "react-i18next";
import { UpdateClientTelephoneNumberAction } from "../../redux/apartments/apartments-actions";
import { Button } from '../../components/Button';
import deepEqual from 'deep-equal';
import { useButtonRights } from '../../utils/useButtonRights';

type Props = {
  clients: Client[],
  companyId: number
}

const convertClientsToMap = (clients: Client[]) => {
  let numberByClientIdRecord = {} as Record<number, string | null>
  clients.forEach(val => {
    numberByClientIdRecord[val.id] = val.telephoneNumber
  });
  return numberByClientIdRecord
}


const SIMcardList = ({ clients, companyId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [numberByClientId, setNumberByClientId] = React.useState<Record<number, string | null>>(convertClientsToMap(clients))
  const changed = (): boolean => !deepEqual(
    numberByClientId,
    convertClientsToMap(clients)
  )
  const updateClientTelephoneNumberButtonRights = useButtonRights(companyId, "manager", !changed())

  const onNumberChanged = (id: number, e: string | null) => {
    setNumberByClientId({ ...numberByClientId, [id]: e })
  };

  const onSaveClicked = () => {
    for (let c of clients) {
      let telephoneNumber = numberByClientId[c.id]
      if (telephoneNumber !== null && telephoneNumber !== undefined) {
        if (c.telephoneNumber !== telephoneNumber) {
          dispatch(UpdateClientTelephoneNumberAction({
            clientId: c.id,
            newTelephoneNumber: telephoneNumber !== "" ? telephoneNumber : null,
          }))
        }
      }
    }
  };

  return (<>
    <div style={{ width: "50%" }}>
      <TableContainer component='div' >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('apartments.centerId')}</TableCell>
              <TableCell>{t('companies.info.simCardNumber')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client, i) => (<SimCardNumber key={i} client={client} telephoneNumber={numberByClientId[client.id]} onNumberChanged={onNumberChanged} />))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    <Button disabled={updateClientTelephoneNumberButtonRights}
      variant="contained"
      onClick={onSaveClicked}>
      {t('generic.save')}
    </Button>
  </>

  )
}



export default SIMcardList
import { all, put, takeLatest } from "redux-saga/effects"
import { CloseSaveCompanyBasicInfoModalAction, FetchApartmentsAction } from "../apartments/apartments-actions"
import { ReportErrorAction } from "../error/error-actions"
import { FetchUsersAction } from "../users/users-actions"
import * as api from "../../api"

import {
  CreateCompanyAction,
  CreateCompanyFailureAction,
  CreateCompanySuccessAction,
  DeleteCompanyUserAction,
  DeleteCompanyUserSuccessAction,
  FetchCompaniesAction,
  FetchCompaniesFailureAction,
  FetchCompaniesSuccessAction,
  FetchCompanyApiKeyAction,
  FetchCompanyApiKeyFailureAction,
  FetchCompanyApiKeySuccessAction,
  RefreshCompanyApiKeyAction,
  RefreshCompanyApiKeyFailureAction,
  RefreshCompanyApiKeySuccessAction,
  SaveCompanyBasicInfoAction,
  UpdateUserRoleAction,
  UpdateUserAsManagerAction,
  UpdateUserRoleSuccessAction,
  UpdateUserAsManagerSuccessAction,
  UpdateApiAccessAction,
  UpdateApiAccessSuccessAction,
  SaveCompanyBasicInfoSuccessAction,
  UpdateUserRoleFailureAction,
  UpdateUserAsManagerFailureAction,
  DeleteCompanyUserFailureAction,
  PremiumToggleAction,
  PremiumToggleSuccessAction,
  DeleteCompanySuccessAction,
  DeleteCompanyAction,
  FetchAlarmTypesSuccessAction,
  FetchAlarmTypesFailureAction,
  FetchAlarmTypesAction,
  MergeApartmentsSuccessAction,
  MergeApartmentsFailureAction,
  MergeApartmentsAction,
  SaveCompanyBasicInfoFailureAction,
} from "./companies-actions"
import {
  CreateCompanyPayload,
  DeleteCompanyPayload,
  DeleteCompanyUserPayload,
  FetchCompanyApiKeyInput,
  RefreshCompanyApiKeyInput,
  SaveCompanyPayload,
  UpdateApiAccessPayload,
  PremiumTogglePayload,
  UpdateUserRoleAsManagerPayload,
  UpdateUserRolePayload,
  FetchAlarmTypesSuccessPayload,
  MergeApartmentsPayload,
} from "./companies-types"
import { ApiCompany } from "../../api/api-types"
import { convertApiCompany } from "../../api/api-conversions"

export function* fetchCompanies() {
  yield api.get(
    `/companies`,
    (resp: ApiCompany[]) => put(FetchCompaniesSuccessAction(resp.map(convertApiCompany))),
    err => all([
      put(FetchCompaniesFailureAction()),
      put(ReportErrorAction(err))
    ])
  )
}

export function* fetchCompanyApiKey(action: {
  payload: FetchCompanyApiKeyInput;
}) {
  type Response = {
    apiKey: string | null;
  };

  yield api.get(
    `/companies/${action.payload.companyId}/api_key`,
    (resp: Response) =>
      put(
        FetchCompanyApiKeySuccessAction({
          ...resp,
          companyId: action.payload.companyId,
        })
      ),
    (err) =>
      all([
        put(FetchCompanyApiKeyFailureAction()),
        put(ReportErrorAction(err))
      ])
  );
}

export function* refreshCompanyApiKey(action: {
  payload: RefreshCompanyApiKeyInput;
}) {
  type Response = {
    newApiKey: string | null;
  };

  yield api.post(
    `/companies/${action.payload.companyId}/api_key`,
    {},
    (resp: Response) =>
      put(
        RefreshCompanyApiKeySuccessAction({
          ...resp,
          companyId: action.payload.companyId,
        })
      ),
    (err) =>
      all([
        put(RefreshCompanyApiKeyFailureAction()),
        put(ReportErrorAction(err)),
      ])
  );
}

export function* createCompany(action: {
  type: string;
  payload: CreateCompanyPayload;
}) {
  yield api.post(
    `/companies`,
    action.payload,
    () => all([
      put(CreateCompanySuccessAction()),
      put(FetchCompaniesAction())
    ]),
    (err) =>
      all([
        put(CreateCompanyFailureAction(err)),
        put(ReportErrorAction(err))
      ])
  );
}

export function* updateUserRole(action: { payload: UpdateUserRolePayload }) {
  yield api.post(
    `/companies/${action.payload.companyId}/user_roles/${action.payload.userId}`,
    { role: action.payload.role, principal: action.payload.principal },
    () =>
      all([
        put(UpdateUserRoleSuccessAction(action.payload)),
        put(FetchCompaniesAction()),
      ]),
    (err) =>
      all([
        put(UpdateUserRoleFailureAction()),
        put(FetchCompaniesAction()),
        put(ReportErrorAction(err)),
      ])
  );
}

export function* updateUserRoleAsManager(action: {
  payload: UpdateUserRoleAsManagerPayload;
}) {
  yield api.post(
    `/companies/${action.payload.companyId}/users`,
    {
      email: action.payload.email,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      role: action.payload.role,
      principal: false,
    },
    () =>
      all([
        put(UpdateUserAsManagerSuccessAction()),
        put(FetchCompaniesAction()),
      ]),
    (err) =>
      all([
        put(UpdateUserAsManagerFailureAction()),
        put(FetchCompaniesAction()),
        put(ReportErrorAction(err)),
      ])
  );
}

export function* reloadCompanyUsers() {
  yield put(FetchUsersAction());
  yield put(FetchCompaniesAction());
}

export function* deleteCompanyUser(action: {
  payload: DeleteCompanyUserPayload;
}) {
  yield api.remove(
    `/companies/${action.payload.companyId}/users/${action.payload.userId}`,
    {},
    () => put(DeleteCompanyUserSuccessAction()),
    (err) =>
      all([
        all([
          put(DeleteCompanyUserFailureAction()),
          put(ReportErrorAction(err)),
        ]),
      ])
  );
}


export function* saveCompanyBasicInfo(action: { payload: SaveCompanyPayload }) {

  yield api.put(
    `/companies/${action.payload.id}/basic_info`,
    {
      name: action.payload.name,
      streetAddress: action.payload.streetAddress,
      zipCode: action.payload.zipCode,
      town: action.payload.town,
    },
    () => all([put(SaveCompanyBasicInfoSuccessAction()),
    put(FetchCompaniesAction()),
    put(CloseSaveCompanyBasicInfoModalAction())
    ]),
    err => all([
      put(SaveCompanyBasicInfoFailureAction()),
      put(ReportErrorAction(err))
    ]))
  }

export function* updateApiAccess(action: { payload: UpdateApiAccessPayload }) {
  yield api.put(
    `/companies/${action.payload.companyId}/api_access`,
    {
      apiAccessEnabled: action.payload.apiAccessEnabled,
    },
    () => put(UpdateApiAccessSuccessAction(action.payload)),
    (err: string) => put(ReportErrorAction(err))
  );
}

export function* premiumToggle(action: { payload: PremiumTogglePayload }) {
  yield api.put(
    `/companies/${action.payload.companyId}/premium_toggle`,
    {
      premium: action.payload.premium,
    },
    () => all([
      put(PremiumToggleSuccessAction()),
      put(FetchCompaniesAction())
    ]),
    (err: string) => put(ReportErrorAction(err))
  );
}

export function* deleteCompany(action: { payload: DeleteCompanyPayload }) {
  yield api.remove(
    `/companies/${action.payload}`,
    {},
    () => all([
      put(FetchCompaniesAction()),
      put(DeleteCompanySuccessAction())
    ]),
    (err: string) => all([put(ReportErrorAction(err))])
  );
}

export function* mergeApartments(action: { payload: MergeApartmentsPayload }) {

  yield api.post(
    `/companies/${action.payload.companyId}/apartments/${action.payload.targetApartmentId}/merge`,
    { sourceApartmentIds: action.payload.sourceApartmentIds },
    () => all([put(MergeApartmentsSuccessAction()),
    put(FetchApartmentsAction({ companyId: action.payload.companyId }))]),
    err => all([put(MergeApartmentsFailureAction()),
    put(ReportErrorAction(err))
    ]))
}


export function* fetchAlarmTypes() {
  yield api.get(
    "/alarm_types",
    (resp : FetchAlarmTypesSuccessPayload) =>
      put(FetchAlarmTypesSuccessAction(resp))
    ,
    (err) =>
      put(FetchAlarmTypesFailureAction())

  );
}


export function* companiesSaga() {
  yield takeLatest(FetchCompaniesAction, fetchCompanies)
  yield takeLatest(FetchCompanyApiKeyAction, fetchCompanyApiKey)
  yield takeLatest(RefreshCompanyApiKeyAction, refreshCompanyApiKey)
  yield takeLatest(CreateCompanyAction, createCompany)
  yield takeLatest(SaveCompanyBasicInfoAction, saveCompanyBasicInfo)
  yield takeLatest(UpdateUserRoleAction, updateUserRole)
  yield takeLatest(UpdateUserAsManagerAction, updateUserRoleAsManager)
  yield takeLatest(DeleteCompanyUserAction, deleteCompanyUser)
  yield takeLatest(UpdateUserAsManagerSuccessAction, reloadCompanyUsers)
  yield takeLatest(UpdateApiAccessAction, updateApiAccess)
  yield takeLatest(PremiumToggleAction, premiumToggle)
  yield takeLatest(DeleteCompanyAction, deleteCompany)
  yield takeLatest(MergeApartmentsAction, mergeApartments)
  yield takeLatest(FetchAlarmTypesAction, fetchAlarmTypes);
}

import { createTheme } from "@mui/material";

export const colors = {
  // neutral10: '#000000', // unused
  neutral08: '#12263F', // main text
  neutral06: '#6E84A3', // header titles, icons
  neutral04: '#EDF2F9', // separator lines, section background
  neutral02: '#F9FBFD', // app background
  neutral00: '#FFFFFF', // element background, navbar text

  primary06: '#81D1E8', // premium off background, hovered button background
  primary04: '#04A3D0', // premium on background, pressed button background, link, cold water, button outline

  warm06: '#BE366C', // alarms, hot water
  cold06: '#04A3D0', // alarms, cold water
  success06: '#04D08D', // check marks
  star06: '#D0CA04', // premium stars

  rowColorAdd: '#60F260',
  rowColorRemove: '#F26060'

};

export const barChartStyles = {
  borderRadius: 5,
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary06,//colors.primary04,
      // dark: //colors.primary06,
    },
    text: {
      primary: colors.neutral08,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 12,
  },
});

export const spacings = {
  stardardSpacing: '25px',
  inlineSpacing: '10px',
  narrow: '5px',
}

export const typography = {
  fontSize: {
    default: '11pt',
    title: '14pt',
  }
}

export const borders = {
  basicOutline: {
    border: `2px solid ${colors.neutral04}`,
    borderRadius: '5px'
  }
}

export const table = {
  tableCellWidth: '500em',
  tableCellWidthLonger: '700em',
  tableCellWidthShorter: '400em',
  tableCellWidthVeryShort: '100em'

}

export const alignments = {
  evenHorizontal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  twoElementGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'space-between',
    gap: '1rem'
  },
  rightSide: {
    display: 'flex',
    justifyContent: 'end'
  },
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
} as const // This is needed or you get "string not comparable to FlexDirection" problems
import React from "react";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandIcons from "../../components/ExpandIcons";
import { TableCell, TableRow } from "../../components/Table";
import { borders } from "../../theme";
import { Apartment } from "../../redux/apartments/apartments-types";
import { Resident } from "../../redux/residents/residents-types";
import ResidentEdit from "./ResidentEdit";

type Props = {
    apartment: Apartment,
    resident: Resident | null | undefined,
    companyId: number,
}

export default function residentRow({ apartment, resident, companyId }: Props): JSX.Element {
    const t = useTranslation().t
    const [expanded, setExpanded] = React.useState<boolean>(false)

    return <>
        <TableRow onClick={() => setExpanded(!expanded)} style={{ cursor: 'pointer' }}>
            <TableCell>
                {apartment.code}
            </TableCell>
            < TableCell >
                {resident !== null ?
                    <>
                        {resident?.main?.email ? <Chip label={resident.main.email} /> : <></ >}
                        {resident?.other?.email ? <Chip label={resident.other.email} /> : <></ >}
                    </>
                    : <>{t('residents.noManager')} </>
                }
            </TableCell>
            <TableCell style={{ width: '1.8em' }}>
                {resident !== null &&
                    <ExpandIcons closed={!expanded} />}
            </TableCell>
        </TableRow>
        {(expanded && resident !== null) && <TableRow>
            <TableCell colSpan={3}>
                <div style={borders.basicOutline}>
                    <ResidentEdit apartment={apartment} companyId={companyId} resident={resident} />
                </div>
            </TableCell>
        </TableRow>}

    </>
}






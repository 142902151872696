import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from '@mui/material';
import { TableCell, TableRow } from '../../../components/Table';
import AlarmCustomizationDropDowns from './AlarmCustomizationDropDowns';
import { AlarmType, Company } from '../../../redux/companies/companies-types';
import { AlarmCustomization } from '../../../redux/apartments/apartments-types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../interfaces/RootState';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../../../components/CustomTooltip';

export type Props = {
  alarmCustomizations: AlarmCustomization[];
  company: Company;
};

const AlarmCustomizationMenu = ({ alarmCustomizations, company }: Props) => {
  const alarmTypes = useSelector(
    (state: RootState) => state.hydrolink.companies.alarmTypes
  );

  const alarmTypeRow = (alarmType: AlarmType, comp: Company, allCustomizations: AlarmCustomization[]) => {
    let relevantCustomization = (allCustomizations || []).find(c => (c.alarmType == alarmType.alarmName)) || null

    return <AlarmCustomizationDropDowns key={alarmType.alarmName} company={comp} alarmType={alarmType} alarmCustomization={relevantCustomization} />
  }

  const t = useTranslation().t;


  return (
    <div style={{ marginTop: '1em' }}>
      <span style={{ marginTop: '1em' }} className="text-bold">
        {t('alarms.alarmSettings')}
      </span>
      <TableContainer component='div'>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('apartments.alarm')}</TableCell>
              <TableCell>
                <CustomTooltip title={t('alarms.highPriorityTooltip') || ''}>
                  <span className="contains-tooltip">{t('alarms.highPriority')}</span>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <CustomTooltip title={t('alarms.notifyNewTooltip') || ''}>
                  <span className="contains-tooltip">{t('alarms.notifyNew')}</span>
                </CustomTooltip>
              </TableCell>
              <TableCell>
                <CustomTooltip title={t('alarms.intervalTooltip') || ''}>
                  <span className="contains-tooltip">{t('alarms.intervalColumn')}</span>
                </CustomTooltip>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarmTypes.filter(alarmType => !alarmType.adminOnly).map(alarmType => alarmTypeRow(alarmType, company, alarmCustomizations))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AlarmCustomizationMenu;

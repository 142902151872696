import GetLanguage from "../components/common/language-selector/GetLanguage"

function AddLanguageToURL(path: string) {
    if (path.includes("?")) {
        path += "&"
    } else {
        path += "?"
    }
    path += "lang=" + GetLanguage
    return path
}

export default AddLanguageToURL
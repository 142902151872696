import { useEffect } from "react";
import { ViewContainer } from "../../components/ViewContainer";
import { alignments } from "../../theme";
import Pagination from "../../components/common/pagination";
import { RootState } from "../../interfaces/RootState";
import { useDispatch, useSelector } from 'react-redux';
import {
    FetchResidentsAction,
    FilterResidentsAction,
    ResidentsPaginationItemsPerPageChangeAction,
    ResidentsPaginationPageChangeAction
} from '../../redux/residents/residents-actions';
import ResidentTable from "./ResidentTable";
import CompanyTabs, { Tabs } from "../companies/CompanyTabs";
import CommonSearchBar from "../../components/common/search-bar/CommonSearchBar";
import { Apartment } from "../../redux/apartments/apartments-types";

type Props = {
    companyId: number
}

const ResidentApartmentList = ({ companyId }: Props) => {

    const dispatch = useDispatch()
    const residentCount = useSelector((root: RootState) => root.hydrolink.residents.filteredAndSortedResidentApartments).length
    const apartments = useSelector((state: RootState) => state.hydrolink.apartments.apartments)
    const currentPage = useSelector((root: RootState) => root.hydrolink.residents.pagination.currentPage)
    const residents = useSelector((state: RootState) => state.hydrolink.residents.residents)

    useEffect(() => {
        dispatch(FetchResidentsAction({ companyId: companyId }))
    }, [companyId, dispatch])

    const pageChange = (pageNumber: number) => {
        dispatch(ResidentsPaginationPageChangeAction(pageNumber))
    }

    const itemsPerPageChange = (itemsPerPage: number) => {
        dispatch(ResidentsPaginationItemsPerPageChangeAction(itemsPerPage))
    }

    const searchData = (apartment: Apartment) =>
        residents
            .filter(resident => resident.apartmentId === apartment.id)
            .flatMap(resident => [resident.main?.email ?? '', resident.other?.email ?? ''].filter(email => email !== ''))

    return <ViewContainer>
        <CompanyTabs tab={Tabs.RESIDENTS} />
        <ViewContainer style={alignments.evenHorizontal}>
            <CommonSearchBar
                items={apartments}
                searchData={searchData}
                filterAction={FilterResidentsAction}
                pageChange={pageChange}

            />
        </ViewContainer>
        <ViewContainer>
            <ResidentTable companyId={companyId} />
        </ViewContainer>
        <ViewContainer>
            <Pagination
                current={currentPage}
                itemsCount={residentCount}
                currentPageCallback={pageChange}
                itemsPerPageCallback={itemsPerPageChange}
            />
        </ViewContainer>
    </ViewContainer>
}

export default ResidentApartmentList
import { AnyAction } from "redux";
import {
    FetchResidentsUnauthorizedAction,
    FetchResidentsSuccessAction,
    ResidentsPaginationPageChangeAction,
    ResidentsPaginationItemsPerPageChangeAction,
    UpsertResidentSuccessAction,
    FetchResidentsAction,
    UpsertResidentAction,
    DeleteResidentAction,
    DeleteResidentSuccessAction,
    FetchResidentsFailureAction,
    UpsertResidentFailureAction,
    DeleteResidentFailureAction,
    SortResidentsAction,
    FilterResidentsAction
} from "./residents-actions";
import {
    FetchResidents,
    ResidentsState
} from "./residents-types";
import { Resident } from "./residents-types";

const initialState: ResidentsState = {
    residents: [],
    filteredAndSortedResidentApartments: [],
    pagination: {
        currentPage: 1,
        itemsPerPage: 10
    },
    authorized: false,
    credentials: {},
    loading: false,
    sortBy: 'code',
    ascending: true
}


const createNewResidentArray = (res: FetchResidents) => {
    let newResidentArray: Resident[] = []
    for (let apId in res) {
        let i = parseInt(apId)
        let main = res[i]?.main
        let other = res[i]?.other
        if (main === undefined) {
            main = null
        }
        if (other === undefined) {
            other = null
        }
        newResidentArray.push({ apartmentId: i, main: main, other: other })
    }
    return newResidentArray.sort((a, b) => (a.apartmentId - b.apartmentId))
}

const loadingTrue = (state: ResidentsState) => ({ ...state, loading: true })
const loadingFalse = (state: ResidentsState) => ({ ...state, loading: false })

const actions = (state: ResidentsState, action: AnyAction) => {
    if (FetchResidentsAction.match(action)) {
        return loadingTrue(state)
    }

    if (UpsertResidentAction.match(action)) {
        return loadingTrue(state)
    }

    if (DeleteResidentAction.match(action)) {
        return loadingTrue(state)
    }
    return state
}

const successActions = (state: ResidentsState, action: AnyAction) => {

    if (FetchResidentsSuccessAction.match(action)) {
        return {
            ...state,
            residents: createNewResidentArray(action.payload.residentsByApartmentId),
            authorized: true,
            loading: false
        }
    }


    if (UpsertResidentSuccessAction.match(action)) {
        let updatedCredentials = { ...state.credentials, ...action.payload.newCredentialsByResidentId }

        return {
            ...state,
            residents: createNewResidentArray(action.payload.residentsByApartmentId),
            credentials: updatedCredentials,
            authorized: true,
            loading: false
        }
    }

    if (DeleteResidentSuccessAction.match(action)) {
        return loadingFalse(state)
    }
    return state
}

const failureActions = (state: ResidentsState, action: AnyAction) => {
    if (FetchResidentsUnauthorizedAction.match(action)) {
        return {
            ...state,
            authorized: false,
            loading: false
        }
    }

    if (FetchResidentsFailureAction.match(action)) {
        return loadingFalse(state)
    }


    if (UpsertResidentFailureAction.match(action)) {
        return loadingFalse(state)
    }

    if (DeleteResidentFailureAction.match(action)) {
        return loadingFalse(state)
    }
    return state

}

const otherActions = (state: ResidentsState, action: AnyAction) => {

    if (SortResidentsAction.match(action)) {

        return {
            ...state,
            sortBy: action.payload.sortBy,
            ascending: action.payload.ascending,
            filteredAndSortedResidentApartments: action.payload.apartments
        }
    }

    if (FilterResidentsAction.match(action)) {
        return {
            ...state,
            filteredAndSortedResidentApartments: action.payload
        }
    }

    if (ResidentsPaginationPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload
            }
        }
    }

    if (ResidentsPaginationItemsPerPageChangeAction.match(action)) {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                itemsPerPage: action.payload
            }
        }
    }

    return state
}



export default function residentsReducer(state: ResidentsState = initialState, action: AnyAction): ResidentsState {

    state = actions(state, action)
    state = successActions(state, action)
    state = failureActions(state, action)
    state = otherActions(state, action)

    return state
}

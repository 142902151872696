import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "../../components/Button";
import { OpenCreateUserModalAction } from "../../redux/users/users-actions";
import AddIcon from '@mui/icons-material/Add'


export default function CreateUserButton() {
    const dispatch = useDispatch()
    const t = useTranslation().t

    return <Button
        onClick={() => dispatch(OpenCreateUserModalAction())}
        startIcon={<AddIcon />}
    >
        {t('users.createNew')}
    </Button>
}
import { createAction } from "@reduxjs/toolkit"
import {
    DeleteUserPayload,
    CreateUserPayload,
    FetchUserSuccessPayload,
    UpdateUserPayload,
    CreateNewUserPasswordPayload,
    UpdateUserEmailPayload,
    UpdateUserAdminPayload,
    FilterUsersPayload,
    SortUsersPayload
} from "./users-types"

export const FetchUsersAction = createAction<void>('FETCH_USERS')
export const FetchUsersSuccessAction = createAction<FetchUserSuccessPayload>('FETCH_USERS_SUCCESS')
export const FetchUsersFailureAction = createAction<void>('FETCH_USERS_FAILURE')

export const UpdateUserAction = createAction<UpdateUserPayload>('UPDATE_USER')
export const UpdateUserSuccessAction = createAction<void>('UPDATE_USER_SUCCESS')
export const UpdateUserFailureAction = createAction<void>('UPDATE_USER_FAILURE')

export const UpdateUserAdminAction = createAction<UpdateUserAdminPayload>('UPDATE_USER_ADMIN')
export const UpdateUserAdminSuccessAction = createAction<void>('UPDATE_USER_ADMIN_SUCCESS')
export const UpdateUserAdminFailureAction = createAction<void>('UPDATE_USER_ADMIN_FAILURE')

export const DeleteUserAction = createAction<DeleteUserPayload>('DELETE_USER')
export const DeleteUserSuccessAction = createAction<DeleteUserPayload>('DELETE_USER_SUCCESS')
export const DeleteUserFailureAction = createAction<void>('DELETE_USER_FAILURE')

export const FilterUsersAction = createAction<FilterUsersPayload>('FILTER_USERS')
export const SortUsersAction = createAction<SortUsersPayload>('SORT_USERS')

export const OpenCreateUserModalAction = createAction<void>('OPEN_CREATE_USER_MODAL')
export const CloseCreateUserModalAction = createAction<void>('CLOSE_CREATE_USER_MODAL')

export const CreateUserAction = createAction<CreateUserPayload>('CREATE_USER')
export const CreateUserSuccessAction = createAction<void>('CREATE_USER_SUCCESS')
export const CreateUserFailureAction = createAction<void>('CREATE_USER_FAILURE')

export const CreateNewUserPasswordAction = createAction<CreateNewUserPasswordPayload>('CREATE_NEW_USER_PASSWORD')
export const CreateNewUserPasswordSuccessAction = createAction<void>('CREATE_NEW_USER_PASSWORD_SUCCESS')
export const CreateNewUserPasswordFailureAction = createAction<void>('CREATE_NEW_USER_PASSWORD_FAILURE')

export const UpdateUserEmailAction = createAction<UpdateUserEmailPayload>('UPDATE_USER_EMAIL')
export const UpdateUserEmailSuccessAction = createAction<void>('UPDATE_USER_EMAIL_SUCCESS')
export const UpdateUserEmailFailureAction = createAction<void>('UPDATE_USER_EMAIL_FAILURE')

export const UsersPaginationPageChangeAction = createAction<number>('USERS_PAGINATION_PAGE_CHANGE')
export const UsersPaginationItemsPerPageChangeAction = createAction<number>('USERS_PAGINATION_ITEMS_PER_PAGE_CHANGE')

import React from 'react';
import { IconButton, ListItemIcon, Menu, MenuItem, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { useTranslation } from 'react-i18next';
import { StyledMenuTypography } from './HamburgerMenu.style';
import { Link } from 'react-router-dom';
import { colors } from '../../../theme';

enum Tab {
  companies = 'companies',
  residents = 'residents',
  licenses = 'licenses',
  users = 'users',
  guides = 'guides',
}

function isAdminOnly(tabType: Tab): boolean {
  switch (tabType) {
    case Tab.licenses: return true
    case Tab.users: return true
  }
  return false
}

export default function HamburgerMenu({ isAdmin }: { isAdmin: boolean }) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const onMenuOpen = (currentTarget: Element) => {
    setAnchorEl(currentTarget)
  }

  const onMenuClose = () => {
    setAnchorEl(null)
  }

  const tabs: Tab[] = [
    Tab.companies,
    Tab.residents,
    Tab.users,
    Tab.licenses,
    Tab.guides,
  ]

  const hamburgerMenuStyle = {
    color: colors.neutral00,
    fontSize: '1.9rem'
  }
  return <div>
    <Grid item>
      <IconButton
        edge="start"
        onClick={(e) => onMenuOpen(e.currentTarget)}
      >
        <MenuIcon style={hamburgerMenuStyle}></MenuIcon>
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={onMenuClose}
        anchorEl={anchorEl}
        disableScrollLock
      >
        {tabs.filter((tabType) => (isAdmin || !isAdminOnly(tabType))).map((tabType, i) => (
          <Link to={`/${tabType}`} key={i}>
            <MenuItem onClick={() => {
              onMenuClose()
            }}>
              <ListItemIcon>
                <img alt='icon' src={require(`../../../assets/ICON_menu_${tabType}.svg`)} />
              </ListItemIcon>
              <StyledMenuTypography>
                {t(`landing.${tabType}`)}
              </StyledMenuTypography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </Grid>
  </div>;
}

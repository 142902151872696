
import { TableRow, TableCell } from "../../components/Table"

type Props = {
    id: number,
    title: string,
    firstValue?: number,
    secondValue?: number,
}

export function ReportTableBodyRows({ id, title, firstValue, secondValue }: Props): JSX.Element {

    return <TableRow key={id}>
        <TableCell>{title ?? '-'}</TableCell>
        <TableCell>{firstValue ?? '-'}</TableCell>
        <TableCell>{secondValue ?? '-'}</TableCell>
    </TableRow>
}
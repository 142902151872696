import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "../../components/Button"
import Checkbox from "../../components/Checkbox"
import { Modal } from "../../components/common/modal/Modal"
import { ModalInput } from "../../components/common/modal/ModalInput"
import { RootState } from "../../interfaces/RootState"
import { ReportErrorAction } from "../../redux/error/error-actions"
import { CloseCreateUserModalAction, CreateUserAction } from "../../redux/users/users-actions"
import { CreateUserPayload } from "../../redux/users/users-types"

const emptyUserData: CreateUserPayload = {
    admin: false,
    email: '',
    firstName: '',
    lastName: '',
    phone: ''
}

function CreateUserModal(): JSX.Element {
    const dispatch = useDispatch()
    const t = useTranslation().t

    const isOpen = useSelector((state: RootState) => state.hydrolink.users.createUserModalOpen)
    const [userData, setUserData] = useState<CreateUserPayload>(emptyUserData)

    // Clear all fields when the modal closes
    useEffect(() => {
        setUserData(emptyUserData)
    }, [isOpen])

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof CreateUserPayload) => {
        setUserData({
            ...userData,
            [field]: e.target.value
        })
    }

    const save = () => {
        if (userData.firstName == "") {
            dispatch(ReportErrorAction(t("errors.firstNameMissing")))
        } else if (userData.lastName == "") {
            dispatch(ReportErrorAction(t("errors.lastNameMissing")))
        } else if (userData.email == "") {
            dispatch(ReportErrorAction(t("errors.emailMissing")))
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userData.email)) {
            // Above is a regex for validating email formats :)
            dispatch(ReportErrorAction(t("errors.badEmailFormat")))
        } else {
            createUser()
        }
    }

    const createUser = () => {
        dispatch(CreateUserAction(userData))
    }

    const closeModal = () => {
        dispatch(CloseCreateUserModalAction())
    }

    const getDialogActions = (): JSX.Element => <>
        <Button
            onClick={closeModal}
        >
            {t('generic.cancel')}
        </Button>
        <Button
            variant="contained"
            onClick={save}
        >
            {t('generic.save')}
        </Button>
    </>

    // The reason as to why this is not a normal React component is because the input field unselects on every render
    function createUserModalInput(field: keyof CreateUserPayload) {
        return <ModalInput
            key={field}
            title={t(`users.${field}`)}
            value={userData[field] as string}
            handleFormValue={e => onFormChange(e, field)}
        />
    }

    return <Modal
        title={t("users.createNew")}
        open={isOpen}
        dialogActions={getDialogActions()}
        closeModal={closeModal}
    >
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', justifyContent: 'space-between', gap: '1rem' }}>
            {createUserModalInput('firstName')}
            {createUserModalInput('lastName')}
            {createUserModalInput('email')}
            {createUserModalInput('phone')}
            {/* createUserModalInput isn't for checkboxes, so create one separately */}
            <div style={{ padding: '10px 0' }}>
                <Checkbox
                    label={t('users.admin')}
                    checked={userData.admin}
                    onChange={() => setUserData({ ...userData, admin: !userData.admin })}
                />
            </div>
        </div>
    </Modal>

}

export default CreateUserModal
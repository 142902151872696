import React from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/Button';
import { Block } from '../../../components/common/block';
import { alignments } from '../../../theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StyledFieldsText } from '../../../components/common/modal/Modal.style';
import { ChangeMeterAction, HydrodigitLRAlarmResetAction } from '../../../redux/apartments/apartments-actions';
import { Meter } from '../../../redux/apartments/apartments-types';
import {useButtonRights} from "../../../utils/useButtonRights";



export default function MeterProperties({ apartmentId, companyId, meter, deleteMeter }: { apartmentId: number, companyId: number, meter: Meter, deleteMeter: () => void }): JSX.Element {
  const dispatch = useDispatch()
  const t = useTranslation().t
  const resetAlarmButtonRights = useButtonRights(companyId, 'manager')
  const deleteMeterButtonRights = useButtonRights(companyId, 'admin')

  const [changingDate, setChangingDate] = React.useState<Date | null>(null)

  const changeMeter = () => {
    if (changingDate) {
      dispatch(ChangeMeterAction({ companyId: companyId, apartmentId: apartmentId, meterId: meter.id, date: changingDate}))
    }
  }

  const resetHydrodigitLRAlarms = () => {
    dispatch(HydrodigitLRAlarmResetAction({meterId: meter.id}))
  }

  const changeDate= (date: Date | null) => {
    if (date) {
      const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
      setChangingDate(newDate)
    }
  }

  const dateWhenMeterChanged = () => {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledFieldsText>
        {t('meters.changeDate')}
      </StyledFieldsText>
      <div>
        <DatePicker
          views={['year', 'month', 'day']}
          value={changingDate}
          inputFormat="dd/MM/yyyy"
          onChange={(date) => changeDate(date)}
          renderInput={(props) =>
            <TextField
              {...props}
              style={{
                border: '1px solid #B5B9B9',
                borderRadius: '5px',
              }}
              sx={{
                'input': {
                  fontSize: '12px',
                  font: 'revert',
                  width: '100%',
                  height: 'auto',
                  padding: '10px',
                },
                'fieldset': {
                  border: '0'
                }
              }}
            />
          }
        />
      </div>
    </LocalizationProvider>
  }

  return <Block noMargin noBottomBorder variant={'white'} style={{}}>
    <h3>{t('meters.properties')}:</h3>
    <div style={{ width: '250px' }}>
      {dateWhenMeterChanged()}
    </div>

    <div style={{ ...alignments.rightSide, marginTop: '20px' }}>
      <Button
        onClick={changeMeter}
        disabled={!changingDate}>
        {t('meters.markAsChanged')}
      </Button>
      {meter.supportsHydrodigitLRAlarmReset && <>
        <div style={{ marginLeft: '20px' }}> </div>
        <Button
          disabled={resetAlarmButtonRights}
          onClick={resetHydrodigitLRAlarms}>
          {t('meters.resetAlarms')}
        </Button>
      </>
      }
      <div style={{ marginLeft: '20px' }}> </div>
      <Button
        disabled={deleteMeterButtonRights}
        onClick={deleteMeter}
      >
        {t('meters.removeMeter')}
      </Button>
    </div>
  </Block>
}
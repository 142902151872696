import { InputLabel } from "@mui/material"
import { useTranslation } from "react-i18next"
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/RootState";
import DropDown from "../../components/DropDown";
import { Button } from "../../components/Button";
import { FetchCSVAction, FetchReportsAction } from "../../redux/reports/reports-actions";
import { FetchCompaniesAction } from "../../redux/companies/companies-actions";
import { DateChoosingTable } from "./DateChoosingTable";
import { displayDate } from "../../utils/utils";
import { Apartment } from "../../redux/apartments/apartments-types";
import deepEqual from "deep-equal";
import { FetchReportFormatsAction } from "../../redux/apartments/apartments-actions";


type Props = {
    apartments: Apartment[]
    companyId: number
}

export function TopBlock({ companyId, apartments }: Props): JSX.Element {

    const defaultFormat = useSelector((state: RootState) => state.hydrolink.companies.companies.find(c => c.id === companyId)?.reportFormat)
    const [chosenFormat, setChosenFormat] = React.useState<string | undefined>(defaultFormat)
    const reportFormats = useSelector((state: RootState) => state.hydrolink.apartments.reportFormats)
    const [from, setFrom] = React.useState<Date | null>(new Date())
    const [until, setUntil] = React.useState<Date | null>(new Date())
    const dispatch = useDispatch()
    const t = useTranslation().t

    useEffect(() => {
        if (defaultFormat === undefined && chosenFormat === undefined) {
            dispatch(FetchCompaniesAction())
            setChosenFormat(defaultFormat)
        }
    }, [])

    useEffect(() => {
        if (chosenFormat === undefined) {
            setChosenFormat(defaultFormat)
        }
    }, [defaultFormat])

    useEffect(() => {
        if (reportFormats.length === 0) {
            dispatch(FetchReportFormatsAction())
        }
    }, [reportFormats])

    useEffect(() => {
        if (from && deepEqual(from, until)) {
            let monthLess = from.setMonth(from.getMonth() - 1)
            setFrom(new Date(monthLess))
        }
        if (from && until && apartments) {
            dispatch(FetchReportsAction({
                companyId: companyId,
                from: from.toISOString().slice(0, 10),
                until: until.toISOString().slice(0, 10),
                apartments: apartments
            }))
        }
    }, [from, until])

    const downloadReport = () => {
        if (from && until && chosenFormat) {
            dispatch(FetchCSVAction({
                companyId: companyId,
                from: from.toISOString().slice(0, 10),
                until: until.toISOString().slice(0, 10),
                format: chosenFormat
            }))
        }
    }

    const format = () => <>
        <InputLabel htmlFor="format">{t('consumptionReporting.format')} </InputLabel>
        <DropDown
            id={'format'}
            options={reportFormats.map(r => { return { value: r.id, displayText: r.name } })}
            selectedValue={chosenFormat ?? ''}
            width='250px'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setChosenFormat(reportFormats.find(r => r.id === e.target.value)?.id ?? reportFormats[0].id)}
        />
    </>

    return <>  <h3>{t("consumptionReporting.consumption")} {from && until ? `(${displayDate(new Date(from))} - ${displayDate(new Date(until))})` : ''}</h3>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: '500px' }}>
                <DateChoosingTable from={from} until={until} setFrom={setFrom} setUntil={setUntil} />
            </div>
            {(reportFormats.length > 0 && chosenFormat) &&
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {format()}
                    <Button
                        onClick={downloadReport}
                        disabled={(!chosenFormat)}
                    >
                        {t('consumptionReporting.download')}
                    </Button>
                </div>}
        </div>
    </>
}
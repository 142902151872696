import { QueuedCommand, SupportedCommand } from "../centers/centers-types"

export type Apartment = {
  id: number;
  code: string;
  client: Client;
  meters: Meter[];
};

export type Meter = {
  id: number;
  type: MeterType;
  warm: boolean;
  heating: boolean;
  code: string;
  reading: number;
  updated: number;
  meterResetAt: Date | null;
  activeAlarmIds: string[];
  supportsHydrodigitLRAlarmReset: boolean;
};

export type MeterType = "water" | "energy" | "temperature" | "leakage";

export type MeterHistoryType = "water" | "energy" | "temperature";

export type Client = {
    id: number;
    code: string;
    softwareVersion: string | null;
    telephoneNumber: string | null;
    commandQueue: QueuedCommand[];
    supportedCommands: SupportedCommand[];
};

export type FetchApartmentsPayload = {
  companyId: number;
};

export type FetchApartmentsSuccessPayload = Apartment[];

export type MeterHistoryReading = {
  created: Date;
  value: number | null;
  averageHumidityPercent?: number;
};

export type ApartmentsState = {
  apartments: Apartment[];
  saveCompanyBasicInfoModalOpen: boolean;
  usageByMeterId: Record<number, MeterHistoryReading[]>;
  chartState: ChartState;
  isReportingOpen: boolean;
  premiumSettings: PremiumSettings;
  alarmSettings: AlarmSettings;
  reportSettings: ReportSettings;
  reportFormats: ReportFormat[];
  loading: boolean;
};

export type ChartState = {
  apartmentId: number | null;
  isChartOpen: boolean;
  type: "day" | "month" | "week";
  offset: number;
  meterType: MeterHistoryType | null;
};

export type PremiumSettings = {
  phoneNumbers: string;
  warmWaterPrice: string;
  coldWaterPrice: string;
};


export enum Interval {
  Daily,
  Weekly,
  Monthly,
  New,
  Never,
}

export type AlarmCustomization = {
  companyId: number | null | undefined;
  alarmType: string;
  highPriority: boolean | null;
  notifyOnNew: boolean | null;
  alarmInterval: string | null;
};


export type FetchPremiumSettingsPayload = {
  companyId: number;
};

export type SavePremiumSettingsPayload = {
  settings: PremiumSettings;
  companyId: number;
};

export type AlarmSettings = {
    stuckEmail: string,
    alarmLang: string,
    alarmCustomizations: AlarmCustomization[];
}



export type FetchAlarmSettingsPayload = {
  companyId: number;
};

export type SaveAlarmSettingsPayload = {
  alarmSettings: AlarmSettings;
  companyId: number;
};


export type SaveAlarmCustomizationPayload = {
    alarmCustomization: AlarmCustomization,
    companyId: number
}

export type ReportSettings = {
  reportingId: string;
  sendReports: boolean;
  reportEmail: string;
  reportFormat: string;
  reportIntervalMonths: number;
  reportLang: string;
  reportLastSent?: string;
  reportStart?: Date;
};

export type FetchReportSettingsPayload = {
  companyId: number;
};

export type SaveReportSettingsPayload = {
  reportSettings: ReportSettings;
  companyId: number;
};

export type UploadCSVPayload = {
  companyId: number;
  file: string;
};

export type UpdateClientTelephoneNumberPayload = {
  clientId: number;
  newTelephoneNumber: string | null;
};

export type UpdateClientTelephoneNumberSuccessPayload = {
  clientId: number;
  newTelephoneNumber: string | null;
};

export type DeleteMeterPayload = {
  companyId?: number;
  id: number;
  type: MeterType;
};

export type FetchGraphsPayload = {
  apartmentId: number;
  type: "month" | "day" | "week";
  offset: number;
  meterType: MeterHistoryType | null;
};

export type FetchGraphsSuccessPayloadWaterMeterData = {
  created: Date;
  usageLitres: number | null;
};

export type FetchGraphsSuccessPayloadEnergyMeterData = {
  created: Date;
  usageKwh: number;
};

export type FetchGraphsSuccessPayloadTemperatureMeterData = {
  timestamp: Date;
  averageTemperatureCelsius: number;
  averageHumidityPercent?: number;
};

export type FetchGraphsSuccessPayload = {
  usageByMeterId?: Record<number, FetchGraphsSuccessPayloadWaterMeterData[]>;
  usageByEnergyMeterId?: Record<
    number,
    FetchGraphsSuccessPayloadEnergyMeterData[]
  >;
  byTemperatureMeterId?: Record<
    number,
    FetchGraphsSuccessPayloadTemperatureMeterData[]
  >;
};

export type DeleteApartmentPayload = {
  companyId: number;
  id: number;
};

export type ReportFormatsPayload = ReportFormat[];

export type ReportFormat = {
  id: string;
  name: string;
};

export type ChangeMeterPayload = {
    apartmentId: number,
    companyId: number,
    meterId: number,
    date: Date,
    valueBefore?: number,
    valueAfter?: number
}

export type UpdateApartmentNamePayload = {
  apartmentId: number;
  companyId: number;
  name: string;
};

export type HydrodigitLRAlarmResetPayload = {
  meterId: number
}


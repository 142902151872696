import React from "react"
import { alignments } from "../theme"

type Props = {
    children: React.ReactNode,
    style?: React.CSSProperties
}

export default function Centered({ children, style }: Props): JSX.Element {
    return <div style={{ ...alignments.centered, ...style }}>
        {children}
    </div>
}

import React from 'react';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../components/Table';
import { RootState } from '../../interfaces/RootState';
import { Company, Role } from '../../redux/companies/companies-types';
import { getCompanyManagerByUser, getHighestRoleForUser } from '../../redux/users/users-reducer';
import { User } from '../../redux/users/users-types';
import UsersRow from './UsersRow';
import Sorter from '../../components/Sorter';
import { SortUsersAction } from '../../redux/users/users-actions';
import { table } from '../../theme';



function UsersTable(): JSX.Element {
    const dispatch = useDispatch()
    const t = useTranslation().t
    const filteredAndSortedUsers = useSelector((state: RootState) => state.hydrolink.users.filteredAndSortedUsers)
    const companies = useSelector((state: RootState) => state.hydrolink.companies.companies)
    const pagination = useSelector((state: RootState) => state.hydrolink.users.pagination)
    const currentSorting = useSelector((state: RootState) => state.hydrolink.users.sortBy)
    const currentAscending = useSelector((state: RootState) => state.hydrolink.users.ascending)
    let startIndex = (pagination.currentPage - 1) * pagination.itemsPerPage
    let endIndex = startIndex + pagination.itemsPerPage
    const users = filteredAndSortedUsers.slice(startIndex, endIndex)

    const getCompaniesForUser = (user: User): Company[] =>
        companies.filter((c: Company) => getCompanyManagerByUser(c, user) !== undefined)

    const sortUsersFunction = (sortUsers: User[], sortBy: string, ascending: boolean) => {
        dispatch(SortUsersAction({ users: sortUsers, sortBy: sortBy, ascending: ascending }))
    }

    const getRole = (u: User) => {
        let role: Role = "viewer"
        if (getCompaniesForUser) {
            role = getHighestRoleForUser(getCompaniesForUser(u), u)
        }
        switch (role) {
            case 'admin':
                return "A"
            case 'manager':
                return "B"
            case 'viewer':
                return "C"
            default:
                return "D"
        }
    }

    const usersTableHead = (): React.ReactNode => <TableHead>
        <TableRow>
            <Sorter
                sortKey='userFullName'
                items={filteredAndSortedUsers}
                sortAction={sortUsersFunction}
                sortBy={currentSorting}
                title={t('users.name')}
                sortData={(user: User) => (user.firstName + user.lastName)}
                currentAscending={currentAscending}
            />
            <Sorter
                sortKey='username'
                items={filteredAndSortedUsers}
                sortAction={sortUsersFunction}
                sortBy={currentSorting}
                title={t('users.username')}
                sortData={(user: User) => user.username}
                currentAscending={currentAscending}
            />
            <TableCell width={table.tableCellWidth}>
                {t('users.companies')}
            </TableCell>
            <Sorter
                sortKey='highestRole'
                items={filteredAndSortedUsers}
                sortAction={sortUsersFunction}
                sortBy={currentSorting}
                title={t('users.highestRole')}
                sortData={(user: User) => getRole(user)}
                currentAscending={currentAscending}
            />
            <TableCell>{/* Expand user button */}</TableCell>
        </TableRow>
    </TableHead>

    return <TableContainer component={Paper}>
        <Table>
            {usersTableHead()}
            <TableBody>
                {users.map((u: User) => <UsersRow key={u.id} user={u} companies={getCompaniesForUser(u)} />)}
            </TableBody>
        </Table>
    </TableContainer>
}

export default UsersTable
import React from "react"
import { colors, spacings } from "../../../theme"

interface BlockProps {
  variant: "white" | "grey",
  style?: React.CSSProperties,
  children?: React.ReactNode,
  noMargin?: boolean,
  noBottomBorder?: boolean
  onClick?: () => void, //what happens when you click the "open" icon
}

export function Block(props: BlockProps) {

  let bgColor = "#FFFFFF"

  if (props.variant === "grey") {
    bgColor = colors.neutral04
  }
  let defaultStyle = {
    padding: spacings.stardardSpacing,
    backgroundColor: bgColor,
    borderRadius: "5px",
    borderBottom: props.noBottomBorder ? '' : `2px solid ${colors.neutral04}`,
    borderColor: colors.neutral04,
    marginBottom: props.noMargin ? '0' : '25px'
  }

  return (
    <div onClick={props.onClick} style={{ ...defaultStyle, ...props.style ?? {} }}>
      {props.children}
    </div>
  )
}
import React, { useEffect } from "react";
import { Alert, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import { Block } from '../../components/common/block';
import { ModalInput } from '../../components/common/modal/ModalInput';
import { alignments } from '../../theme';
import { Apartment } from "../../redux/apartments/apartments-types";
import { Resident, UpsertResident, UpsertResidents } from "../../redux/residents/residents-types";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DeleteResidentAction, UpsertResidentAction } from "../../redux/residents/residents-actions";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StyledFieldsText } from "../../components/common/modal/Modal.style";
import { RootState } from "../../interfaces/RootState";
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";

type Props = {
    apartment: Apartment,
    resident: Resident | null | undefined,
    companyId: number,
}

export default function ResidentEdit({ apartment, resident, companyId }: Props): JSX.Element {
    const t = useTranslation().t
    const dispatch = useDispatch()
    const [email, setEmail] = React.useState<string>('')
    const [otherEmail, setOtherEmail] = React.useState<string>('')
    const [moveInMonth, setMoveInMonth] = React.useState<Date | null>(null)
    const [otherMoveInMonth, setOtherMoveInMonth] = React.useState<Date | null>(null)
    const credentials = useSelector((root: RootState) => root.hydrolink.residents.credentials)
    const [credentialsDisplayed, setCredentialsDisplayed] = React.useState<boolean>(false)

    useEffect(() => {
        if (resident?.main?.email) {
            setEmail(resident?.main?.email)
        }
        if (resident?.main?.dataVisibleFrom) {
            let date = new Date()
            date.setFullYear(resident?.main?.dataVisibleFrom.year, resident?.main?.dataVisibleFrom.month - 1)
            setMoveInMonth(date)
        }
        if (resident?.other?.email) {
            setOtherEmail(resident?.other.email)
        }
        if (resident?.other?.dataVisibleFrom) {
            let date = new Date()
            date.setFullYear(resident?.other?.dataVisibleFrom.year, resident?.other?.dataVisibleFrom.month - 1)
            setOtherMoveInMonth(date)
        }
    }, [resident])

    const anythingChanged = (): boolean =>
        emailChanged(resident?.main?.email, email) || emailChanged(resident?.other?.email, otherEmail)
        || moveInMonthChanged(resident?.main?.dataVisibleFrom, moveInMonth)
        || moveInMonthChanged(resident?.other?.dataVisibleFrom, otherMoveInMonth)

    const emailChanged = (original: string | undefined, state: string) => {
        if (!original) {
            original = ''
        }
        return original !== state
    }

    const moveInMonthChanged = (original: { year: number, month: number } | undefined, compare: Date | null) => {
        if (original === undefined) {
            return compare !== null
        }
        return original.year !== compare?.getFullYear() || original.month - 1 !== compare!!.getMonth()
    }

    const deleteResident = (id: number, resetEmail: React.Dispatch<React.SetStateAction<string>>, resetDate: React.Dispatch<React.SetStateAction<Date | null>>) => {
        if (window.confirm(`${t("residents.deleteResidentConfirmation")}?`)) {
            dispatch(DeleteResidentAction({ companyId: companyId, residentId: id, apartmentId: apartment.id }))
            resetEmail('')
            resetDate(null)
        }
    }

    const newResidentValidationCheck = (email: string, year: number | undefined, month: number | undefined) => {
        if (email !== '' && year && month !== undefined) {
            let newResidentData = {
                email: email,
                dataVisibleFrom: {
                    year: year,
                    month: month + 1
                }
            }
            return newResidentData
        }
        return null
    }

    const save = () => {
        let newMain = newResidentValidationCheck(email, moveInMonth?.getFullYear(), moveInMonth?.getMonth())
        let newOther = newResidentValidationCheck(otherEmail, otherMoveInMonth?.getFullYear(), otherMoveInMonth?.getMonth())
        if (newMain || newOther) {
            let newResident: UpsertResident = {
                main: newMain,
                other: newOther
            }
            let newResidents: UpsertResidents = { [apartment.id]: newResident }
            dispatch(UpsertResidentAction({ companyId: companyId, toUpsertByApartmentId: newResidents }))
        }
    }

    const moveInDate = (titleMoveInMonth: string, moveInMonthValue: any, setMoveInMonthValue: React.Dispatch<React.SetStateAction<Date | null>>) => {
        return <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledFieldsText>
                {titleMoveInMonth}
            </StyledFieldsText>
            <div>
                <DatePicker
                    views={['year', 'month']}
                    value={moveInMonthValue}
                    onChange={(date) => setMoveInMonthValue(date)}
                    renderInput={(props) =>
                        <TextField
                            {...props}
                            style={{
                                border: '1px solid #B5B9B9',
                                borderRadius: '5px',
                            }}
                            sx={{
                                "input": {
                                    fontSize: '12px',
                                    font: 'revert',
                                    width: '100%',
                                    height: 'auto',
                                    padding: '10px',
                                },
                                "fieldset": {
                                    border: '0'
                                }
                            }}
                        />
                    }
                />
            </div>
        </LocalizationProvider>
    }

    const displayCredentials = (id: number | undefined) => {
        if (!credentialsDisplayed) {
            setCredentialsDisplayed(true)
        }
        return <>
            <div style={{ padding: '10px 0' }}>
                <StyledFieldsText>
                    {t("residents.username")}
                </StyledFieldsText>
                <p>
                    {credentials[id as keyof typeof credentials].username ?? ""}
                </p>
            </div>

            <div style={{ padding: '10px 0' }}>
                <StyledFieldsText>
                    {t("residents.password")}
                </StyledFieldsText>
                <p>
                    {credentials[id as keyof typeof credentials].password ?? ""}
                </p>
            </div></>
    }

    const row = (id: number | undefined, title: string, value: string, onChange: React.Dispatch<React.SetStateAction<string>>, titleMoveInMonth: string, moveInMonthValue: any, setMoveInMonthValue: React.Dispatch<React.SetStateAction<Date | null>>) => {
        return <div style={{ ...alignments.twoElementGrid, gridTemplateColumns: 'repeat(5, 1fr)' }}>
            <ModalInput
                key={id}
                title={title}
                value={value}
                handleFormValue={e => onChange(e.target.value)}
            />
            <div style={{ padding: '10px 0' }}>
                {moveInDate(titleMoveInMonth, moveInMonthValue, setMoveInMonthValue)}
            </div>
            <></>
            <FontAwesomeIconButton onClick={() => deleteResident(id!!, onChange, setMoveInMonthValue)} icon={faTrash} style={{ width: '0%', height: '130%' }} />
            {credentials[id as keyof typeof credentials] ? displayCredentials(id) : <></>}
        </div>
    }


    return <Block noMargin noBottomBorder variant={'white'}>
        <h3>{apartment.code} {t("residents.residents")}</h3>
        <div>
            {row(resident?.main?.id, t("residents.mainEmail"), email, setEmail, t("residents.moveInMonth"), moveInMonth, setMoveInMonth)}
            {row(resident?.other?.id, t("residents.otherEmail"), otherEmail, setOtherEmail, t("residents.moveInMonth"), otherMoveInMonth, setOtherMoveInMonth)}
        </div>
        <div style={{ ...alignments.rightSide, marginTop: '20px' }}>
            {credentialsDisplayed ?
                <Alert severity="info" style={{ marginRight: '10px' }}>{t("residents.credentialsAlert")}</Alert> : <></>}
            <Button
                disabled={!anythingChanged()}
                variant="contained"
                onClick={save}
            >
                {t('generic.save')}
            </Button>
        </div>
    </Block>
}
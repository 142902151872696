import ReactDOM from 'react-dom'
import './assets/index.css'
import getStore from './configureStore'
import { loadPersistedData } from './redux/persistence'
import Application from './components/application/Application'
import { FetchUserInformationAction } from './redux/auth/auth-actions'
import { theme } from './theme'

const store = getStore()

loadPersistedData(store)

if (store.getState().hydrolink.auth.isAuthenticated) {
  store.dispatch(FetchUserInformationAction())
}

ReactDOM.render(
  <Application store={store} theme={theme} />,
  document.getElementById('root')
)

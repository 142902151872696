import { useSelector } from "react-redux"
import { RootState } from "../../interfaces/RootState"
import { spacings } from "../../theme"
import ApiAccess from "./ApiAccess"
import ApiKey from "./ApiKey"

type Props = {
    companyId: number,
    apiKey: string | null
}

export default function ApiSettings({ companyId, apiKey }: Props) {
    const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)

    return <div>
        <ApiAccess companyId={companyId} isAdmin={isAdmin} />
        <ApiKey companyId={companyId} apiKey={apiKey} />
    </div>
}
import * as api from "../../api"
import { takeLatest, put, all } from 'redux-saga/effects'
import { ReportErrorAction } from '../error/error-actions'
import {
    FetchUserInformationAction,
    ForceLogoutAction,
    SendPasswordResetAction,
    SendPasswordResetFailureAction,
    SendPasswordResetSuccessAction,
    SetLanguageAction,
    UserReceivedAction,
    UserReceivedFailureAction
} from '../auth/auth-actions'
import {
    FetchUserSuccessPayload,
    SendPasswordResetPayload
} from './auth-types'
import i18next from "i18next"

export function* fetchCurrentUser() {
    yield api.get(
        '/current_user',
        (resp: FetchUserSuccessPayload) => all([
            put(UserReceivedAction(resp)),
            put(SetLanguageAction(resp.lang))
        ]),
        (err: string) => all([
            put(UserReceivedFailureAction(err)),
            put(ForceLogoutAction()),
            put(ReportErrorAction(err))
        ])
    )
}

export function* sendPasswordReset(action: { payload: SendPasswordResetPayload }) {
    yield api.post(
        '/password_reset',
        action.payload,
        () => all([
            put(SendPasswordResetSuccessAction()),
            put(ForceLogoutAction())
        ]),
        (err: string) => all([
            put(SendPasswordResetFailureAction()),
            put(ReportErrorAction(err))
        ])
    )
}


export function* setLanguage(action: { payload: string }) {

        if (!localStorage.getItem("language")) {
            localStorage.setItem("language", action.payload)
            yield i18next.changeLanguage(action.payload)
        }
}

export function* authSagas() {
    yield takeLatest(FetchUserInformationAction, fetchCurrentUser)
    yield takeLatest(SendPasswordResetAction, sendPasswordReset)
    yield takeLatest(SetLanguageAction, setLanguage)
}

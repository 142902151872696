import { AnyAction } from "redux";
import { RootState } from "../../interfaces/RootState";
import {
    CompaniesPaginationItemsPerPageChangeAction,
    CompaniesPaginationPageChangeAction,
    SaveCompanyBasicInfoSuccessAction,
    CreateCompanyAction,
    CreateCompanyFailureAction,
    DeleteCompanyUserAction,
    DeleteCompanyUserFailureAction,
    DeleteCompanyUserSuccessAction,
    FetchCompaniesAction,
    FetchCompaniesFailureAction,
    FetchCompanyApiKeyAction,
    FetchCompanyApiKeyFailureAction,
    RefreshCompanyApiKeyAction,
    RefreshCompanyApiKeyFailureAction,
    SaveCompanyBasicInfoAction,
    SaveCompanyBasicInfoFailureAction,
    UpdateUserAsManagerAction,
    UpdateUserAsManagerFailureAction,
    UpdateUserAsManagerSuccessAction,
    UpdateUserRoleAction,
    UpdateUserRoleFailureAction,
    UpdateUserRoleSuccessAction,
    UpdateApiAccessSuccessAction,
    SortCompaniesAction,
    FilterCompaniesAction,
    PremiumToggleAction,
    PremiumToggleSuccessAction,
    PremiumToggleFailureAction,
    FetchAlarmTypesAction,
    FetchAlarmTypesSuccessAction,
    MergeApartmentsAction,
    MergeApartmentsSuccessAction,
    MergeApartmentsFailureAction
} from "../companies/companies-actions"
import {
  CloseCreateCompanyModal,
  CreateCompanySuccessAction,
  FetchCompaniesSuccessAction,
  FetchCompanyApiKeySuccessAction,
  OpenCreateCompanyModal,
  RefreshCompanyApiKeySuccessAction,
} from "./companies-actions";
import { CompaniesState } from "./companies-types";

const initialState: CompaniesState = {
  loading: false,
  companies: [],
  apiKeys: {},
  createCompanyModalOpen: false,
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
  },
  filteredAndSortedCompanies: [],
  sortBy: "name",
  ascending: true,
  alarmTypes: [],
};

const loadingTrue = (state: CompaniesState) => ({ ...state, loading: true });
const loadingFalse = (state: CompaniesState) => ({ ...state, loading: false });

const actions = (state: CompaniesState, action: AnyAction) => {
  if (FetchCompaniesAction.match(action)) {
    return loadingTrue(state);
  }

  if (FetchCompanyApiKeyAction.match(action)) {
    return loadingTrue(state);
  }

  if (RefreshCompanyApiKeyAction.match(action)) {
    return loadingTrue(state);
  }

  if (CreateCompanyAction.match(action)) {
    return loadingTrue(state);
  }

  if (SaveCompanyBasicInfoAction.match(action)) {
    return loadingTrue(state);
  }

  if (UpdateUserRoleAction.match(action)) {
    return loadingTrue(state);
  }

  if (UpdateUserAsManagerAction.match(action)) {
    return loadingTrue(state);
  }

  if (DeleteCompanyUserAction.match(action)) {
    return loadingTrue(state);
  }

  if (PremiumToggleAction.match(action)) {
    return loadingTrue(state);
  }

  if (FetchAlarmTypesAction.match(action)) {
    return loadingTrue(state);
  }
    if (PremiumToggleAction.match(action)) {
        return loadingTrue(state)
    }
    if (MergeApartmentsAction.match(action)) {
        return loadingTrue(state)
      }

  return state;
};

const successActions = (state: CompaniesState, action: AnyAction) => {
  if (FetchCompaniesSuccessAction.match(action)) {
    action.payload.sort((a, b) => a.name.localeCompare(b.name));
    return {
      ...state,
      companies: action.payload,
      loading: false,
    };
  }

  if (FetchCompanyApiKeySuccessAction.match(action)) {
    return {
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [action.payload.companyId]: action.payload.apiKey,
      },
      loading: false,
    };
  }

  if (RefreshCompanyApiKeySuccessAction.match(action)) {
    return {
      ...state,
      apiKeys: {
        ...state.apiKeys,
        [action.payload.companyId]: action.payload.newApiKey,
      },
      loading: false,
    };
  }

  if (CreateCompanySuccessAction.match(action)) {
    return {
      ...state,
      createCompanyModalOpen: false,
      loading: false,
    };
  }

  if (SaveCompanyBasicInfoSuccessAction.match(action)) {
    return loadingFalse(state);
  }

  if (UpdateUserRoleSuccessAction.match(action)) {
    return loadingFalse(state);
  }

  if (UpdateUserAsManagerSuccessAction.match(action)) {
    return loadingFalse(state);
  }

  if (DeleteCompanyUserSuccessAction.match(action)) {
    return loadingFalse(state);
  }

  if (PremiumToggleSuccessAction.match(action)) {
    return loadingFalse(state);
  }

  if (FetchAlarmTypesSuccessAction.match(action)) {
    return {
      ...state,
      alarmTypes: action.payload,
      loading: false,
    };
  }

  if (PremiumToggleSuccessAction.match(action)) {
      return loadingFalse(state)
  }

  if (MergeApartmentsSuccessAction.match(action)) {
    return loadingFalse(state)
  }

  return state;
};

const otherActions = (state: CompaniesState, action: AnyAction) => {
  if (OpenCreateCompanyModal.match(action)) {
    return {
      ...state,
      createCompanyModalOpen: true,
    };
  }

  if (CloseCreateCompanyModal.match(action)) {
    return {
      ...state,
      createCompanyModalOpen: false,
    };
  }

  if (CompaniesPaginationPageChangeAction.match(action)) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        currentPage: action.payload,
      },
    };
  }

  if (CompaniesPaginationItemsPerPageChangeAction.match(action)) {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        itemsPerPage: action.payload,
      },
    };
  }

  if (SortCompaniesAction.match(action)) {
    return {
      ...state,
      sortBy: action.payload.sortBy,
      ascending: action.payload.ascending,
      filteredAndSortedCompanies: action.payload.companies,
    };
  }

  if (FilterCompaniesAction.match(action)) {
    return {
      ...state,
      filteredAndSortedCompanies: action.payload,
    };
  }

  if (UpdateApiAccessSuccessAction.match(action)) {
    return {
      ...state,
      companies: state.companies.map((c) => {
        if (c.id === action.payload.companyId) {
          c.apiAccessEnabled = action.payload.apiAccessEnabled;
        }

        return c;
      }),
    };
  }
  return state;
};

const failureActions = (state: CompaniesState, action: AnyAction) => {
  if (FetchCompaniesFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (FetchCompanyApiKeyFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (RefreshCompanyApiKeyFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (CreateCompanyFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (SaveCompanyBasicInfoFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (UpdateUserRoleFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (UpdateUserAsManagerFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (DeleteCompanyUserFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (PremiumToggleFailureAction.match(action)) {
    return loadingFalse(state);
  }

  if (MergeApartmentsFailureAction.match(action)) {
    return loadingFalse(state)
  }

  return state
};

export default function companiesReducer(
  state: CompaniesState = initialState,
  action: AnyAction
): CompaniesState {
  state = actions(state, action);
  state = successActions(state, action);
  state = failureActions(state, action);
  state = otherActions(state, action);
  return state;
}

export const selectCompanyApiKey = (companyId: number) => (state: RootState) =>
  state.hydrolink.companies.apiKeys[companyId];

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

interface ChildProps {
  closed: boolean, //when this is true, the component is "closed"
}


const ExpandIcons: React.FC<ChildProps> = (props) => {

  return (
    <span className="text-grey">
      {props.closed ?
        <FontAwesomeIcon icon={faChevronDown} size='1x' /> :
        <FontAwesomeIcon icon={faChevronUp} size='1x' />}
    </span>
  )
}

export default ExpandIcons
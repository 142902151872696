import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { elementWithHeader } from "../../components/common/header/Header";
import { ClearApartmentsAction, FetchAlarmSettingsAction, FetchApartmentsAction, FetchPremiumSettingsAction, FetchReportSettingsAction } from "../../redux/apartments/apartments-actions";
import { selectCompanyApiKey } from '../../redux/companies/companies-reducer';
import { Company } from "../../redux/companies/companies-types";
import { ViewContainer } from "../../components/ViewContainer";
import { Block } from "../../components/common/block";
import ApartmentList from "./ApartmentList";
import CompanyInfo from "./CompanyInfo";
import { Client } from "../../redux/apartments/apartments-types";
import { RootState } from "../../interfaces/RootState";
import { FetchCompaniesAction, MergeApartmentsAction } from "../../redux/companies/companies-actions";
import UploadCSV from "./UploadCSV";
import { useTranslation } from "react-i18next";
import PremiumSettings from "./PremiumSettings";
import ExpandableBlock from "../../components/ExpandableBlock";
import { FetchUsersAction } from "../../redux/users/users-actions";
import { FetchAlarmTypesAction } from "../../redux/companies/companies-actions";
import CompanyTabs, { Tabs } from "../companies/CompanyTabs";
import React from "react";
import { Button } from "../../components/Button";
import ButtonsDisplayer from "../../components/FloatButtonsRight";
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import FontAwesomeIconButton from "../../components/FontAwesomeIconButton";
import { useButtonRights } from "../../utils/useButtonRights";


type Props = {
  company: Company,
  clients: Client[],
  apiKey: string | null
}

function ApartmentsComponent({ company, clients, apiKey }: Props): ReactElement {
  const { t } = useTranslation()
  const [mergeApartmentsOn, setMergeApartmentsOn] = React.useState<boolean>(false)
  const [apartmentsToMerge, setApartmentsToMerge] = React.useState<number[]>([])
  const isAdmin = useSelector((state: RootState): boolean => state.hydrolink.auth.isAdmin)
  const mergeApartmentsButtonRights = useButtonRights(company.id, "manager")
  const dispatch = useDispatch()

  const mergeApartments = () => {
    dispatch(MergeApartmentsAction({
      companyId: company.id,
      targetApartmentId: apartmentsToMerge[0],
      sourceApartmentIds: apartmentsToMerge.slice(1)}))
    resetMergeApartments()
  }

  const resetMergeApartments = () => {
    setApartmentsToMerge([])
    setMergeApartmentsOn(false)
  }

  return <ViewContainer>
    <CompanyTabs tab={Tabs.APARTMENTS} />
    <Block variant="grey">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "25px" }}>
        {company.name}<>
          <div style={{display: 'flex', justifyContent: 'space-between'}} >
          {
        mergeApartmentsOn &&
        <ButtonsDisplayer
          buttons={[
            <Button
              onClick={() => resetMergeApartments()}>
              {t('apartments.cancel')}
            </Button>,
            <Button
              disabled={apartmentsToMerge.length === 0}
              onClick={mergeApartments}>
              {t('apartments.mergeApartments')}
            </Button>
          ]} />
      }
            <FontAwesomeIconButton disabled={mergeApartmentsButtonRights} style={mergeApartmentsOn ? {transform: 'rotate(-180deg)'}: {}} icon={faWrench} onClick={() => setMergeApartmentsOn(!mergeApartmentsOn)}/>
          </div>
        </>

      </div>
      <ApartmentList companyId={company.id} mergeApartmentsOn={mergeApartmentsOn} setApartmentsToMerge={setApartmentsToMerge} apartmentsToMerge={apartmentsToMerge} />
    </Block >
    <CompanyInfo company={company} clients={clients} apiKey={apiKey} />
    {isAdmin &&
      <ExpandableBlock leftSide={t('csv.addApartments')} grey>
        <Block variant="white" style={{ marginTop: '10px' }}>
          <UploadCSV companyId={company.id} />
        </Block>
      </ExpandableBlock>
    }
    {company.premium &&
      <ExpandableBlock leftSide={t('apartments.premiumSettings.title')} grey>
        <PremiumSettings company={company} />
      </ExpandableBlock>
    }
  </ViewContainer >
}


export default function Apartments() {
  const dispatch = useDispatch()
  const params = useParams()
  const companyId = parseInt(params.companyId ?? "0")
  const company = useSelector(selectCompany(companyId))
  const clients = useSelector(selectUniqueClients)
  const apiKey = useSelector(selectCompanyApiKey(companyId));

  useEffect(() => {
    if (company === undefined) {
      dispatch(FetchCompaniesAction());
    }
  }, [company, dispatch])

  useEffect(() => {
    dispatch(ClearApartmentsAction())
    dispatch(FetchUsersAction())
    dispatch(FetchAlarmTypesAction())
    dispatch(FetchApartmentsAction({ companyId }))
    dispatch(FetchPremiumSettingsAction({ companyId }))
    dispatch(FetchAlarmSettingsAction({ companyId }))
    dispatch(FetchReportSettingsAction({ companyId }))

  }, [companyId, dispatch])

  if (company === undefined) {
    return null
  }

  return elementWithHeader(<ApartmentsComponent company={company} clients={clients} apiKey={apiKey} />)
}

const selectCompany = (companyId: number) => (state: RootState) =>
  state.hydrolink.companies.companies.find(c => c.id === companyId)

const selectUniqueClients = (state: RootState) => {
  const foundIds = new Set<number>()
  const res: Client[] = []

  for (const a of state.hydrolink.apartments.apartments) {
    if (foundIds.has(a.client.id)) {
      continue
    }
    foundIds.add(a.client.id)
    res.push(a.client)
  }
  return res
}
